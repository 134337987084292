<template>
  <div class="container mx-auto justify-center flex flex-col mb-14 p-4 md:py-0">
    <template v-if="isLoaded">
      <client-name></client-name>
      <step-progress :steps="steps"></step-progress>
      <transition name="fade" appear>
        <keep-alive>
          <component :is="steps[mainStore.currentStep].component"></component>
        </keep-alive>
      </transition>
    </template>
    <loading-spinner v-else></loading-spinner>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useMainStore } from '@/stores/mainStore';
import { useRoute, useRouter } from 'vue-router';

import ClientName from '@/components/ClientName.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import StepProgress from '@/components/StepProgress.vue';

import PartOne from '@/components/cost-estimates/PartOne.vue';
import PartTwo from '@/components/cost-estimates/PartTwo.vue';
import TradeMarkApplication from '@/components/cost-estimates/TradeMarkApplication.vue';

const mainStore = useMainStore();
const isLoaded = ref(false);
const route = useRoute();
const router = useRouter();

const stepList = [
  { name: 'Summary', component: PartOne },
  { name: 'Acceptance', component: PartTwo },
];

const steps = computed(() => {
  if (mainStore.costEstimate?.trade_mark_application) {
    return [{ name: 'Trade Marks', component: TradeMarkApplication }, ...stepList]
  }
  return stepList;
})

mainStore
  .getCostEstimate(route.params.id)
  .then(() => {
    const isTradeMarkApplication = mainStore.costEstimate?.matter_type?.value === 'TradeMarkApplication';
    isLoaded.value = true;
    mainStore.title = isTradeMarkApplication ? 'Quote for Legal Assistance' : 'Cost Estimate for Legal Assistance';
    mainStore.stageReached = 1;
    mainStore.businessInformation.original = { ...mainStore.costEstimate.business_information };
    mainStore.businessInformation.updated = { ...mainStore.costEstimate.business_information };
    mainStore.logCostEstimateAccess(route.params.id).catch((err) => {
      console.error("Error occurred logging CE access", err);
    });
  })
  .catch(() => {
    mainStore.errorMessage = 'Sorry we could not find your cost estimate.';
    router.push({ name: 'NotFound' });
  });
</script>
