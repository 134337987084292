<template>
  <div>
    <template v-if="!mainStore.transactionComplete">
      <Form @submit="onSubmit" :validation-schema="schema" @invalid-submit="onInvalidSubmit">
        <form-section label="Credit Card">
          <div class="columns-1 md:columns-2 mb-0 md:mb-2">
            <p-input
              v-model="paymentStore.paymentMethodOwner.name"
              label="Name"
              name="name"
              placeholder="Enter name on card..."
            />
            <p-input
              v-model="paymentStore.paymentMethodOwner.email"
              label="Email"
              type="email"
              name="email"
              placeholder="example@company.com"
            />
          </div>
          <stripe-card-element />
          <payment-error-message />
          <div v-if="!selectedInvoiceCount" class="bg-warning flex p-3 my-4 justify-center rounded-md">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 mr-2">
              <path
                fill-rule="evenodd"
                d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
                clip-rule="evenodd"
              />
            </svg>
            <span>Select an invoice for payment on previous page</span>
          </div>
          <template v-if="mainStore.jurisdiction !== 'uk' && selectedInvoiceCount">
            <div class="text-center mt-4">
              <span class="font-italic text-xs sm:text-sm">
                This transaction is subject to a surcharge of {{ paymentStore.visaFeePercent }}% for Visa or Mastercard,
                and {{ paymentStore.amexFeePercent }}% for American Express.
              </span>
            </div>
          </template>
        </form-section>
        <div class="flex justify-center space-x-8 my-8">
          <div class="flex-col text-left font-semibold text-sm">
            <div class="mb-2">Balance due</div>
            <div class="mb-2">Processing fee</div>
            <div class="mb-2">Total</div>
          </div>
          <div class="flex-col text-sm">
            <div class="mb-2">{{ mainStore.numberToCurrency(paymentStore.paymentAmount) }}</div>
            <div class="mb-2">{{ mainStore.numberToCurrency(paymentStore.processingFee) }}</div>
            <div class="mb-2">{{ mainStore.numberToCurrency(paymentStore.paymentTotal) }}</div>
          </div>
        </div>
        <div class="flex mb-4 justify-center">
          <p-input v-model="acceptance" type="checkbox" name="acceptance"></p-input>
        </div>
        <footer-nav
          previous
          submit
          :disabled="paymentStore.paymentAmount <= 0"
          is-last-step
          :next="'Pay ' + mainStore.numberToCurrency(paymentStore.paymentTotal)"
        />
      </Form>
    </template>
    <template v-else>
      <transaction-success>Payment complete.</transaction-success>
    </template>
    <loading-overlay :loading="paymentStore.checkingPayment" :text="'Processing payment'"></loading-overlay>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';

import FooterNav from '@/components/FooterNav.vue';
import FormSection from '@/components/FormSection.vue';
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import PaymentErrorMessage from '@/components/PaymentErrorMessage.vue';
import PInput from '@/components/PInput.vue';
import StripeCardElement from '@/components/stripe/StripeCardElement.vue';
import SectionTitle from '@/components/SectionTitle.vue';
import TransactionSuccess from '@/components/TransactionSuccess.vue';

import { usePaymentStore } from '@/stores/paymentStore';
import { useMainStore } from '@/stores/mainStore';
import { Form, useForm } from 'vee-validate';
import { boolean, object, string } from 'yup';

const { handleSubmit } = useForm();
const paymentStore = usePaymentStore();
const mainStore = useMainStore();
const acceptance = ref(false);

const schema = object({
  email: string().email('Must be a valid email address').required().label('Email Address'),
  name: string().required().label('Name on card'),
  acceptance: boolean().oneOf([true], 'Payment requires acceptance of terms'),
});

function onInvalidSubmit({ values, errors, results }) {
  const el = document.querySelector(`#error-alert`);
  if (el) {
    el.scrollIntoView();
  }
}
const selectedInvoiceCount = computed(() => {
  return paymentStore.outstandingInvoices.filter((invoice) => !!invoice.selected).length;
});
const onSubmit = handleSubmit(() => {
  paymentStore.processCardPayment();
});
</script>
