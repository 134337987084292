<template>
  <header class="h-16 bg-teal flex items-center mb-0 md:mb-4">
    <div class="container mx-auto flex items-center justify-between">
      <div class="flex">
        <img class="h-10 w-10 mr-4" src="/img/logo/lv-logo_square.svg" alt="LegalVision Logo" />
        <div class="flex flex-col justify-end">
          <img class="h-3 w-24 mb-1" src="/img/logo/logo-lv__header.png" alt="LegalVision Logo" />
          <div class="text-white font-bold text-sm">{{ mainStore.title }}</div>
        </div>
      </div>
      <div class="text-white font-semi-bold">
        <a class="flex items-center text-decoration-none text-sm" :href="`tel:${mainStore.phoneNumber.split(' ').join('')}`">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            class="w-4 h-4 mr-2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
            />
          </svg>
          <span class="font-semibold hidden sm:block">{{ mainStore.phoneNumber }}</span>
        </a>
      </div>
    </div>
  </header>
  <router-view></router-view>
</template>
