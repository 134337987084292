<template>
  <div class="flex flex-wrap mb-5 sm:mb-10 sm:space-x-3 justify-around">
    <div
      v-for="award in awards"
      :key="award.title"
      class="flex justify-center items-center px-2 sm:px-0 flex-col h-full grow max-w-[190px] mb-6"
    >
      <img
        :src="award.src"
        alt=""
        class="mb-2"
        width="100"
        height="100"
      />
      <div class="text-sm text-center"><span class="font-semibold">{{ award.title }}</span> - <span>{{ award.text }}</span></div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useMainStore } from '@/stores/mainStore.js';
const mainStore = useMainStore();

const awards = computed(() => {
  if (mainStore.jurisdiction === 'uk') {
    return [
      {
        title: '2023 Economic Innovator of the Year Finalist',
        text: 'The Spectator',
        src: '/img/awards/uk/2023_innovation-of-the-year_finalist.webp',
      },
      {
        title: '2023 Law Company of the Year Finalist',
        text: 'The Lawyer Awards',
        src: '/img/awards/uk/2023-law-company-of-the-year-finalist-the-lawyer-awards.png',
      },
      {
        title: '2023 Future of Legal Services Innovation',
        text: 'Legal Innovation Awards',
        src: '/img/awards/uk/2023-future-of-legal-services-innovation-legal-innovation-awards.webp',
      },
      {
        title: '2021 Fastest Growing Law Firm in APAC',
        text: 'Financial Times',
        src: '/img/awards/uk/2021-fastest-growing-law-firm-in-apac-financial-times.png',
      },
    ];
  }
  return [
    {
      title: '2023 Fast Firms',
      text: 'Australasian Lawyer',
      src: '/img/awards/au/2023_fast-firms.png',
    },
    {
      title: '2022 Law Firm of the Year',
      text: 'Australasian Law Awards',
      src: '/img/awards/au/2022-law-firm-of-the-year-australasian-law-awards.webp',
    },
    {
      title: '2021 Fastest Growing Law Firm in APAC',
      text: 'Financial Times 1000',
      src: '/img/awards/au/2021-fastest-growing-law-firm-in-apac-financial-times.png',
    },
    {
      title: '2020 Excellence in Technology & Innovation Finalist',
      text: 'Australasian Law Awards',
      src: '/img/awards/au/2020_australasian-law_innovation.webp',
    },
    {
      title: '2020 Employer of Choice Winner',
      text: 'Australasian Lawyer',
      src: '/img/awards/au/2020_australasian-lawyer_employer-of-choice.webp',
    },
  ];
});
</script>
