<template>
  <div
    v-if="isPreview"
    class="bg-vivid-tangelo text-white font-semibold text-lg flex p-3 my-4 justify-center rounded-md"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="w-8 h-8 mr-4"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
      />
    </svg>
    <span class="">Membership proposal is in preview mode</span>
  </div>
</template>

<script setup>
import { useMainStore } from '@/stores/mainStore';
import { computed } from 'vue';

const mainStore = useMainStore();

const isPreview = computed(() => mainStore.isPreview);
</script>
