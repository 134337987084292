<template>
  <div class="bg-white rounded-md -mx-5 sm:mx-0 p-4 space-y-4">
    <div v-if="matterType === 'Immigration'" class="space-y-4">
      <p>
        The total cost for this project will be
        <span class="font-bold">{{ totalCost }}</span>
        (inclusive of {{ taxType }} and disbursements). The professional fees are based
        on our <a class="underline decoration-dotted underline-offset-4 cursor-pointer" href="#" @click.prevent="modalActive = true">hourly rates</a> and the
        expected time to complete the stages listed below, considering the instructions you have
        provided. Our hourly rates and the rights and obligations of each party are outlined in
        our costs agreement. Please read this document carefully and let us know if you have any
        questions.
      </p>
      <p><span class="font-bold">Payment terms for member clients</span></p>
      <p>
        For professional fees, payment is due 14 days from the invoice date. Our finance team
        will send an invoice following the completion of the scope of work of your legal
        project. For government fees, an upfront payment is required before we are able to commence work.
      </p>
      <p><span class="font-bold">Payment terms for non-member clients</span></p>
      <p>Payment is required up-front before we are able to commence working on any application.</p>
    </div>
    <div v-else-if="matterType === 'TradeMarkApplication'" class="space-y-4">
      <p>
        The total quoted price is
        <span class="font-bold">{{ totalCost }}</span>
        (inclusive of {{ taxType }} and disbursements). Additional fees may apply if there are any objections or oppositions raised by the trade mark assessment body or a third party.
      </p>
      <p>For international trade marks, additional downstream fees may apply to register your brand name, and our quote is subject to conversion rate fluctuations.</p>
      <p>We will always advise you of any additional fees and get your approval before incurring any costs.</p>
    </div>
    <div v-else class="space-y-4">
      <p>
        We estimate the total cost of this project will be <span class="font-bold">{{ totalCost }}</span> (inclusive of
        {{ taxType }} and disbursements). This estimate reflects our
        <span class="underline decoration-dotted underline-offset-4 cursor-pointer" @click="modalActive = true"
        >hourly rates</span
        >
        and the expected time to complete the stages listed above, considering the instructions you have provided.
      </p>
      <p>Factors that may influence the costs include:</p>
      <ul class="list-disc ml-8">
        <li>your timely response to requests for information or instructions;</li>
        <li>whether your instructions are varied;</li>
        <li>the conduct and level of cooperation of any other party involved;</li>
        <li>the complexity or uncertainty concerning legal issues affecting your matter; or</li>
        <li>changes in the law.</li>
      </ul>
      <p>
        Please note, if we anticipate that the Cost Estimate will be exceeded, we will provide you with an update as soon
        as practicable.
      </p>
      <p>
        Our hourly rates and the rights and obligations of each party are outlined in our
        <a :href="mainStore.costAgreementUrl" target="_blank" class="text-ming-600">costs agreement</a>. Please read this
        document carefully and let us know if you have any questions.
      </p>
    </div>
  </div>
  <Modal v-model:active="modalActive" label="Hourly Rates">
    <div class="text-base leading-relaxed">
      <p>Hourly rate bands unless otherwise notified to you in writing:</p>
      <ul class="list-disc ml-8">
        <li v-for="(rate, index) in rates" :key="rate.title">
          {{ `${currencyType}${rate.rate} per hour (ex ${taxType}) for ${rate.title}` }}
        </li>
      </ul>
      <p>
        To the extent the above bands do not apply as set out above, we will notify you of the relevant hourly rate band
        that applies to your lawyer prior to commencing work on the scope of work.
      </p>
    </div>
  </Modal>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useMainStore } from '@/stores/mainStore.js';
import Modal from '../Modal.vue';

const mainStore = useMainStore();

const modalActive = ref(false);
const totalCost = computed(() => mainStore.numberToCurrency(mainStore?.costEstimate?.total_cost_estimate));
const matterType = computed(() => mainStore?.costEstimate?.matter_type?.value);
const { currencyType, taxType } = mainStore;
const rates = computed(() => {
  return RATES_MAP[mainStore.jurisdiction].map((rate, index, array) => ({
    ...rate,
    title: `${rate.title}${index === array.length - 1 ? '.' : ';'}${index === array.length - 2 ? ' and' : ''}`,
  }));
});

const AU_RATES = [
  {
    title: 'Senior Practice Leaders',
    rate: 795,
  },
  {
    title: 'Practice Leaders and Patent Attorneys',
    rate: 695,
  },
  {
    title: 'Senior Lawyers and Senior Associates',
    rate: 545,
  },
  {
    title: 'Lawyers and Trade Mark Attorneys',
    rate: 445,
  },
  {
    title: 'Graduate Lawyers and Junior Lawyers',
    rate: 345,
  },
  {
    title: 'Paralegals',
    rate: 195,
  },
];

const NZ_RATES = [
  {
    title: 'Senior Practice Leaders',
    rate: 795,
  },
  {
    title: 'Practice Leaders and Patent Attorneys',
    rate: 695,
  },
  {
    title: 'Senior Associates',
    rate: 545,
  },
  {
    title: 'Associates and Trade Mark Attorneys',
    rate: 445,
  },
  {
    title: 'Graduate Lawyers and Junior Lawyers',
    rate: 345,
  },
  {
    title: 'Paralegals',
    rate: 195,
  },
];

const UK_RATES = [
  {
    title: 'Practice Leaders',
    rate: 400,
  },
  {
    title: 'Senior Associates',
    rate: 300,
  },
  {
    title: 'Associates',
    rate: 225,
  },
  {
    title: 'Solicitors',
    rate: 180,
  },
  {
    title: 'Trainee Solicitors and Paralegals',
    rate: 110,
  },
];

const RATES_MAP = {
  au: AU_RATES,
  nz: NZ_RATES,
  uk: UK_RATES,
};
</script>

<style scoped lang="scss"></style>
