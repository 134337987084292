<template>
  <div class="mb-4 md:mb-0">
    <label class="block mb-2 font-semibold text-xs sm:text-sm">Bank Details</label>
    <div id="bank-element" />
    <span class="text-red-700 text-xs sm:text-sm">{{ becsError }}</span>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { useMainStore } from '@/stores/mainStore';
import { usePaymentStore } from '@/stores/paymentStore';

const paymentStore = usePaymentStore();
const mainStore = useMainStore();

const element = ref(null);
const becsError = ref('');

const screenWidth = window.innerWidth;

const stripeHeight = computed(() => {
  if (screenWidth < 640) {
    return '2.5rem';
  }
  return '3rem';
});

const stripePadding = computed(() => {
  if (screenWidth < 640) {
    return '0.625rem';
  }
  return '1rem';
});

onMounted(() => {
  const checkElement = setInterval(async () => {
    const element = document.querySelector('#bank-element');
    if (element != null && stripeHeight && stripePadding) {
      handleSetup();
      clearInterval(checkElement);
    }
  }, 100);
});

const handleSetup = async () => {
  const element = mainStore.stripe.elements().create('auBankAccount', {
    style: {
      base: {
        color: '#5A6C72',
        fontFamily: 'Inter, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '14px',
        '::placeholder': {
          color: '#aab7c4',
          fontSize: '14px',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  });

  element.mount('#bank-element');

  element.on('change', (event) => {
    if (event.error && event.error.message) {
      becsError.value = event.error.message;
      return;
    }
    becsError.value = '';
  });

  element.value = element;
  paymentStore.stripeElement = element;
};
</script>

<style scoped>
.StripeElement {
  box-sizing: border-box;
  height: v-bind('stripeHeight');
  padding: v-bind('stripePadding');
  border: solid 1px #c6cfd2;
  background-color: #f9fafb;
  border-radius: 0.5rem;
}

.StripeElement--invalid {
  border-color: red;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.StripeElement--focus {
  color: #5a6c72;
  background-color: #f9fafb;
  border-color: #a1cbef;
  outline: 0;
}
</style>
