<template>
  <div class="container mx-auto justify-center flex flex-col mb-14 p-4 md:py-0">
    <template v-if="isLoaded">
      <client-name></client-name>
      <step-progress :steps="steps"></step-progress>
      <transition name="fade" appear>
        <keep-alive>
          <component :is="steps[mainStore.currentStep].component"></component>
        </keep-alive>
      </transition>
    </template>
    <loading-spinner v-else></loading-spinner>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useMainStore } from '@/stores/mainStore';
import { usePaymentStore } from '@/stores/paymentStore';
import { useRoute } from 'vue-router';

import ClientName from '@/components/ClientName.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import StepProgress from '@/components/StepProgress.vue';

import PartOne from '@/components/overdue-invoices/PartOne.vue';
import PartTwo from '@/components/overdue-invoices/PartTwo.vue';

const mainStore = useMainStore();
const paymentStore = usePaymentStore();
const route = useRoute();

const isLoaded = ref(false);
const localQuote = computed(() => mainStore.quote);

const invoices = route.query.id ? route.query.id : route.query['id[]'];

axios
  .post('/api/quote/overdue', { invoices })
  .then((response) => {
    paymentStore.outstandingInvoices = (response.data || []).filter((invoice) => Number(invoice.balance_due) > 0);
  })
  .finally(() => {
    isLoaded.value = true;
    mainStore.title = 'Invoice for Legal Assistance';
    mainStore.stageReached = 1;
  });

const clientName = computed(() => {
  if (paymentStore.outstandingInvoices?.length > 0) {
    return paymentStore.outstandingInvoices[0].client_name;
  }
  return '';
});

const steps = [
  {
    name: 'Summary',
    component: PartOne,
  },
  {
    name: 'Payment',
    component: PartTwo,
  },
];
</script>
