<template>
  <h1 class="text-center text-2xl font-bold my-4 text-[#F36924]">{{ clientName }}</h1>
</template>

<script setup>
import { computed } from 'vue';

import { useMainStore } from '@/stores/mainStore';

const mainStore = useMainStore();

const clientName = computed(() => {
  if (mainStore.quote?.is_third_party_payer && mainStore.quote?.lead?.name) {
    return mainStore.quote.lead.name;
  }

  return mainStore.quote?.client_name;
});
</script>
