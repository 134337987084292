<template>
  <div class="container mx-auto justify-center flex flex-col mb-14 p-4 md:py-0">
    <template v-if="isLoaded">
      <client-name></client-name>
      <step-progress :steps="steps"></step-progress>
      <transition name="fade" appear>
        <keep-alive>
          <component :is="steps[mainStore.currentStep].component"></component>
        </keep-alive>
      </transition>
    </template>
    <loading-spinner v-else></loading-spinner>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useMainStore } from '@/stores/mainStore';
import { useRoute, useRouter } from 'vue-router';

import ClientName from '@/components/ClientName.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import StepProgress from '@/components/StepProgress.vue';

import PartOne from '@/components/invoice/PartOne.vue';
import PartTwo from '@/components/invoice/PartTwo.vue';

const mainStore = useMainStore();
const route = useRoute();
const router = useRouter();

const isLoaded = ref(false);
const localQuote = computed(() => mainStore.quote);

mainStore
  .getQuote(route.params.id)
  .then(() => {
    isLoaded.value = true;
    mainStore.title = 'Invoice for Legal Assistance';
    if (mainStore.quote.is_lvc_pro_invoice) {
      mainStore.title = 'Invoice for membership';
    }
    mainStore.stageReached = 1;
  })
  .catch(() => {
    mainStore.errorMessage = 'Sorry we could not find your invoice.';
    router.push({ name: 'NotFound' });
  });

const steps = [
  {
    name: 'Summary',
    component: PartOne,
  },
  {
    name: 'Payment',
    component: PartTwo,
  },
];
</script>
