<template>
  <loading-spinner></loading-spinner>
</template>

<script setup>
import { useMainStore } from '@/stores/mainStore.js';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();
const mainStore = useMainStore();

mainStore
  .getQuote(route.params.id)
  .then(() => {
    const isPreview = route.query.preview || false;
    if (mainStore.quote.cost_estimate?.id) {
      router.push({ name: 'CostEstimate', params: { id: mainStore.quote.cost_estimate.id } });
      return;
    }

    if (mainStore.quote.is_employment_hr_quote) {
      router.push({
        name: 'EmploymentHR',
        params: { id: mainStore.quote.id },
        query: { preview: route.query.preview },
      });
      return;
    }

    if (mainStore.quote.is_addon_only_quote) {
      router.push({
        name: 'AddonQuote',
        params: { id: mainStore.quote.id },
        query: { preview: route.query.preview },
      });
      return;
    }

    if (mainStore.quote.is_lvc_pro_quote || mainStore.quote.is_lv_access) {
      router.push({
        name: 'Membership',
        params: { id: mainStore.quote.id },
        query: { preview: route.query.preview },
      });
      return;
    }

    if (!isPreview) {
      axios.post(`/api/quote/${mainStore.quote.id}/access_log`).catch((error) => console.error(error));
    }

    if (mainStore.quote.type === 'Quote' && !mainStore.quote.is_subscription_quote) {
      router.push({ name: 'PaymentAdvice', params: { id: mainStore.quote.id } });
      return;
    }

    if (mainStore.quote.type === 'Payment_Advice' && !mainStore.quote.is_subscription_quote) {
      router.push({ name: 'PaymentAdvice', params: { id: mainStore.quote.id } });
      return;
    }

    if (mainStore.quote.type === 'Invoice') {
      router.push({ name: 'Invoice', params: { id: mainStore.quote.id } });
    }
  })
  .catch(() => {
    mainStore.errorMessage = 'Sorry we could not find your quote.';
    router.push({ name: 'NotFound' });
  });
</script>
