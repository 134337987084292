import { createRouter, createWebHistory } from 'vue-router';
import ProMembershipSignup from '@/views/ProMembershipSignup.vue';
import Invoice from '@/views/Invoice.vue';
import PaymentAdvice from '@/views/PaymentAdvice.vue';
import AddonQuote from '@/views/AddonQuote.vue';
import PaymentRoute from '@/views/PaymentRoute.vue';
import OverdueInvoice from '@/views/OverdueInvoice.vue';
import PaymentMethod from '@/views/PaymentMethod.vue';
import NotFound from '@/views/NotFound.vue';
import Subscription from '@/views/Subscription.vue';
import EmploymentHR from '@/views/EmploymentHR.vue';
import DocumentSigned from '@/components/DocumentSigned.vue';
import DocumentDeclined from '@/components/DocumentDeclined.vue';
import PaymentMethodSuccess from '@/components/PaymentMethodSuccess.vue';
import CostEstimate from '@/views/CostEstimate.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/membership/:id?',
      name: 'Membership',
      beforeEnter: (to, from, next) => {
        const quoteNumber = to.params.id;
        const isPreview = to.query.preview || false;
        if (!isPreview) {
          axios.post(`/api/quote/${quoteNumber}/access_log`).catch((error) => console.error(error));
        }
        next();
      },
      component: ProMembershipSignup,
    },
    {
      path: '/membership/:id/payment/success',
      name: 'PaymentSucess',
      component: ProMembershipSignup,
    },
    {
      path: '/pay/:id?',
      name: 'Invoice',
      component: Invoice,
    },
    {
      path: '/quote/:id?',
      name: 'PaymentAdvice',
      component: PaymentAdvice,
    },
    {
      path: '/cost-estimate/:id?',
      name: 'CostEstimate',
      component: CostEstimate,
    },
    {
      path: '/cost-estimates/:id?',
      name: 'CostEstimates',
      component: CostEstimate,
    },
    {
      path: '/employment-hr/:id?',
      name: 'EmploymentHR',
      component: () => import('@/views/EmploymentHR.vue'),
    },
    {
      path: '/support/:id?',
      name: 'AddonQuote',
      beforeEnter: (to, from, next) => {
        const quoteNumber = to.params.id;
        const isPreview = to.query.preview || false;
        if (!isPreview) {
          axios.post(`/api/quote/${quoteNumber}/access_log`).catch((error) => console.error(error));
        }
        next();
      },
      component: AddonQuote,
    },
    {
      path: '/invoice',
      component: OverdueInvoice,
    },
    {
      path: '/invoices',
      component: OverdueInvoice,
    },
    {
      path: '/payment-method/:id?',
      name: 'PaymentMethod',
      component: PaymentMethod,
    },
    {
      path: '/update/success',
      name: 'PaymentMethodSuccess',
      component: PaymentMethodSuccess,
    },
    {
      path: '/update/:id?',
      name: 'PaymentMethod',
      component: PaymentMethod,
    },
    {
      path: '/payment/:id?',
      name: 'PaymentRoute',
      component: PaymentRoute,
    },
    {
      path: '/payment/:id?/pro/membership/summary',
      redirect: (to) => ({
        name: 'Membership',
        query: { id: to.params.id },
      }),
    },
    {
      path: '/document-signed',
      props: true,
      component: DocumentSigned,
    },
    {
      path: '/document-declined',
      component: DocumentDeclined,
    },
    {
      path: '/not-found',
      name: 'NotFound',
      props: true,
      component: NotFound,
    },
    {
      path: '/subscription/createFromQuote/:id',
      component: Subscription,
    },
  ],
});

export default router;
