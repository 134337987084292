<template>
  <div>
    <template v-if="isCancelledOrExpired">
      <section-title class="mt-6 sm:mt-12">Quote Expired</section-title>
      <information-grid start-open :data-array="cancelledData"></information-grid>
    </template>
    <template v-if="businessBackground?.length || businessInformation?.length">
      <section-title class="mt-6 sm:mt-12">Background</section-title>
      <information-grid v-if="businessBackground?.length" :data-array="businessBackground"></information-grid>
      <information-grid v-if="businessInformation?.length" :data-array="businessInformation"></information-grid>
    </template>
    <section-title class="mt-6 sm:mt-12">Membership Benefits</section-title>
    <promotional-modal></promotional-modal>
    <template v-if="!isCancelledOrExpired">
      <section-title class="mt-6 sm:mt-12">Membership Term</section-title>
      <tier-select></tier-select>
    </template>
    <information-grid v-if="mainStore.quote.is_rm_sub_quote" :data-array="rmInfo" :start-open="true"></information-grid>
    <!--    <information-grid-->
    <!--      v-if="mainStore.quote.is_pcs_sub_quote"-->
    <!--      :data-array="pcsInfo"-->
    <!--      :start-open="true"-->
    <!--    ></information-grid>-->
    <template v-if="!isCancelledOrExpired && mainStore.quote?.date_due && !mainStore.quote.terms_accepted">
      <div class="p-4 rounded-lg bg-[#21a56e] mt-8 flex justify-center">
        <div class="flex">
          <div class="flex justify-center mr-2 items-center"></div>
          <span class="font-semibold text-sm sm:text-base text-white">
            Proposal valid until {{ new Date(mainStore.quote.date_due).toLocaleDateString() }}
          </span>
        </div>
      </div>
    </template>
    <div
      v-if="!mainStore.quote?.subscription"
      class="flex items-center mt-8"
      :class="{ 'flex-col lg:flex-row': showTrialPeriod }"
    >
      <div :class="{ 'basis-7/12': showTrialPeriod, hidden: !showTrialPeriod }">
        <trial-period></trial-period>
      </div>
      <div class="mx-auto" :class="{ 'basis-5/12 self-start': showTrialPeriod }">
        <div class="flex justify-center rounded-md py-4">
          <img
            class="w-40 sm:w-52 border-r border-gray-50 pl-2 pr-2 sm:pr-6 lg:pr-4"
            src="/img/trustpilot-logo.png"
            alt="Trustpilot logo with stars"
          />
          <figure class="flex flex-col justify-center text-center pr-2 pl-2 sm:pl-6 lg:pl-14">
            <img
              class="w-28 sm:w-36 mx-auto"
              src="/img/google-stars-transparent-bg-217x39.webp"
              alt="Google logo with stars"
            />
            <figcaption class="font-semibold lg:font-medium text-sm sm:text-lg lg:text-lg mt-1">
              {{ mainStore.jurisdiction === 'au' ? '4.8' : mainStore.jurisdiction === 'nz' ? '4.9' : '5' }}/5 on Google
              Reviews
            </figcaption>
          </figure>
        </div>
      </div>
    </div>
    <section-title class="mt-6">FAQ</section-title>
    <membership-faq></membership-faq>
    <div></div>
    <footer-nav v-if="!isCancelledOrExpired" next="Proceed to Account Information"></footer-nav>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useMainStore } from '@/stores/mainStore.js';
import { useContractStore } from '@/stores/contractStore.js';

import FooterNav from '@/components/FooterNav.vue';
import GoogleReview from '@/components/GoogleReview.vue';
import InformationGrid from '@/components/InformationGrid.vue';
import MembershipFaq from '@/components/MembershipFaq.vue';
import PromotionalModal from '@/components/lvc-pro/PromotionalModal.vue';
import SectionTitle from '@/components/SectionTitle.vue';
import StatusBanner from '@/components/StatusBanner.vue';
import TierSelect from '@/components/TierSelect.vue';
import TrialPeriod from '@/components/lvc-pro/TrialPeriod.vue';

const mainStore = useMainStore();
const contractStore = useContractStore();

const isCancelledOrExpired = computed(() => {
  return (
    mainStore.quote.status === 'Cancelled' ||
    (mainStore.quote?.date_due &&
      new Date(new Date(mainStore.quote.date_due).setHours(23, 59, 59, 999)) < new Date() &&
      !mainStore.quote.terms_accepted)
  );
});

const cancelledData = ref([
  {
    label: 'Your quote has expired',
    text: `The ${mainStore.quote.client_name} quote expired on ${new Date(
      mainStore.quote.date_due
    ).toLocaleDateString()}. We’d still love to support you! Reach out to your consultant ${
      mainStore.quote.assigned_user.name
    } at <a href="tel:${mainStore.phoneNumber.split(' ').join('')}">${mainStore.phoneNumber}</a> or <a href="mailto:${
      mainStore.quote.assigned_user.email_address_first.email_address
    }">${mainStore.quote.assigned_user.email_address_first.email_address}</a> to receive an updated quote for support.`,
    fontAwesomeIcon: {
      icon: 'fa-ban',
      colourClass: 'text-red-500',
    },
  },
]);

const rmInfo = [
  {
    label: 'Dedicated Relationship Manager',
    text: 'You will be matched with a lawyer (your Relationship Manager) who is best placed to assist you with your legal needs. You will have direct access to, and support from, your Relationship Manager. You can book a call at a time that suits you best, directly with your Relationship Manager to discuss your legal request.',
  },
];

// const pcsInfo = [
//   {
//     label: 'Additional Support: Privacy Compliance Service',
//     text: 'Get comprehensive privacy compliance support including audits, on-demand advice, policy creation, training, webinars, and impact assessments to ensure your business meets privacy requirements and effectively manages data protection risks.',
//   },
// ];

const businessBackground = ref([]);

const screenWidth = computed(() => window.innerWidth);

const showTrialPeriod = computed(() => {
  return (
    !mainStore.quote?.subscription &&
    mainStore.quote.trial_period_guarantee &&
    !mainStore.quote.is_no_charge_sub &&
    !mainStore.quote.is_reactivate
  );
});

if (mainStore.quote.is_no_charge_sub) {
  const contractOption = mainStore.quote.lvc_pro_contract_options.filter((contract) => {
    return contract?.subscription_term.label === mainStore.quote?.subscription_term;
  })[0];
  contractStore.contractData = {
    discount_percent: 0,
    subscription_term: {
      value: contractOption.subscription_term.value,
      label: contractOption.subscription_term.label,
    },
    lv_fees: 0,
    monthly_cost: 0,
    whs_monthly_fee: {
      whs_total_excl_gst: 0,
      whs_total_inc_gst: 0,
    },
    contract_end_date: null,
    whs_addon_enabled: mainStore.quote.is_whs_sub_quote,
  };
}

if (mainStore.quote.is_lv_access) {
  const contractOption = mainStore.quote.lvc_pro_contract_options.filter((contract) => {
    return contract?.subscription_term.label === '1 year';
  })[0];
  contractStore.contractData = {
    discount_percent: 0,
    subscription_term: {
      value: contractOption.subscription_term.value,
      label: contractOption.subscription_term.label,
    },
    lv_fees: 0,
    monthly_cost: 0,
    whs_monthly_fee: {
      whs_total_excl_gst: 0,
      whs_total_inc_gst: 0,
    },
    contract_end_date: null,
    whs_addon_enabled: mainStore.quote.is_whs_sub_quote,
  };
}

if (mainStore.quote.client_background) {
  businessBackground.value.push({
    label: 'About your business',
    text: mainStore.quote.client_background,
    icon: 'about_your_business',
  });
}

const businessInformation = ref([]);

if (mainStore.quote.client_need) {
  businessInformation.value.push({
    label: 'Immediate needs',
    text: mainStore.quote.client_need,
    icon: 'immediate_needs',
  });
}

if (mainStore.quote.client_upcoming_needs) {
  businessInformation.value.push({
    label: 'Upcoming needs',
    text: mainStore.quote.client_upcoming_needs,
    icon: 'upcoming_legal_needs',
  });
}

const clientOngoingNeeds = mainStore.quote.client_ongoing_needs?.items?.filter((el) => !!el.value);

if (clientOngoingNeeds?.length) {
  let html = '<ul class="dashed">';
  const ongoingNeeds = clientOngoingNeeds.map((item) => item.label);

  ongoingNeeds.forEach((need) => {
    html += `<li>${need}</li>`;
  });

  html += '</ul>';

  businessInformation.value.push({
    label: 'Ongoing support',
    text: html,
    icon: 'ongoing_support',
  });
}
</script>
