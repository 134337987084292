<template>
  <div>Signed</div>
</template>

<script setup>
import { nextTick, onMounted } from 'vue';
import { useRoute } from 'vue-router';
const route = useRoute();

onMounted(() => {
  nextTick(() => {
    const quoteId = route.query.quoteId;
    const customData = { quoteId: quoteId };
    const event = new CustomEvent('envelope-signed', { detail: customData });
    window.parent.document.dispatchEvent(event);
  });
});
</script>
