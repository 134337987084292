<template>
  <div class="flex flex-row flex-wrap faq mb-12">
    <div class="md:basis-1/2 flex flex-col md:pr-2">
      <template
        v-for="info in membershipInformation.slice(0, mainStore.jurisdiction === 'uk' && whsActive ? 4 : 3)"
        :key="info.title"
      >
        <div class="py-4">
          <div
            class="flex items-center cursor-pointer font-bold text-sm"
            @click="selected[info.key] ? (selected[info.key] = false) : (selected[info.key] = !selected[info.key])"
          >
            <font-awesome-icon v-if="!selected[info.key]" icon="fa-solid fa-chevron-right" size="xs" class="mr-2" />
            <font-awesome-icon v-if="selected[info.key]" icon="fa-solid fa-chevron-down" size="xs" class="mr-2" />
            {{ info.title }}
          </div>
          <div v-if="selected[info.key]" v-html="info.content" class="ml-6 mt-4"></div>
        </div>
      </template>
    </div>
    <div class="md:basis-1/2 flex flex-col md:pl-2">
      <template
        v-for="info in membershipInformation.slice(
          mainStore.jurisdiction === 'uk' && whsActive ? 4 : 3,
          computedEndIndex
        )"
        :key="info.title"
      >
        <div class="py-4">
          <div
            class="flex items-center cursor-pointer font-bold text-sm"
            @click="selected[info.key] = !selected[info.key]"
          >
            <font-awesome-icon v-if="!selected[info.key]" icon="fa-solid fa-chevron-right" size="xs" class="mr-2" />
            <font-awesome-icon v-if="selected[info.key]" icon="fa-solid fa-chevron-down" size="xs" class="mr-2" />
            {{ info.title }}
          </div>
          <div v-if="selected[info.key]" v-html="info.content" class="ml-6 mt-4"></div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useMainStore } from '@/stores/mainStore.js';
import { useContractStore } from '@/stores/contractStore.js';

import AU_FAQ from '@/json/lvc-pro/au_faq.json';
import NZ_FAQ from '@/json/lvc-pro/nz_faq.json';
import UK_FAQ from '@/json/lvc-pro/uk_faq.json';
import AU_FAQ_EMPLOYMENT from '@/json/employment-hr/au_faq.json';

const mainStore = useMainStore();
const contractStore = useContractStore();
const selected = ref(null);

const MAP_INFORMATION = {
  au: AU_FAQ,
  nz: NZ_FAQ,
  uk: UK_FAQ,
};

const whsActive = computed(() => {
  return mainStore.quote.is_whs_sub_quote;
});

const membershipInformation = computed(() => {
  if (mainStore.jurisdiction === 'au' && mainStore.quote.is_employment_hr_quote) {
    return AU_FAQ_EMPLOYMENT;
  }
  return MAP_INFORMATION[mainStore.jurisdiction] ?? AU_FAQ;
});

const computedEndIndex = computed(() => {
  if (mainStore.jurisdiction === 'uk') {
    return whsActive.value ? 7 : 6;
  }
  return whsActive.value ? 6 : 5;
});
selected.value = new Array(computedEndIndex.value).fill(false);
</script>

<style scoped>
.faq :deep(p) {
  @apply text-sm my-2;
}
.faq :deep(ul) {
  @apply text-sm list-disc;
}
</style>
