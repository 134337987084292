<template>
  <div class="mb-4 md:mb-0">
    <div class="flex flex-col sm:flex-row mb-0 sm:mb-6">
      <div class="flex flex-col mb-6 sm:basis-1/2 sm:mb-0 pr-0 sm:pr-4">
        <div class="font-bold text-sm">Account Name</div>
        <div class="">{{ mainStore.bankAccountName }}</div>
      </div>
      <div class="flex flex-col mb-6 sm:basis-1/2 sm:mb-0 sm:pl-4">
        <div class="font-bold text-sm">Transaction reference</div>
        <div class="">{{ referenceString }}</div>
      </div>
    </div>
    <div v-if="mainStore.bsbNumber" class="flex flex-col sm:flex-row mb-0 sm:mb-6">
      <div class="flex flex-col mb-6 sm:basis-1/2 sm:mb-0 pr-0 sm:pr-4">
        <div class="font-bold text-sm">{{ mainStore.bsbName }}</div>
        <div class="">{{ mainStore.bsbNumber }}</div>
      </div>
      <div class="flex flex-col mb-6 sm:basis-1/2 sm:mb-0 sm:pl-4">
        <div class="font-bold text-sm">Account number</div>
        <div class="">{{ mainStore.bankAccountNumber }}</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useMainStore } from '@/stores/mainStore';
import { computed } from 'vue';

const mainStore = useMainStore();

const referenceString = computed(() => {
  if (mainStore.costEstimate) {
    return mainStore.unacceptedCostStagesWithPaymentAdvice.map((stage) => stage.latestPaymentAdvice.name).join(' ');
  }
  return mainStore.quote.name;
});
</script>
