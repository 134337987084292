<template>
  <div class="flex flex-col items-center justify-center pt-5">
    <template v-if="isCheckmark">
      <div class="flex justify-center items-center">
        <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
          <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
          <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
        </svg>
      </div>
    </template>
    <div class="text-center flex flex-col justify-center pt-5 text-lg font-semibold">
      <span v-if="amount">{{ mainStore.numberToCurrency(amount / 100) }}</span>
      <span class="font-normal">
        <slot />
      </span>
    </div>
    <div v-if="receiptUrl && mainStore.jurisdiction !== 'uk'" class="flex pt-3 md:pt-6">
      <a
        class="mx-auto bg-ming-600 text-white p-3 rounded-md flex items-center text-sm justify-center font-semibold"
        download
        :href="receiptUrl"
        target="_blank"
        >Download receipt
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          class="w-5 h-5 ml-2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
          />
        </svg>
      </a>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { usePaymentStore } from '@/stores/paymentStore';
import { useMainStore } from '@/stores/mainStore';

defineProps({
  isCheckmark: {
    type: Boolean,
    default: true,
  },
});

const paymentStore = usePaymentStore();
const mainStore = useMainStore();
const receiptUrl = ref('');
const amount = ref(null);

const intent = computed(() => mainStore.quote?.confirmed_intent);

watch(
  intent,
  (value) => {
    if (value) {
      axios
        .get(`/api/payment-intent/${value.id}`)
        .then((response) => {
          receiptUrl.value = `/api/receipts/${value.id}/download`;
          amount.value = response.amount;
        })
        .catch(() => {});
    }
  },
  { immediate: true }
);
</script>

<style scoped>
.checkmark {
  width: 116px;
  height: 116px;
  border-radius: 50%;
  display: block;
  stroke-width: 5;
  stroke: white;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #36b37e;
  animation: fill 0.4s ease-in-out 0.4s forwards;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 5;
  stroke-miterlimit: 1;
  stroke: #36b37e;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(0.1, 0.1, 0);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 78px #36b37e;
  }
}
</style>
