<template>
  <div>
    <template v-if="!mainStore.transactionComplete">
      <Form @submit="onSubmit" :validation-schema="schema">
        <section-title>Payment Details</section-title>
        <form-section :show-label="false">
          <template v-if="paymentStore.isMultiPayment">
            <div class="columns-2 flex">
              <div class="mb-2">
                <label for="numberthing" class="block mb-2 font-semibold text-xs sm:text-sm">Amount</label>
                <input
                  v-model="paymentStore.multiPaymentAmount"
                  id="numberthing"
                  type="number"
                  step="0.01"
                  class="h-10 sm:h-12 bg-lv-slate-20 border border-lv-slate-200 rounded-lg placeholder:text-lv-slate-600 placeholder:text-sm text-sm sm:text-base block w-full p-2.5 focus:outline-none"
                />
              </div>
            </div>
          </template>
          <div class="columns-1 md:columns-2 mb-0 md:mb-2">
            <p-input
              v-model="paymentStore.paymentMethodOwner.name"
              label="Name"
              name="name"
              placeholder="Enter name on card..."
            />
            <p-input
              v-model="paymentStore.paymentMethodOwner.email"
              label="Email"
              type="email"
              name="email"
              placeholder="example@company.com"
            />
          </div>
          <stripe-card-element />
          <payment-error-message />
          <template v-if="showSurchargeText">
            <div class="text-center mt-4">
              <span class="font-italic text-xs sm:text-sm">
                This transaction is subject to a surcharge of {{ paymentStore.cardFeePercent('card') }}% for Visa or
                Mastercard, and {{ paymentStore.cardFeePercent('amex') }}% for American Express.
              </span>
            </div>
          </template>
        </form-section>
        <information-grid v-if="mainStore.quote?.payment_terms" :data-array="paymentTerms"></information-grid>
        <div class="flex justify-center space-x-8 my-8">
          <div class="flex-col text-left font-semibold text-sm">
            <div class="mb-2">Balance due</div>
            <div class="mb-2">Processing fee</div>
            <div class="mb-2">Total</div>
          </div>
          <div class="flex-col text-sm">
            <div class="mb-2">{{ mainStore.numberToCurrency(paymentStore.paymentAmount) }}</div>
            <div class="mb-2">{{ mainStore.numberToCurrency(paymentStore.processingFee) }}</div>
            <div class="mb-2">{{ mainStore.numberToCurrency(paymentStore.paymentTotal) }}</div>
          </div>
        </div>
        <footer-nav
          previous
          submit
          is-last-step
          :next="'Pay ' + mainStore.numberToCurrency(paymentStore.paymentTotal)"
        ></footer-nav>
      </Form>
    </template>
    <template v-else>
      <transaction-success>Payment complete.</transaction-success>
    </template>
    <loading-overlay :loading="paymentStore.checkingPayment" text="Processing payment"></loading-overlay>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, computed } from 'vue';

import SectionTitle from '@/components/SectionTitle.vue';
import PaymentTypeSelect from '@/components/PaymentTypeSelect.vue';
import FormSection from '@/components/FormSection.vue';
import PInput from '@/components/PInput.vue';
import StripeCardElement from '@/components/stripe/StripeCardElement.vue';
import StripeBecsDebitElement from '@/components/stripe/StripeBecsDebitElement.vue';
import InformationGrid from '@/components/InformationGrid.vue';
import FooterNav from '@/components/FooterNav.vue';
import PaymentErrorMessage from '@/components/PaymentErrorMessage.vue';
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import TransactionSuccess from '@/components/TransactionSuccess.vue';

import { Form, useForm } from 'vee-validate';
import { object, string } from 'yup';

import { usePaymentStore } from '@/stores/paymentStore';
import { useMainStore } from '@/stores/mainStore';

const paymentStore = usePaymentStore();
const mainStore = useMainStore();
const { handleSubmit } = useForm();

const schema = object({
  name: string().required('This field is required'),
  email: string().email('Must be a valid email address').required('This field is required'),
});

const paymentTerms = ref([
  {
    label: 'Payment Terms',
    text: mainStore.quote?.payment_terms,
  },
]);

const showSurchargeText = computed(() => {
  return mainStore.jurisdiction !== 'uk' && !mainStore.quote.is_lvc_pro_invoice && paymentStore.paymentAmount > 0;
});

onMounted(() => {
  if (paymentStore.isMultiPayment) {
    paymentStore.$patch({
      multiPaymentAmount: mainStore.quote.balance,
    });
  }
});

const onSubmit = handleSubmit(() => {
  paymentStore.processCardPayment();
});
</script>
