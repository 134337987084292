<template>
  <div>
    <div class="bg-white border rounded-md mb-10 -mx-5 sm:mx-0 text-xs">
      <div class="p-4 sm:p-5">
        <div class="overflow-x-scroll md:overflow-hidden relative">
          <table class="table-auto rounded-md border-spacing-2 border border-lv-slate-200 w-full">
            <thead>
              <tr class="font-semibold border-b whitespace-nowrap">
                <th class="border-r p-3 sticky left-0 bg-white">
                  <div class="flex items-center">
                    <div class="flex items-center cursor-pointer">
                      <input
                        id="accept-checkbox"
                        type="checkbox"
                        @click="selectAll"
                        v-model="allSelected"
                        class="mr-1 w-4 h-4 border border-lv-slate-200 rounded-lg checked:accent-ming-600 hover:accent-ming-500"
                      />
                      <label for="accept-checkbox" class="ml-3 cursor-pointer"> Invoice Number</label>
                    </div>
                  </div>
                </th>
                <th class="p-3 text-left">Invoice Date</th>
                <th class="p-3 text-left">Due Date</th>
                <th class="p-3 text-left">Invoice Amount</th>
                <th class="p-3 text-left">Payment</th>
                <th class="p-3 text-left">Balance Due</th>
                <th class="p-3 text-left sticky -right-1 bg-white"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(invoice, index) in paymentStore.outstandingInvoices"
                :key="invoice.id"
                class="whitespace-nowrap"
              >
                <td class="border-r p-3 sticky left-0 bg-white">
                  <div class="flex items-center cursor-pointer">
                    <input
                      :id="'accept-checkbox-' + index"
                      v-model="selectedInvoices[index]"
                      @click="
                        paymentStore.outstandingInvoices[index].selected =
                          !paymentStore.outstandingInvoices[index].selected
                      "
                      type="checkbox"
                      class="mr-1 w-4 h-4 border border-lv-slate-200 rounded-lg checked:accent-ming-600 hover:accent-ming-500"
                    />
                    <label :for="'accept-checkbox-' + index" class="ml-3 cursor-pointer"> {{ invoice.name }}</label>
                  </div>
                </td>
                <td class="p-3">{{ mainStore.convertDateToString(invoice.invoice_date) }}</td>
                <td class="p-3">{{ mainStore.convertDateToString(invoice.due_date) }}</td>
                <td class="p-3">{{ mainStore.numberToCurrency(invoice.invoice_total) }}</td>
                <td class="p-3">{{ mainStore.numberToCurrency(invoice.payment_total) }}</td>
                <td class="p-3">{{ mainStore.numberToCurrency(invoice.balance_due) }}</td>
                <td class="p-3 sticky -right-1 bg-white">
                  <button @click="downloadInvoice(invoice)">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                      />
                    </svg>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="flex space-x-8 my-8 justify-center">
          <div class="flex-col text-left font-semibold text-sm">
            <div class="mb-2">Invoice Total</div>
            <div class="mb-2">Payment Total</div>
            <div class="mb-4">Balance Total</div>
            <div class="mb-2">Selected Total</div>
          </div>
          <div class="flex-col text-sm">
            <div class="mb-2">{{ mainStore.numberToCurrency(invoiceTotal) }}</div>
            <div class="mb-2">{{ mainStore.numberToCurrency(paymentTotal) }}</div>
            <div class="mb-4">{{ mainStore.numberToCurrency(balanceTotal) }}</div>
            <div class="mb-2">{{ mainStore.numberToCurrency(paymentStore.paymentAmount) }}</div>
          </div>
        </div>
      </div>
    </div>
    <footer-nav next="Payment"></footer-nav>
  </div>
</template>

<script setup>
import { ref, reactive, watch, computed } from 'vue';
import { usePaymentStore } from '@/stores/paymentStore';
import FooterNav from '@/components/FooterNav.vue';
import { all } from 'axios';

const allSelected = ref(false);
const selectedInvoices = reactive([]);

const paymentStore = usePaymentStore();

function downloadInvoice(invoice) {
  const link = document.createElement('a');
  const url = `/api/quote/${invoice.id}/download`;
  link.setAttribute('href', url);
  link.setAttribute('download', invoice.name);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

const invoiceTotal = computed(() => {
  return paymentStore.outstandingInvoices.reduce((total, invoice) => {
    return total + invoice.invoice_total;
  }, 0);
});

const paymentTotal = computed(() => {
  return paymentStore.outstandingInvoices.reduce((total, invoice) => {
    return total + invoice.payment_total;
  }, 0);
});

const balanceTotal = computed(() => {
  return paymentStore.outstandingInvoices.reduce((total, invoice) => {
    return total + Number(invoice.balance_due);
  }, 0);
});

function selectAll() {
  if (allSelected.value) {
    paymentStore.outstandingInvoices.forEach((invoice, index) => {
      invoice.selected = false;
      selectedInvoices[index] = false;
    });
    allSelected.value = false;
  } else {
    paymentStore.outstandingInvoices.forEach((invoice, index) => {
      invoice.selected = true;
      selectedInvoices[index] = true;
    });
    allSelected.value = true;
  }
}

watch(selectedInvoices, () => {
  if (selectedInvoices.every((invoice) => !!invoice)) {
    allSelected.value = true;
  } else {
    allSelected.value = false;
  }
});
</script>
