<template>
  <div class="bg-white border border-lv-slate-200 rounded-md mb-10 -mx-5 sm:mx-0">
    <div class="border-b border-lv-slate-200 p-4 sm:p-5">
      <span class="font-semibold">Details</span>
    </div>
    <div class="p-4 sm:p-5">
      <div v-for="qli in orderedList" :key="qli.name" class="text-sm mb-8">
        <div class="flex flex-col sm:flex-row sm:items-center justify-between mb-4">
          <div class="flex border border-lv-slate-200 rounded-md font-semibold p-2">{{ qli.name }}</div>
          <div class="flex flex-col sm:flex-row sm:space-x-4 justify-between">
            <div v-if="qli.discount" class="flex flex-row justify-between sm:flex-col text-right mt-3 sm:mt-0">
              <div class="font-semibold">Discount</div>
              <div>{{ formattedDiscount(qli) }}</div>
            </div>
            <div class="flex flex-row justify-between sm:flex-col text-right mt-3 sm:mt-0">
              <div class="font-semibold">{{ qli.discount > 0 ? 'Discount Price' : 'Price' }}</div>
              <div>{{ mainStore.numberToCurrency(qli.subtotal) }}</div>
            </div>
          </div>
        </div>
        <div class="mb-4" v-if="qli.description">
          <p class="mb-4" v-html="replaceDescription(qli.description)"></p>
        </div>
        <template v-if="qli.activity_items?.length">
          <div v-for="item in qli.activity_items" :key="item.name">
            <div class="flex flex-col sm:flex-row sm:items-center justify-between mb-4">
              <div class="flex flex-col">
                <div class="flex border border-lv-slate-200 bg-light-slate-grey rounded-md font-semibold p-2">
                  {{ item.name }}
                </div>
                <div class="mt-4" v-if="item.description">
                  <p v-html="replaceDescription(item.description)"></p>
                </div>
              </div>
              <div class="flex flex-row justify-between sm:flex-col text-right mt-3 sm:mt-0">
                <div class="font-semibold">Price</div>
                <div>{{ mainStore.numberToCurrency(item.amount) }}</div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="border-t border-lv-slate-200 p-4 sm:p-5 flex items-end flex-col text-sm">
      <div v-if="localQuote.discount > 0" class="flex justify-between w-full mb-2 md:w-1/3">
        <span class="font-semibold">Discount</span>
        <span class="">{{ mainStore.numberToCurrency(localQuote.discount) }}</span>
      </div>
      <div class="flex justify-between w-full mb-2 md:w-1/3">
        <span class="font-semibold">Subtotal</span>
        <span class="">{{ mainStore.numberToCurrency(localQuote.subtotal) }}</span>
      </div>
      <div class="flex justify-between w-full mb-2 md:w-1/3">
        <span class="font-semibold">{{ mainStore.taxType }}</span>
        <span class="">{{ mainStore.numberToCurrency(localQuote.gst) }}</span>
      </div>
      <div class="flex justify-between w-full mb-2 md:w-1/3">
        <span class="font-semibold">Total</span>
        <span class="">{{ mainStore.numberToCurrency(localQuote.total) }}</span>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed } from 'vue';
import { useMainStore } from '@/stores/mainStore.js';

const mainStore = useMainStore();

const orderedList = computed(() => {
  return [...mainStore.quote.quote_line_items].sort((a, b) => a.position - b.position);
});

const localQuote = mainStore.quote;

const productScopeMapper = {
  'Scope of Work:': "<span class='font-semibold'>Scope of Work:</span>",
  'Scope of work:': "<span class='font-semibold'>Scope of work:</span>",
  'Complimentary Work:': "<span class='font-semibold'>Complimentary Work:</span>",
  'Complimentary work:': "<span class='font-semibold'>Complimentary Work:</span>",
  'Complimentary:': "<span class='font-semibold'>Complimentary:</span>",
};

const replaceDescription = (description) => {
  for (const searchString in productScopeMapper) {
    description = description.replace(searchString, productScopeMapper[searchString]);
  }
  return description.replace(/\n/g, '<br />');
};

const isDiscounted = computed(() => {
  return mainStore.quote.quote_line_items.some((item) => item.discount > 0);
});

function formattedDiscount(qli) {
  return qli.discount_type ? `${qli.discount}%` : mainStore.numberToCurrency(qli.discount);
}
</script>
