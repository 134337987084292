import { defineStore } from 'pinia';

export const useMainStore = defineStore('main', {
  state: () => ({
    environment: 'local',
    jurisdiction: 'au',
    currentStep: 0,
    stageReached: 0,
    title: '',
    isPreview: false,
    quote: null,
    costEstimate: null,
    businessInformation: {},
    isBusinessInformationUpdated: false,
    isUpdatingBusinessInformation: false,
    stripe: null,
    transactionComplete: false,
    errorMessage: '',
    stickyButton: false,
  }),
  getters: {
    unacceptedCostStages: (state) => {
      return state.costEstimate?.stages.filter((stage) => !stage.accepted);
    },
    acceptedCostStages: (state) => {
      return state.costEstimate?.stages.filter((stage) => stage.accepted);
    },
    unacceptedCostStagesWithPaymentAdvice() {
      return this.unacceptedCostStages?.filter((stage) => !!stage.latestPaymentAdvice);
    },
    upfrontCostEstimate: (state) => {
      return (
        state.costEstimate?.stages
          .filter((stage) => !stage.accepted)
          .some((stage) => !!stage.latestUnpaidPaymentAdvice) || false
      );
    },
    costAgreementUrl: (state) => {
      return state.quote?.cost_agreement_url || state.costEstimate?.cost_agreement_url || '/costs-agreement';
    },
    currencyType: (state) => {
      return state.jurisdiction === 'uk' ? '£' : '$';
    },
    bsbNumber: (state) => {
      const BSB_MAP = {
        au: '182222',
        nz: '',
        uk: '20-00-00',
      };
      return BSB_MAP[state.jurisdiction];
    },
    bsbName: (state) => {
      const BSB_NAME_MAP = {
        au: 'Account BSB',
        nz: '',
        uk: 'Sort Code',
      };
      return BSB_NAME_MAP[state.jurisdiction];
    },
    bankAccountName: (state) => {
      const BANK_ACCOUNT_NAME_MAP = {
        au: 'Legalvision ILP Pty Ltd Law Practice Trust Account',
        nz: 'LegalVision NZ Limited - Nominated Trust',
        uk: 'LegalVision Law UK Ltd',
      };
      return BANK_ACCOUNT_NAME_MAP[state.jurisdiction];
    },
    bankAccountNumber: (state) => {
      const BANK_ACCOUNT_NUMBER_MAP = {
        au: '303836837',
        nz: '12-3490-0043639-02',
        uk: '73521524',
      };
      return BANK_ACCOUNT_NUMBER_MAP[state.jurisdiction];
    },
    currencyString: (state) => {
      const CURRENCY_MAP = {
        au: 'aud',
        nz: 'nzd',
        uk: 'gbp',
      };
      return CURRENCY_MAP[state.jurisdiction];
    },
    companyNumber: (state) => {
      const COMPANY_NUMBER_MAP = {
        au: 'ABN',
        nz: 'NZBN',
        uk: 'Company Number',
      };
      return COMPANY_NUMBER_MAP[state.jurisdiction];
    },
    area: (state) => {
      const AREA_MAP = {
        au: 'Town/City',
        nz: 'Suburb',
        uk: 'Town/City',
      };

      return AREA_MAP[state.jurisdiction];
    },
    state: (state) => {
      const STATE_MAP = {
        au: 'State',
        nz: 'Suburb',
        uk: 'County',
      };

      return STATE_MAP[state.jurisdiction];
    },
    taxType: (state) => {
      return state.jurisdiction === 'uk' ? 'VAT' : 'GST';
    },
    numberToCurrency() {
      const currencyType = this.currencyType;
      return (number) =>
        currencyType +
        Number(number).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
    },
    convertDateToString: (state) => {
      return (date) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return new Date(date).toLocaleDateString(undefined, options);
      };
    },
    phoneNumber: (state) => {
      const JURISDICTION_PHONE_NUMBER_MAP = {
        au: '1300 544 755',
        nz: '0800 005 570',
        uk: '0808 196 8584',
      };

      return JURISDICTION_PHONE_NUMBER_MAP[state.jurisdiction];
    },
    whsShort: (state) => {
      return state.jurisdiction === 'au' ? 'WHS' : 'HSW';
    },
    whsLong: (state) => {
      return state.jurisdiction === 'au' ? 'Work, Health and Safety' : 'Health and Safety at Work';
    },
  },
  actions: {
    getQuote(quoteId) {
      if (this.quote && this.quote.id === quoteId) {
        return Promise.resolve();
      }
      return axios.get(`/api/quote/${quoteId}`).then((response) => (this.quote = response.data));
    },
    getCostEstimate(costEstimateId) {
      return axios.get(`/api/cost-estimate/${costEstimateId}`).then((response) => (this.costEstimate = response.data));
    },
    logCostEstimateAccess(costEstimateId) {
      return axios.post(`/api/cost-estimate/${costEstimateId}/log`, {});
    },
    updateQuoteTerm(data) {
      const quoteId = this.quote.id;
      return axios.put(`/api/quote/${quoteId}`, data);
    },
    updateBusinessInformation() {
      const quoteId = this.quote?.id;
      const costEstimateId = this.costEstimate?.id;
      const original = this.businessInformation.original;
      const updated = this.businessInformation.updated;
      this.isUpdatingBusinessInformation = true;
      if (JSON.stringify(original) === JSON.stringify(updated)) {
        this.isBusinessInformationUpdated = true;
        this.isUpdatingBusinessInformation = false;
        return true;
      }
      let endpoint = '';
      if (quoteId) {
        endpoint = `/api/business-information/${quoteId}/quote`;
      }

      if (costEstimateId) {
        endpoint = `/api/business-information/${costEstimateId}/cost-estimate`;
      }
      return axios
        .post(endpoint, {
          original,
          updated,
        })
        .then(() => {
          this.isBusinessInformationUpdated = true;
        })
        .catch(() => {})
        .finally(() => {
          this.isUpdatingBusinessInformation = false;
        });
    },
    logEvent(event) {
      const quoteId = this.quote?.id;
      return axios.post(`/api/quote/${quoteId}/log`, event).catch(() => {});
    },
    downloadQuote() {
      const quoteId = this.quote?.id;
      window.location = `/api/quote/${quoteId}/download`;
    },
  },
});
