<template>
  <div class="flex flex-col md:grid gap-6 mb-6 info-grid" :class="gridClass">
    <div v-for="el in dataArray" :key="el.label" class="bg-white p-4 rounded-md flex flex-col">
      <div class="flex flex-row items-center mb-2">
        <div v-if="el.icon" class="flex justify-center items-center mr-3">
          <img :src="'/img/icons/' + el.icon + '.svg'" alt="" />
        </div>
        <div v-if="el.fontAwesomeIcon?.icon" class="flex justify-center items-center mr-3">
          <font-awesome-icon
            :icon="`fa-solid ${el.fontAwesomeIcon.icon}`"
            :class="`${el.fontAwesomeIcon.colourClass}`"
          ></font-awesome-icon>
        </div>
        <div class="font-bold flex items-center" :class="{ 'ml-2': el.icon, 'ml-0': !el.icon }">
          {{ el.label }}
        </div>
      </div>
      <div class="flex flex-col justify-between h-full">
        <div class="line-clamp-3" :class="{ 'line-clamp-none': isExpanded }" v-html="el.text.replace(/^\n/, '')"></div>
        <button
          v-if="!isExpanded && !startOpen"
          class="mt-2 ml-2 self-start justify-self-end"
          @click="isExpanded = true"
        >
          ...See More
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';

const props = defineProps({
  dataArray: {
    type: Array,
    default: () => [],
    required: true,
  },
  startOpen: {
    type: Boolean,
    default: false,
  },
});

const isExpanded = ref(props.startOpen);

const gridClass = computed(() => {
  if (props.dataArray.length === 1) return 'grid-cols-1';
  if (props.dataArray.length === 2) return 'grid-cols-2';
  if (props.dataArray.length === 3) return 'grid-cols-3';
});
</script>

<style scoped>
.info-grid :deep(ul) {
  list-style-type: disc;
  @apply list-inside pl-2;
}
.info-grid :deep(ol) {
  list-style-type: decimal;
  @apply list-inside pl-2;
}
.info-grid :deep(p) {
  margin: 6px 0;
}
</style>
