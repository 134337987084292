<template>
  <div class="container mx-auto justify-center flex flex-col mb-14 p-4 md:py-0">
    <template v-if="isLoaded">
      <preview-banner></preview-banner>
      <client-name></client-name>
      <step-progress :steps="steps"></step-progress>
      <transition name="fade" appear>
        <keep-alive>
          <component :is="steps[mainStore.currentStep].component"></component>
        </keep-alive>
      </transition>
    </template>
    <loading-spinner v-else></loading-spinner>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { useMainStore } from '@/stores/mainStore';
import { useContractStore } from '@/stores/contractStore';
import { useRoute, useRouter } from 'vue-router';

import ClientName from '@/components/ClientName.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import PreviewBanner from '@/components/PreviewBanner.vue';
import StepProgress from '@/components/StepProgress.vue';

import PartOne from '@/components/lvc-pro/PartOne.vue';
import PartTwo from '@/components/lvc-pro/PartTwo.vue';
import PartThree from '@/components/lvc-pro/PartThree.vue';

const mainStore = useMainStore();
const contractStore = useContractStore();
const route = useRoute();
const router = useRouter();

const steps = [
  {
    name: 'Summary',
    component: PartOne,
  },
  {
    name: 'Account information',
    component: PartTwo,
  },
  {
    name: 'Sign contract',
    component: PartThree,
  },
];

onMounted(() => {
  mainStore.$subscribe(function (mutation, state) {
    if (mutation?.payload?.currentStep && mainStore.currentStep > mainStore.stageReached) {
      mainStore.$patch({
        stageReached: mainStore.stageReached + 1,
      });
    }
  });

  mainStore.isPreview = !!route.query.preview;

  if (mainStore.isPreview) {
    mainStore.stageReached = 3;
  }

  router.push({ query: {} });
});

const isLoaded = ref(false);

mainStore
  .getQuote(route.params.id)
  .then(() => {
    mainStore.businessInformation.original = { ...mainStore.quote.business_information };
    mainStore.businessInformation.updated = { ...mainStore.quote.business_information };
    if (mainStore.quote.envelope_id) {
      contractStore.getEnvelope(mainStore.quote.envelope_id);
    }

    if (mainStore.quote.terms_accepted) {
      mainStore.stageReached = 3;
    }
    isLoaded.value = true;
  })
  .catch(() => {
    mainStore.errorMessage = 'Sorry we could not find your membership quote.';
    router.push({ name: 'NotFound' });
  })
  .finally(() => {
    mainStore.title = 'Membership proposal';
  });
</script>
