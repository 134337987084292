<template>
  <template v-if="!mainStore.quote.terms_accepted && !mainStore.quote.is_no_charge_sub">
    <div class="flex md:hidden">
      <div class="flex p-1 border border-lv-slate-200 bg-lv-slate-20 rounded-lg mx-auto lg:mx-0 mb-4 w-full sm:w-1/2">
        <button
          v-for="(option, index) in contractOptions"
          :key="index"
          type="button"
          class="rounded-md px-3 py-2 uppercase lg:basis-auto"
          :class="{
            'bg-[#52707A] text-white rounded-md font-semibold': selected === index,
            'text-[#29383D] font-normal': selected !== index,
            'basis-1/3': contractOptions.length === 3,
            'basis-1/2': contractOptions.length === 2,
            'basis-full': contractOptions.length === 1,
          }"
          @click="handleTierSelect(index)"
        >
          {{ option.subscription_term.label }}
        </button>
      </div>
    </div>
    <carousel class="carousel" ref="tierCarousel" v-bind="settings" :breakpoints="breakpoints">
      <slide v-for="(option, index) in contractOptions" :key="option.subscription_term.value">
        <div
          class="flex h-full w-full"
          :class="{
            'pr-2 lg:pr-3': index === 0 && contractOptions.length > 1,
            'px-0 sm:px-2 lg:px-3': index !== 0 && index !== contractOptions.length - 1 && contractOptions.length > 1,
            'pl-2 lg:pl-3': index === contractOptions.length - 1 && contractOptions.length > 1,
            'w-auto md:mr-auto': index === 0 && contractOptions.length === 1,
          }"
        >
          <div
            class="border flex flex-col rounded-md p-4 lg:p-6 cursor-pointer border-t-8 w-full h-full relative"
            :class="{
              'bg-ming-50 border-ming-700 lg:scale-[1.02]': selected === index,
              'bg-white border-lv-slate-200': selected !== index,
            }"
            @click="handleTierSelect(index)"
          >
            <template v-if="yearlySaving(option, index) > 0">
              <div
                class="bg-vivid-tangelo rounded-b-lg text-sm text-white flex items-center font-semibold px-3 py-1 absolute -top-2 left-1/2 transform -translate-x-1/2 whitespace-nowrap"
              >
                {{ mainStore.numberToCurrency(yearlySaving(option, index)) }} saved per year
              </div>
            </template>
            <div class="flex mb-6 mt-2 font-bold uppercase text-left">
              {{ option.subscription_term.label }}
            </div>
            <div class="font-bold text-2xl mb-4 text-left">{{ weeklyCost(option) }} / week</div>
            <div class="flex flex-col text-sm mb-4 text-left">
              <span
                >{{ mainStore.numberToCurrency(monthlyCost(option, false)) }} (excl. {{ mainStore.taxType }})<span
                  v-if="localQuote.type === 'Payment_Advice'"
                >
                  monthly</span
                ></span
              >
              <span v-if="localQuote.type !== 'Payment_Advice'">billed monthly</span>
            </div>
            <button
              v-if="contractOptions.length > 1"
              class="rounded-md px-3 font-semibold text-sm mb-4 w-full h-10"
              :class="{
                'bg-ming-700 text-white': selected === index,
                'bg-dark-grey-slate/[.1]': selected !== index,
              }"
            >
              {{ selected === index ? 'Selected' : 'Select' }}
            </button>
            <div class="text-sm mb-6 flex flex-col">
              <ul>
                <li
                  v-for="(inclusion, index) in tierInclusions(option, index)"
                  :key="inclusion"
                  class="flex text-left text-sm py-1.5"
                >
                  <div class="mr-2 basis-2/8">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      class="w-6 h-6 text-vivid-tangelo"
                      :class="{
                        'text-lv-slate-200': !option.ecb_amount && index === 0,
                      }"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                      />
                    </svg>
                  </div>
                  <div class="basis-6/8 flex items-center font-semibold">{{ inclusion }}</div>
                </li>
              </ul>
              <details @click.stop.prevent="toggleDetails(index)" :open="showDetails">
                <summary class="flex text-left py-1.5">
                  <div class="mr-2 basis-2/8">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      class="w-6 h-6 text-vivid-tangelo"
                      :class="{
                        'text-lv-slate-200': !option.ecb_amount && index === 0,
                      }"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                      />
                    </svg>
                  </div>
                  <div class="flex items-center">
                    <span>Membership Benefits</span>
                    <font-awesome-icon
                      v-if="!showDetails"
                      icon="fa-solid fa-plus"
                      size="lg"
                      class="ml-2 text-teal font-semibold"
                    />
                    <font-awesome-icon v-if="showDetails" icon="fa-solid fa-minus" size="lg" class="ml-2" />
                  </div>
                </summary>
                <ul class="pl-5">
                  <li v-for="inclusion in membershipFeatures" :key="inclusion" class="flex text-left text-sm py-1">
                    &bull;&nbsp;
                    <div class="basis-6/8">{{ inclusion }}</div>
                  </li>
                </ul>
                <ul v-if="localQuote.is_rm_sub_quote" class="pl-5">
                  <li class="flex text-sm py-1">
                    &bull;&nbsp;
                    <div class="basis-6/8 text-left font-normal">Dedicated Relationship Manager</div>
                  </li>
                </ul>
                <ul v-if="localQuote.is_pcs_sub_quote" class="pl-5">
                  <li class="flex text-sm py-1">
                    &bull;&nbsp;
                    <div
                      class="basis-6/8 text-left"
                      :class="{ 'line-through font-normal': !pcsActive, ' font-normal': pcsActive }"
                    >
                      Privacy Compliance Service
                    </div>
                  </li>
                </ul>
                <ul
                  v-if="localQuote.is_whs_sub_quote || localQuote.subscription?.is_upgrade_with_active_whs"
                  class="pl-5"
                >
                  <li v-for="inclusion in whsInclusions" :key="inclusion" class="flex text-sm py-1">
                    &bull;&nbsp;
                    <div
                      v-html="inclusion"
                      class="basis-6/8 text-left"
                      :class="{ 'line-through font-normal': !whsActive, ' font-normal': whsActive }"
                    ></div>
                  </li>
                </ul>
              </details>
            </div>
            <div class="mt-auto">
              <template v-if="localQuote.is_whs_sub_quote || localQuote.subscription?.is_upgrade_with_active_whs">
                <div class="flex" v-if="!localQuote.is_whs_bundled && !localQuote.is_no_charge_sub">
                  <p-input
                    v-model="whsActive"
                    type="toggle"
                    :label="mainStore.whsShort + ' Advisory Support'"
                    :required="false"
                    name="whs"
                    @click.stop.prevent="whsActive = !whsActive"
                  ></p-input>
                </div>
                <div class="text-left text-xs" :class="{ 'text-sm font-semibold': localQuote.is_whs_bundled }">
                  {{ localQuote.is_whs_bundled ? 'Includes ' : '' }}
                  {{
                    mainStore.jurisdiction === 'au'
                      ? 'Work, Health and Safety Advisory'
                      : 'Health and Safety at Work Advisory'
                  }}
                  <span v-if="!localQuote.is_whs_bundled">
                    ({{
                      mainStore.numberToCurrency(localQuote.is_no_charge_sub ? 0 : option.whs_weekly_cost)
                    }}
                    /week)</span
                  >
                </div>
              </template>
              <template v-if="localQuote.is_pcs_sub_quote">
                <div class="flex mt-2">
                  <p-input
                    v-model="pcsActive"
                    type="toggle"
                    :label="'Privacy Compliance Service'"
                    :required="false"
                    name="whs"
                    @click.stop.prevent="pcsActive = !pcsActive"
                  ></p-input>
                </div>
                <div class="text-left text-xs">
                  Privacy Compliance Service
                  <span>
                    ({{
                      mainStore.numberToCurrency(localQuote.is_no_charge_sub ? 0 : option.pcs_weekly_cost)
                    }}
                    /week)</span
                  >
                </div>
              </template>
              <div v-if="localQuote.is_rm_sub_quote" class="text-left text-xs mt-1 font-semibold">
                Includes Relationship Manager
              </div>
            </div>
          </div>
        </div>
      </slide>
    </carousel>
    <form-section label="Additional Terms" v-if="localQuote.is_employment_hr_quote" class="mt-4">
      <p class="text-left text-sm py-1">
        Please note the following special terms that apply to our Costs Agreement for our Employment + HR Advisory
        Service:
      </p>
      <ul>
        <li class="flex text-left text-sm py-1">
          &bull;&nbsp;Extras Cover does not apply for our Employment + HR Advisory Service members.
        </li>
        <li class="flex text-left text-sm py-1">
          &bull;&nbsp;Your pricing tier may increase if your employee headcount increases during the term of your
          membership.
        </li>
      </ul>
    </form-section>
  </template>
  <template v-else>
    <div class="flex flex-col sm:flex-row mb-0 sm:mb-8">
      <div class="flex flex-col mb-6 sm:basis-1/2 sm:mb-0 pr-0 sm:pr-4 border-l-2 border-lv-slate-200 pl-3">
        <div class="font-bold">{{ contractStore.contractData.subscription_term.label }}</div>
        <div class="text-sm">Membership Term</div>
      </div>
      <div class="flex flex-col mb-6 sm:basis-1/2 sm:mb-0 sm:pl-4 border-l-2 border-lv-slate-200 pl-3">
        <div class="font-bold">
          {{ mainStore.numberToCurrency(contractStore.contractData.monthly_cost) }} (incl. {{ mainStore.taxType }})
        </div>
        <div class="text-sm">Monthly Billing Fee</div>
      </div>
      <div class="flex flex-col mb-6 sm:basis-1/2 sm:mb-0 sm:pl-4 border-l-2 border-lv-slate-200 pl-3">
        <div class="font-bold">{{ contractEndDateString }}</div>
        <div class="text-sm">Membership Term End</div>
      </div>
      <div
        v-if="contractStore.contractData.whs_addon_enabled"
        class="flex flex-col mb-6 sm:basis-1/2 sm:mb-0 sm:pl-4 border-l-2 border-lv-slate-200 pl-3"
      >
        <div class="font-bold">
          {{ mainStore.whsLong }}
        </div>
        <div class="text-sm">Additional Support</div>
      </div>
    </div>
  </template>
</template>

<script setup>
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { useMainStore } from '@/stores/mainStore';
import { useContractStore } from '@/stores/contractStore';
import { Carousel, Slide, Pagination } from 'vue3-carousel';
import FormSection from '@/components/FormSection.vue';

import PInput from '@/components/PInput.vue';

import AU_PROMO from '@/json/lvc-pro/au_promo.json';
import NZ_PROMO from '@/json/lvc-pro/nz_promo.json';
import UK_PROMO from '@/json/lvc-pro/uk_promo.json';

const mainStore = useMainStore();
const contractStore = useContractStore();
const tierCarousel = ref(null);

const localQuote = mainStore.quote;

const whsActive = ref(mainStore.quote.is_whs_sub_quote || !!mainStore.quote.is_whs_bundled);
const pcsActive = ref(mainStore.quote.is_pcs_sub_quote);
const rmActive = ref(mainStore.quote.is_rm_sub_quote);
const selected = ref(mainStore.quote.lvc_pro_contract_options.length - 1);

const showDetails = ref(
  !whsActive.value || localQuote.lvc_pro_contract_options.length === 1 || localQuote.is_employment_hr_quote
);

function toggleDetails(index) {
  showDetails.value = !showDetails.value;
}

onMounted(() => {
  if (!mainStore.quote.terms_accepted && !mainStore.quote.is_no_charge_sub) {
    tierCarousel.value.slideTo(selected.value);
  }
});

const isPaid = computed(() => {
  return mainStore.quote.status === 'Paid' || mainStore.quote.confirmed_intent;
});

const contractOptions = computed(() => {
  if (
    localQuote.terms_accepted ||
    localQuote.is_no_charge_sub ||
    (localQuote.type === 'Payment_Advice' && isPaid.value)
  ) {
    selected.value = 0;

    return localQuote.lvc_pro_contract_options.filter(
      (option) => option.subscription_term.label === localQuote.subscription_term
    );
  }
  if (
    [
      '0a32ae98-326e-11ee-a037-06c1d03e00f4',
      'd7e4bfe6-2c0d-11ee-adf8-024266d70dec',
      '5e7faf24-993d-11ee-a10d-06c1d03e00f4',
      'a0fa2d62-c47a-11ee-a68b-06c1d03e00f4',
      'ac0ab878-8d81-11ee-bda1-06c1d03e00f4',
    ].indexOf(mainStore.quote.id) > -1
  ) {
    selected.value = 0;

    return localQuote.lvc_pro_contract_options.filter((option) => option.subscription_term.label === '3 Years');
  }

  if (mainStore.quote.id === 'a5e32f86-94aa-11ee-876b-0673d455dffe') {
    selected.value = 0;

    return localQuote.lvc_pro_contract_options.filter((option) => option.subscription_term.label === '1 Year');
  }

  if (mainStore.quote.id === '16f981c4-e745-11ee-8b14-0242c0c35c15') {
    const options = localQuote.lvc_pro_contract_options.filter(
      (option) => option.subscription_term.label !== '5 Years'
    );
    selected.value = options.length - 1;
    return options;
  }

  return localQuote.lvc_pro_contract_options;
});

const weeklyCost = (option) => {
  let number =
    option.weekly_cost +
    (whsActive.value ? option.whs_weekly_cost : 0) +
    (rmActive.value ? option.rm_weekly_cost : 0) +
    (pcsActive.value ? option.pcs_weekly_cost : 0);

  if (localQuote.is_no_charge_sub) {
    number = 0;
  }

  return mainStore.numberToCurrency(number);
};

const monthlyCost = (option, isGST = true) => {
  if (localQuote.is_no_charge_sub) return 0;

  const subCost = isGST ? option.monthly_fee?.total_inc_gst : option.monthly_fee?.total_excl_gst;

  const whsCost = isGST ? option.whs_monthly_fee?.whs_total_inc_gst : option.whs_monthly_fee?.whs_total_excl_gst;

  const rmCost = isGST ? option.rm_monthly_fee?.rm_total_inc_gst : option.rm_monthly_fee?.rm_total_excl_gst;

  const pcsCost = isGST ? option.pcs_monthly_fee?.pcs_total_inc_gst : option.pcs_monthly_fee?.pcs_total_excl_gst;

  const whsAmount = whsActive.value ? whsCost : 0;

  const rmAmount = rmActive.value ? rmCost : 0;

  const pcsAmount = pcsActive.value ? pcsCost : 0;

  return subCost + whsAmount + rmAmount + pcsAmount;
};

const yearlySaving = (option, index) => {
  if (localQuote.is_no_charge_sub) return 0;

  const currentOptionSaving = option.yearly_saving;
  const firstOptionSaving = index > 0 ? contractOptions.value[0].yearly_saving : currentOptionSaving;
  const whsOptionSaving = whsActive.value ? option.whs_yearly_saving : 0;
  const firstWhsOptionSaving =
    index > 0 ? (whsActive.value ? contractOptions.value[0].whs_yearly_saving : 0) : whsOptionSaving;
  const rmOptionSaving = rmActive.value ? option.rm_yearly_saving : 0;
  const firstRMOptionSaving =
    index > 0 ? (rmActive.value ? contractOptions.value[0].rm_yearly_saving : 0) : rmOptionSaving;

  const pcsOptionSaving = pcsActive.value ? option.pcs_yearly_saving : 0;
  const firstPCSOptionSaving =
    index > 0 ? (pcsActive.value ? contractOptions.value[0].pcs_yearly_saving : 0) : pcsOptionSaving;

  return (
    currentOptionSaving -
    firstOptionSaving +
    (whsOptionSaving - firstWhsOptionSaving) +
    (rmOptionSaving - firstRMOptionSaving) +
    (pcsOptionSaving - firstPCSOptionSaving)
  ).toFixed(2);
};

const monthlySaving = (option, index) => {
  if (localQuote.is_no_charge_sub) return 0;

  const optionCost = option.monthly_fee.total_excl_gst;
  const previousOptionCost = index > 0 ? contractOptions.value[0].monthly_fee.total_excl_gst : optionCost;
  const whsOptionCost = whsActive.value ? option.whs_monthly_fee.whs_total_excl_gst : 0;
  const previousWhsOptionCost =
    index > 0 ? (whsActive.value ? contractOptions.value[0].whs_monthly_fee.whs_total_excl_gst : 0) : whsOptionCost;
  const rmOptionCost = rmActive.value ? option.rm_monthly_fee.rm_total_excl_gst : 0;
  const previousRMOptionCost =
    index > 0 ? (rmActive.value ? contractOptions.value[0].rm_monthly_fee.rm_total_excl_gst : 0) : rmOptionCost;
  const pcsOptionCost = pcsActive.value ? option.pcs_monthly_fee.pcs_total_excl_gst : 0;
  const previousPCSOptionCost =
    index > 0 ? (pcsActive.value ? contractOptions.value[0].pcs_monthly_fee.pcs_total_excl_gst : 0) : pcsOptionCost;

  return (
    previousOptionCost -
    optionCost +
    (previousWhsOptionCost - whsOptionCost) +
    (previousRMOptionCost - rmOptionCost) +
    (previousPCSOptionCost - pcsOptionCost)
  ).toFixed(2);
};

const contractEndDateString = computed(() => {
  return mainStore.convertDateToString(contractStore.contractEndDate);
});

const jurisdiction = computed(() => mainStore.jurisdiction);

function tierInclusions(option, index) {
  const ecb = mainStore.numberToCurrency(option.ecb_amount) + ' Extras Fund';
  const saving = monthlySaving(option, index);

  const inclusions = [];
  if (saving > 0) {
    inclusions.unshift('Save ' + mainStore.numberToCurrency(saving) + ' per month');
  }

  if (option.ecb_amount > 0 && !localQuote.is_employment_hr_quote) {
    inclusions.unshift(ecb);
  }

  return inclusions;
}

function handleTierSelect(index) {
  selected.value = index;
  tierCarousel.value.slideTo(index);
}

const membershipFeatures = computed(() => {
  if (localQuote.is_employment_hr_quote) {
    return [
      'Support with performance management and disciplinary matters',
      'Assistance with employee disputes',
      'Employment Documents',
      'Assistance with individual negotiation of employment contract',
      'Employment Law Advice',
      'Workplace Training',
      'Worker Correspondence',
    ];
  }
  return [
    'Kick off legal planning session',
    'Unlimited document drafting, reviews and amendments',
    'Unlimited legal advice consultations',
    `Unlimited ${jurisdiction.value === 'uk' ? 'UK' : 'ANZ'} trade mark applications`,
    'Ongoing Employment and HR support',
    'Business structuring, incorporation and governance support',
    'Members-only business templates and tools',
    'Routine legal health checks',
  ];
});

const whsInclusions = computed(() => {
  return [
    jurisdiction.value === 'au'
      ? 'WHS audit and framework designed'
      : 'Health and Safety audit and HSW framework designed',
    'Ongoing ' + mainStore.whsShort + ' training and unlimited incident reporting',
  ];
});

const breakpoints = {
  540: {
    itemsToShow: contractOptions.value.length < 3 ? (contractOptions.value.length == 2 ? 1.4 : 1) : 1.6,
    snapAlign: contractOptions.value.length < 3 ? 'center' : 'center',
  },
  768: {
    itemsToShow: contractOptions.value.length < 3 ? contractOptions.value.length : 2.1,
    snapAlign: contractOptions.value.length < 3 ? 'center' : 'center',
  },
  1024: {
    itemsToShow: contractOptions.value.length,
    snapAlign: contractOptions.value.length < 3 ? 'center' : 'center',
  },
};

const settings = {
  itemsToShow: contractOptions.value.length == 1 ? 1 : 1.1,
  snapAlign: 'center',
};

watch(
  [selected, whsActive, pcsActive],
  ([selectedIndex, whsState, pcsState]) => {
    contractStore.contractData = {
      discount_percent: contractOptions.value[selectedIndex]?.discount_percent,
      subscription_term: contractOptions.value[selectedIndex]?.subscription_term,
      whs_addon_enabled: whsState,
      whs_monthly_fee: contractOptions.value[selectedIndex]?.whs_monthly_fee,
      lv_fees: localQuote.is_no_charge_sub ? 0 : contractOptions.value[selectedIndex]?.monthly_fee?.lv_fees,
      cpi_amount: localQuote.is_no_charge_sub ? 0 : contractOptions.value[selectedIndex]?.monthly_fee?.cpi_amount,
      monthly_cost: monthlyCost(contractOptions.value[selectedIndex], true),
      total_excl_gst: monthlyCost(contractOptions.value[selectedIndex], false),
      rm_addon_enabled: rmActive.value,
      rm_monthly_fee: contractOptions.value[selectedIndex]?.rm_monthly_fee,
      pcs_addon_enabled: pcsState,
      pcs_monthly_fee: contractOptions.value[selectedIndex]?.pcs_monthly_fee,
    };
    axios
      .post(`/api/quote/log/term-select/${mainStore.quote.id}`, {
        contractData: contractStore.contractData,
      })
      .catch(() => {});
    if (localQuote.is_employment_hr_quote) {
      localQuote.payment_amount =
        monthlyCost(contractOptions.value[selectedIndex], true) *
        (contractOptions.value[selectedIndex]?.subscription_term.value.split('_')[0] * 12);
    }
  },
  { immediate: true }
);

function isInViewport(elem, offset = 0) {
  if (!elem) return false;
  const bounding = elem.getBoundingClientRect();
  return bounding.top >= 0 && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) + offset;
}

addEventListener('scroll', (event) => {
  const carousel = document.querySelector('.carousel');
  if (!carousel) return;
  if (isInViewport(carousel, -100)) {
    mainStore.stickyButton = true;
  }
});
</script>

<style scoped>
.carousel :deep(.carousel__viewport) {
  @apply pb-4 lg:py-4 lg:px-2;
}
</style>
