<template>
  <div>
    <template v-if="businessBackground?.length">
      <section-title class="mt-6 sm:mt-12">Background</section-title>
      <information-grid v-if="businessBackground?.length" :data-array="businessBackground"></information-grid>
    </template>
    <quote-line-items />
    <section-title>Quote Information</section-title>
    <div class="flex flex-col md:flex-row md:space-x-3 space-y-3 md:space-y-0 mb-6">
      <legalvision-team
        class="sm:basis-1/2"
        :full-width="!mainStore.quote.additional_assistance"
        :class="{ 'sm:basis-full': !mainStore.quote.additional_assistance }"
      ></legalvision-team>
      <div v-if="mainStore.quote.additional_assistance" class="sm:basis-1/2 bg-lv-slate-100 p-4 rounded-md">
        <span class="font-bold">Additional Assistance</span>
        <div v-html="mainStore.quote.additional_assistance.replace(/\n/g, '<br />')"></div>
      </div>
    </div>
    <footer-nav :next="isPaymentAdvice ? 'Payment' : 'Terms'"></footer-nav>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useMainStore } from '@/stores/mainStore';

import FooterNav from '@/components/FooterNav.vue';
import LegalvisionTeam from '@/components/LegalvisionTeam.vue';
import QuoteLineItems from '@/components/QuoteLineItems.vue';
import SectionTitle from '@/components/SectionTitle.vue';
import InformationGrid from '@/components/InformationGrid.vue';

const mainStore = useMainStore();

const isPaymentAdvice = mainStore.quote.type === 'Payment_Advice';

const businessBackground = ref([]);

if (mainStore.quote?.client_background) {
  businessBackground.value.push({
    label: 'About you',
    text: mainStore.quote.client_background,
    icon: 'about_your_business',
  });
}

if (mainStore.quote?.client_need) {
  businessBackground.value.push({
    label: 'Your Legal Needs',
    text: mainStore.quote.client_need,
    icon: 'immediate_needs',
  });
}
</script>
