<template>
  <div class="md:flex flex-row md:space-x-3 mb-4 md:mb-0">
    <div class="basis-1/2 mb-2 md:mb-0">
      <label class="block mb-2 font-semibold text-xs sm:text-sm">Card Number</label>
      <div id="card-number" />
      <span class="text-red-700 text-xs">{{ cardNumberError }}</span>
    </div>
    <div class="basis-1/4 mb-2 md:mb-0">
      <label class="block mb-2 font-semibold text-xs sm:text-sm">Expiry</label>
      <div id="card-expiry" />
      <span class="text-red-700 text-xs">{{ cardExpiryError }}</span>
    </div>
    <div class="basis-1/4 mb-2 md:mb-0">
      <label class="block mb-2 font-semibold text-xs sm:text-sm">CVC</label>
      <div id="card-cvc" />
      <span class="text-red-700 text-xs">{{ cardCvcError }}</span>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { useMainStore } from '@/stores/mainStore';
import { usePaymentStore } from '@/stores/paymentStore';

const paymentStore = usePaymentStore();
const mainStore = useMainStore();

const elementArray = ref([]);
const cardNumberError = ref('');
const cardExpiryError = ref('');
const cardCvcError = ref('');
const isAmex = ref(false);

const screenWidth = window.innerWidth;

const stripeHeight = computed(() => {
  if (screenWidth < 640) {
    return '2.5rem';
  }
  return '3rem';
});
const stripePadding = computed(() => {
  if (screenWidth < 640) {
    return '0.625rem';
  }
  return '1rem';
});

onMounted(() => {
  const checkElement = setInterval(async () => {
    const element = document.querySelector('#card-number');
    if (element != null) {
      handleSetup();
      clearInterval(checkElement);
    }
  }, 100);
});

const elementOptionObject = {
  style: {
    base: {
      color: '#5A6C72',
      fontFamily: 'Inter, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '14px',
      '::placeholder': {
        color: '#aab7c4',
        fontSize: '14px',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

const handleSetup = async () => {
  const elements = mainStore.stripe.elements();

  const cardNumberElement = elements.create('cardNumber', elementOptionObject);
  const cardExpiryElement = elements.create('cardExpiry', elementOptionObject);
  const cardCvcElement = elements.create('cardCvc', elementOptionObject);

  cardNumberElement.mount('#card-number');
  cardExpiryElement.mount('#card-expiry');
  cardCvcElement.mount('#card-cvc');

  elementArray.value.push(cardNumberElement);
  elementArray.value.push(cardExpiryElement);
  elementArray.value.push(cardCvcElement);

  cardNumberElement.on('change', (event) => {
    setCardType(event);
    if (event.error && event.error.message) {
      cardNumberError.value = event.error.message;
      return;
    }
    cardNumberError.value = '';
    if (event.complete) {
      cardExpiryElement.focus();
    }
  });

  cardExpiryElement.on('change', (event) => {
    if (event.error && event.error.message) {
      cardExpiryError.value = event.error.message;
      return;
    }
    cardExpiryError.value = '';
    if (event.complete) {
      cardCvcElement.focus();
    }
  });

  cardCvcElement.on('change', (event) => {
    if (event.error && event.error.message) {
      cardCvcError.value = event.error.message;
      return;
    }
    cardCvcError.value = '';
  });

  paymentStore.stripeElement = cardNumberElement;
};

const setCardType = (event) => {
  if (!event.brand) {
    return;
  }
  paymentStore.isAmex = event.brand === 'amex';
};
</script>

<style scoped>
.StripeElement {
  box-sizing: border-box;
  height: v-bind('stripeHeight');
  padding: v-bind('stripePadding');
  border: solid 1px #c6cfd2;
  background-color: #f9fafb;
  border-radius: 0.5rem;
}

.StripeElement--invalid {
  border-color: red;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.StripeElement--focus {
  color: #5a6c72;
  background-color: #f9fafb;
  border-color: #a1cbef;
  outline: 0;
}
</style>
