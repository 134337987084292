<template>
  <div />
</template>

<script setup>
import { useRoute } from 'vue-router';

const route = useRoute();

console.log('sub mounted');
console.log(route.params.id);
axios.post(`/api/subscription/force/create/${route.params.id}`).then((res) => console.log(res));
</script>
