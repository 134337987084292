<template>
  <div
    v-if="active"
    class="fixed top-0 left-0 w-full h-full bg-slate-200 z-40 bg-opacity-70 modal-overlay"
    @click.prevent="clickOutsideModal"
  >
    <div
      class="modal-class relative top-[50%] -translate-y-[50%] left-50 min-h-[440px] max-h-[90vh] max-w-[100vw] sm:max-w-[600px] mx-auto flex bg-white border-2 border-gray-300 rounded-lg shadow z-50"
    >
      <div class="flex flex-col w-full">
        <!-- modal header -->
        <div v-if="label" class="flex items-center justify-between p-4 border-b rounded-t border-gray-200">
          <img v-if="icon" :src="'/img/icons/' + icon + '.svg'" alt="" class="mr-4 sm:mr-6" />
          <h3 class="text-base sm:text-lg font-semibold">{{ label }}</h3>
          <button
            type="button"
            class="text-gray-600 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
            @click="closeModal"
          >
            <svg
              aria-hidden="true"
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <div class="sr-only">Close modal</div>
          </button>
        </div>
        <!-- modal body -->
        <div class="p-4 relative overflow-auto h-full">
          <slot>
            <div class="text-base leading-relaxed" v-html="content"></div>
          </slot>
        </div>
        <!-- modal footer -->
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script setup>
import { watch } from 'vue';

const props = defineProps({
  active: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: '',
  },
  content: {
    type: String,
    default: '',
  },
  icon: {
    type: String,
    default: '',
  },
  closeOutside: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['update:active']);

const clickOutsideModal = (event) => {
  if (event.target.classList.contains('modal-overlay') && props.closeOutside) {
    closeModal();
  }
};
const closeModal = () => {
  removeBodyClass();
  emit('update:active', false);
};

const removeBodyClass = () => {
  document.body.classList.remove('overflow-hidden');
  document.body.classList.remove('bg-slate-100');
};

const addBodyClass = () => {
  document.body.classList.add('overflow-hidden');
  document.body.classList.add('bg-slate-100');
};

watch(
  () => props.active,
  (isActive) => {
    isActive ? addBodyClass() : removeBodyClass();
  }
);
</script>

<style scoped>
.modal-class {
}

.modal-class :deep(p) {
  @apply my-2;
}

.modal-class :deep(ul) {
  @apply ml-4 list-disc;
}

.modal-class :deep(li) {
  @apply my-1;
}
</style>
