<template>
  <div class="container mx-auto justify-center flex flex-col mb-14 p-4 md:py-0">
    <template v-if="isLoaded">
      <h1 class="text-center text-2xl font-bold my-4">{{ clientName }}</h1>
      <template v-if="!mainStore.transactionComplete">
        <section-title>Current Payment Details</section-title>
        <div v-if="defaultPaymentMethod && paymentMethodInformation" class="flex flex-col sm:flex-row mb-0 sm:mb-8">
          <div
            v-for="data in paymentMethodInformation"
            :key="data.name"
            class="flex flex-col mb-6 sm:basis-1/2 sm:mb-0 pr-0 sm:pr-4 border-l-2 border-lv-slate-200 pl-3"
          >
            <div class="font-bold">{{ data.value }}</div>
            <div class="text-sm">{{ data.name }}</div>
          </div>
        </div>
        <Form @submit="onSubmit" :validation-schema="schema">
          <section-title>Update Payment Details</section-title>
          <stripe-payment-element :client-secret="clientSecret"></stripe-payment-element>
          <payment-error-message />
          <div class="flex mb-4 justify-center">
            <p-input v-model="acceptance" type="checkbox" name="acceptance"></p-input>
          </div>
          <footer-nav submit is-last-step next="Update" :loading="paymentStore.checkingPayment"></footer-nav>
        </Form>
      </template>
      <transaction-success v-else>Payment method updated.</transaction-success>
    </template>
    <loading-spinner v-else></loading-spinner>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useMainStore } from '@/stores/mainStore';
import { usePaymentStore } from '@/stores/paymentStore';
import { useRoute, useRouter } from 'vue-router';
import { Form, useForm } from 'vee-validate';
import { boolean, object, string } from 'yup';

import FooterNav from '@/components/FooterNav.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import PInput from '@/components/PInput.vue';
import SectionTitle from '@/components/SectionTitle.vue';
import StripePaymentElement from '@/components/stripe/StripePaymentElement.vue';
import TransactionSuccess from '@/components/TransactionSuccess.vue';
import PaymentErrorMessage from '@/components/PaymentErrorMessage.vue';

const mainStore = useMainStore();
const paymentStore = usePaymentStore();
const route = useRoute();
const router = useRouter();
const { handleSubmit } = useForm();

const defaultPaymentMethod = ref({});
const clientSecret = ref(null);
const clientName = ref('');
const isLoaded = ref(false);
const acceptance = ref(false);

axios
  .get(`/api/customers/${route.params.id}/paymentMethod/supportingData`)
  .then((response) => {
    mainStore.title = 'Update Payment Method';
    clientSecret.value = response.client_secret;
    defaultPaymentMethod.value = response.default_payment_method;
    clientName.value = response.client_name;
    isLoaded.value = true;
  })
  .catch(() => {
    mainStore.errorMessage = 'Sorry we could not find your account.';
    router.push({ name: 'NotFound' });
  });

const schema = object({
  acceptance: boolean().oneOf([true], 'Updating your payment method requires acceptance of terms'),
});

const onSubmit = handleSubmit(() => {
  paymentStore.updatePaymentMethod();
});

const paymentMethodInformation = computed(() => {
  if (defaultPaymentMethod.value?.type === 'card') {
    return [
      {
        name: 'Payment Method',
        value: `Credit Card - ${defaultPaymentMethod.value.card.brand.toUpperCase()}`,
      },
      {
        name: 'Card Number',
        value: `•••• ${defaultPaymentMethod.value.card.last4}`,
      },
      {
        name: 'Expiry',
        value: `${defaultPaymentMethod.value.card.exp_month}/${defaultPaymentMethod.value.card.exp_year}`,
      },
      {
        name: 'Date added',
        value: `${new Date(defaultPaymentMethod.value.created * 1000).toLocaleDateString('en-AU')}`,
      },
    ];
  }

  if (defaultPaymentMethod.value?.type === 'au_becs_debit') {
    return [
      {
        name: 'Payment Method',
        value: 'BECS Direct Debit',
      },
      {
        name: 'BSB',
        value: defaultPaymentMethod.value.au_becs_debit.bsb_number,
      },
      {
        name: 'Account Number',
        value: `•••• ${defaultPaymentMethod.value.au_becs_debit.last4}`,
      },
      {
        name: 'Date added',
        value: `${new Date(defaultPaymentMethod.value.created * 1000).toLocaleDateString('en-AU')}`,
      },
    ];
  }

  if (defaultPaymentMethod.value?.type === 'bacs_debit') {
    return [
      {
        name: 'Payment Method',
        value: 'Bacs Direct Debit',
      },
      {
        name: 'Sort Code',
        value: defaultPaymentMethod.value.bacs_debit.sort_code,
      },
      {
        name: 'Account Number',
        value: `•••• ${defaultPaymentMethod.value.bacs_debit.last4}`,
      },
      {
        name: 'Date added',
        value: `${new Date(defaultPaymentMethod.value.created * 1000).toLocaleDateString('en-AU')}`,
      },
    ];
  }

  return null;
});
</script>
