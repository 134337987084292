<script setup>
import { computed } from 'vue';
import { useMainStore } from '@/stores/mainStore.js';
const mainStore = useMainStore();
const exclusions = computed(() => mainStore.costEstimate?.exclusions ?? '');
</script>

<template>
  <div v-if="exclusions" class="bg-white border border-lv-slate-200 rounded-md -mx-5 sm:mx-0">
    <div class="border-b border-lv-slate-200 p-4 sm:p-5">
      <span class="font-semibold">Exclusions</span>
    </div>
    <div class="p-4 sm:p-5">
      <div v-html="exclusions.replaceAll('\n', '<br />')" />
    </div>
  </div>
</template>

<style scoped>

</style>
