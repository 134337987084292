<template>
  <div class="container mx-auto justify-center my-14">
    <h1 class="text-center text-2xl font-bold my-4">Oops! Something went wrong</h1>
    <div class="bg-lv-slate-100 p-4 rounded-lg flex flex-col">
      <h2 class="text-xl font-bold mb-3 md:mb-4 text-center">{{ mainStore.errorMessage }}</h2>
      <div class="flex justify-center text-center">
        <p>
          If the problem persists please contact us at
          <a :href="'mailto:' + clientSuccessEmail" class="underline">{{ clientSuccessEmail }}</a> or call us on
          <a :href="'tel:' + mainStore.phoneNumber.split(' ').join('')" class="underline">{{ mainStore.phoneNumber }}</a
          >.
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useMainStore } from '@/stores/mainStore';
import { useRoute } from 'vue-router';

import SectionTitle from '@/components/SectionTitle.vue';

const mainStore = useMainStore();
const route = useRoute();

const clientSuccessEmail = computed(() => {
  const EMAIL_MAP = {
    au: 'clientsuccess@legalvision.com.au',
    nz: 'clientsuccess@legalvision.co.nz',
    uk: 'clientsuccess@legalvision.co.uk',
  };

  return EMAIL_MAP[mainStore.jurisdiction];
});
</script>
