<template>
  <form-section :show-label="false">
    <div class="max-full stripe-element-container">
      <div class="stripe-element-wrapper">
        <div id="stripe-element" />
      </div>
    </div>
    <!-- <payment-error-message /> -->
  </form-section>
</template>

<script setup>
import { computed, onMounted } from 'vue';
import { useMainStore } from '@/stores/mainStore';
import { usePaymentStore } from '@/stores/paymentStore';

import PaymentErrorMessage from '@/components/PaymentErrorMessage.vue';
import FormSection from '@/components/FormSection.vue';

const paymentStore = usePaymentStore();
const mainStore = useMainStore();

const { clientSecret, hasBillingDetails } = defineProps({
  hasBillingDetails: {
    type: Boolean,
    default: false,
  },
  clientSecret: {
    type: String,
    default: '',
  },
});

const businessName = computed(() => {
  const BUSINESS_NAME_MAP = {
    au: 'LegalVision ILP Pty Ltd',
    nz: 'LegalVision NZ Limited',
    uk: 'LegalVision Law UK Ltd',
  };

  return BUSINESS_NAME_MAP[mainStore.jurisdiction];
});

onMounted(() => {
  const checkElement = setInterval(async () => {
    const element = document.querySelector('#stripe-element');
    if (element != null) {
      handleSetup();
      clearInterval(checkElement);
    }
  }, 100);
});

const handleSetup = async () => {
  const elementGroup = mainStore.stripe.elements({
    // mode: 'setup',
    // currency: mainStore.currencyString,
    clientSecret: clientSecret,
    appearance: {
      theme: 'default',
      variables: {
        colorBackground: '#ffffff',
        fontSizeBase: '16px',
        spacingTab: '10px',
        spacingGridRow: '15px',
        spacingGridColumn: '20px',
      },
      rules: {
        '.Input': {
          border: 'solid 1px #9e9e9e',
          padding: 'var(--p-spacing3)',
        },
        '.Tab': {
          border: 'solid 1px #9e9e9e',
        },
        '.TabLabel': {
          fontSize: '15px',
        },
        '.Tab--selected': {
          borderColor: '#E0E6EB',
          boxShadow:
            '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px var(--colorPrimary)',
        },
      },
    },
  });

  const element = elementGroup.create('payment', {
    layout: {
      type: 'accordion',
      defaultCollapsed: false,
      radios: true,
      spacedAccordionItems: false,
    },
    fields: {
      billingDetails: {
        name: 'auto',
        email: 'auto',
        phone: 'auto',
        address: {
          line1: hasBillingDetails ? 'never' : 'auto',
          line2: hasBillingDetails ? 'never' : 'auto',
          city: hasBillingDetails ? 'never' : 'auto',
          state: hasBillingDetails ? 'never' : 'auto',
          country: 'auto',
          postalCode: hasBillingDetails ? 'never' : 'auto',
        },
      },
    },
    business: {
      name: businessName.value,
    },
  });

  element.mount('#stripe-element');

  paymentStore.stripeElement = elementGroup;
};
</script>

<style scoped></style>
