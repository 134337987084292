<template>
  <div class="flex flex-col gap-6">
    <template v-if="application">
      <section-title class="mt-6 sm:mt-12">Proposed trade mark filing particulars</section-title>
      <div class="bg-white rounded-md p-4">
        <div class="prose max-w-full prose-img:inline prose-img:align-top prose-img:m-0" v-html="application.summary"></div>
      </div>
      <div class="grid gap-[2px] grid-cols-[200px_1fr] [&>*]:p-4 [&>*]:bg-white rounded-md">
        <div class="font-bold rounded-tl-md">
          Trade marks
        </div>
        <div v-html="tradeMarks" class="rounded-tr-md" />
        <div class="font-bold">Country</div>
        <div>{{ application.country }}</div>
        <div class="font-bold">Owner</div>
        <div v-html="computedOwner" />
        <div class="font-bold">Address<br/><span class="font-normal italic">(Note this will be publicly available)</span></div>
        <div>{{ address }}</div>
        <div class="font-bold rounded-bl-md">Classes and goods/services</div>
        <div class="rounded-br-md" v-html="goodsAndServices" />
      </div>
      <div v-for="overrideMark in overrideGoodsAndServices" :key="overrideMark.id">
        <div class="grid gap-[2px] grid-cols-[200px_1fr] [&>*]:p-4 [&>*]:bg-white rounded-md">
          <div class="font-bold rounded-bl-md">Classes and goods/services for
          <span v-if="overrideMark.provisional_image_url">
            <img :src="overrideMark.provisional_image_url" :alt="overrideMark.name" width="128px">
          </span><span v-else>{{ overrideMark.word }}</span>

          </div>
          <div class="rounded-br-md" v-html="overrideMark.picklist" />
        </div>
      </div>
      <section-title>Preliminary risk assessment</section-title>
      <div v-if="application.prior_similar_trade_marks" class="bg-white rounded-md p-4">
        <h2 class="font-bold">Prior similar trade marks</h2>
        <div class="prose max-w-full prose-img:inline prose-img:align-top prose-img:m-0" v-html="application.prior_similar_trade_marks"></div>
      </div>
      <div v-if="application.distinctiveness" class="bg-white rounded-md p-4">
        <h2 class="font-bold">Distinctiveness</h2>
        <div class="prose max-w-full prose-img:inline prose-img:align-top prose-img:m-0" v-html="application.distinctiveness"></div>
      </div>
    </template>
    <footer-nav
      :next="nextText"
    ></footer-nav>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useMainStore } from '@/stores/mainStore.js';
import InformationGrid from '@/components/InformationGrid.vue';
import SectionTitle from '@/components/SectionTitle.vue';
import FooterNav from '@/components/FooterNav.vue';

const mainStore = useMainStore();
const costEstimateInformation = ref([]);

const application = computed(() => mainStore.costEstimate?.trade_mark_application ?? {});
const tradeMarks = computed(() => {
  return (application.value.trade_marks ?? []).map((mark) => {
    if (mark.provisional_image_url) {
      return `<img src="${mark.provisional_image_url}" alt="${mark.name}" width="128px">`;
    }
    return mark.word;
  }).join('<br />')
});
const summary = computed(() => {
  return [
    {
      label: 'Application Summary',
      text: application.value.summary?.replace(/\n/g, '<br>') ?? '',
    },
    {
      label: 'Trade Marks',
      text: tradeMarks.value,
    },
    {
      label: 'Preliminary Risk Assessment',
      text: application.value.preliminary_risk_assessment?.replace(/\n/g, '<br>') ?? '',
    },
    {
      label: 'Distinctiveness',
      text: application.value.distinctiveness?.replace(/\n/g, '<br>') ?? '',
    },
  ];
});
const getPicklistString = (picklist) => {
  const parsed = JSON.parse(picklist)
  return Object.entries(parsed).sort(([classA], [classB]) => parseInt(classA) - parseInt(classB))
    .map(([classNum, descriptionList]) => {
      const sortedDescriptions = descriptionList.sort((a, b) => a.localeCompare(b)).join('; ');
      return `<h3 class="font-bold">Class ${classNum}</h3>
${sortedDescriptions}`
    }).join('<br /><br />')
}

const goodsAndServices = computed(() => {
  return getPicklistString(application.value.picklist ?? '{}');
});

const overrideGoodsAndServices = computed(() => {
  return (application.value?.trade_marks ?? [])
    .filter((mark) => mark.override_picklist?.length)
    .map((mark) => ({
      ...mark,
      picklist: getPicklistString(mark.override_picklist),
    }));
})

const address = computed(() => {
  return [
    application.value?.address_street,
    application.value?.address_city,
    `${application.value?.address_state ?? ''} ${application.value?.address_postcode ?? ''}`.trim(),
    application.value?.address_country,
  ].filter((v) => !!v?.trim()).join(', ');
});

const computedOwner = computed(() => {
  const isIndividual = application.value?.owner_entity_type?.value === 'Individual';
  if (isIndividual) {
    return `${application.value?.owner_title?.label ?? ''} ${application.value?.owner_first_name ?? ''} ${application.value?.owner_last_name ?? ''}`.trim();
  }
  let acnOrAbn = null;
  const abn = application.value?.owner_abn;
  const acn = application.value?.owner_acn;
  if (acn) {
    acnOrAbn = `(ACN: ${acn})`;
  } else if (abn) {
    acnOrAbn = `(ABN: ${abn})`;
  }
  return [application.value?.owner_registered_name, acnOrAbn]
    .filter((v) => !!v)
    .join("<br />");
});
const nextText = 'Summary';
</script>
