import { defineStore } from 'pinia';
import { useMainStore } from './mainStore';
import axios from 'axios';

const AU_RATES = {
  amex: 0.0175,
  card: 0.01,
  bank: 0,
  international: 0.029,
};

const NZ_RATES = {
  amex: 0.029,
  card: 0.02,
  international: 0.029,
};

const calculateTotal = (rate, amount) => {
  return rate * (rate * amount + amount + 0.3) + 0.3 + amount;
};

export const usePaymentStore = defineStore('payment', {
  state: () => ({
    isCreditCard: true,
    isDirectDeposit: false,
    isBankPayment: false,
    stripeElement: null,
    isAmex: false,
    paymentError: null,
    paymentMethodOwner: {
      name: '',
      email: '',
    },
    checkingPayment: false,
    outstandingInvoices: [],
    outstandingInvoiceAmount: 0,
    multiPaymentAmount: 0,
  }),
  getters: {
    nextDebitDate: (state) => {
      const mainStore = useMainStore();
      const { quote } = mainStore;

      if (quote?.id === 'd046872a-a79e-4f22-96f4-e39cfe765c54') {
        return new Date(2024, 11, 24);
      }

      if (quote?.next_billing_date) {
        return new Date(quote.next_billing_date);
      }

      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth();
      const day = date.getDate();
      return new Date(year, month + 1, day + 1);
    },
    isMultiPayment() {
      const mainStore = useMainStore();
      const { quote } = mainStore;

      return !!quote?.is_multiple_payments;
    },
    paymentType: (state) => {
      if (state.isCreditCard) {
        return state.isAmex ? 'amex' : 'card';
      }
      return 'bank';
    },
    feeRate() {
      const mainStore = useMainStore();
      const { jurisdiction, quote } = mainStore;

      if (jurisdiction === 'uk' || quote?.is_lvc_pro_invoice) {
        return 0;
      }

      return jurisdiction === 'nz' ? NZ_RATES[this.paymentType] : AU_RATES[this.paymentType];
    },
    paymentAmount() {
      const mainStore = useMainStore();
      const { quote } = mainStore;
      const { costEstimate } = mainStore;

      if (costEstimate) {
        return costEstimate.total;
      }

      if (this.outstandingInvoices.length) {
        return this.outstandingInvoices
          .filter((invoice) => invoice.selected)
          .map((invoice) => Number(invoice.balance_due))
          .reduce((a, b) => a + b, 0);
      }

      if (this.isMultiPayment) {
        if (this.multiPaymentAmount > quote.balance) {
          this.multiPaymentAmount = quote.balance;
        }
        if (this.multiPaymentAmount < 0) {
          this.multiPaymentAmount = 0;
        }
        return Number(this.multiPaymentAmount);
      }
      return quote?.payment_amount ?? 0;
    },
    processingFee() {
      const mainStore = useMainStore();
      const { quote } = mainStore;
      const amount = this.paymentAmount;

      if (this.feeRate === 0 || amount <= 0 || quote?.is_lvc_pro_invoice) {
        return 0;
      }
      const total = calculateTotal(this.feeRate, amount);
      return (total - amount).toFixed(2);
    },
    paymentTotal() {
      const amount = this.paymentAmount;
      const feeRate = this.feeRate;

      if (feeRate === 0 || amount <= 0) {
        return amount.toFixed(2);
      }
      return calculateTotal(feeRate, amount).toFixed(2);
    },
    cardFeePercent: (state) => {
      const mainStore = useMainStore();
      const { quote, costEstimate } = mainStore;
      const paymentAmount = quote?.payment_amount || costEstimate?.total || 0;

      return (type) => {
        let rate = AU_RATES[type];
        if (state.jurisdiction === 'nz') {
          rate = NZ_RATES[type];
        }
        if (state.jurisdiction === 'uk') {
          rate = 0;
        }
        const total = calculateTotal(rate, paymentAmount);
        const fee = total - paymentAmount;
        return ((fee / total) * 100).toFixed(2);
      };
    },
    amexFeePercent() {
      const amount = this.paymentAmount;
      const mainStore = useMainStore();
      const { jurisdiction } = mainStore;
      let rate = AU_RATES['amex'];
      if (jurisdiction === 'nz') {
        rate = NZ_RATES['amex'];
      }

      if (rate === 0 || amount <= 0) {
        return (rate * 100).toFixed(2);
      }
      const total = calculateTotal(rate, amount).toFixed(2);
      const fee = total - amount;
      return ((fee / total) * 100).toFixed(2);
    },
    visaFeePercent() {
      const amount = this.paymentAmount;
      const mainStore = useMainStore();
      const { jurisdiction } = mainStore;
      let rate = AU_RATES['card'];
      if (jurisdiction === 'nz') {
        rate = NZ_RATES['card'];
      }
      if (rate === 0 || amount <= 0) {
        return (rate * 100).toFixed(2);
      }
      const total = calculateTotal(rate, amount).toFixed(2);
      const fee = total - amount;
      return ((fee / total) * 100).toFixed(2);
    },
  },
  actions: {
    async createPaymentMethod() {
      const mainStore = useMainStore();
      this.checkingPayment = true;
      this.verifyPaymentMethod()
        .then((isVerified) => {
          if (isVerified) {
            mainStore.$patch({
              currentStep: mainStore.currentStep + 1,
            });
          }
        })
        .catch(() => {
          this.paymentError = 'Something went wrong, please try again.';
        })
        .finally(() => {
          this.checkingPayment = false;
        });
    },
    async verifyPaymentMethod() {
      const mainStore = useMainStore();
      let isPaymentMethodVerified = false;

      try {
        this.paymentError = null;

        const payload = {
          payment_method: {
            billing_details: {
              name: this.paymentMethodOwner.name,
              email: this.paymentMethodOwner.email,
            },
          },
        };

        if (this.isCreditCard) {
          payload.payment_method.card = this.stripeElement;
        } else {
          payload.payment_method.au_becs_debit = this.stripeElement;
        }

        const { setupIntent, error } = this.isCreditCard
          ? await mainStore.stripe.confirmCardSetup(mainStore.quote.setup_intent_id, payload)
          : await mainStore.stripe.confirmAuBecsDebitSetup(mainStore.quote.setup_intent_id, payload);

        if (error) {
          throw { code: error.code, message: error.message };
        }

        isPaymentMethodVerified = true;

        if (this.isCreditCard) {
          const response = await this.creditCardAuthorisation(setupIntent.id);

          if (!response) {
            isPaymentMethodVerified = false;
            this.createSetupIntent();
          }
        }

        if (isPaymentMethodVerified) {
          mainStore.quote.confirmed_intent = setupIntent;
        }
      } catch (error) {
        this.paymentError = error.message;
        isPaymentMethodVerified = false;
        mainStore.logEvent({
          event: error.code || 'payment_method_verification_error',
          error: error.message,
        });
      }

      return isPaymentMethodVerified;
    },
    async creditCardAuthorisation(confirmedIntentId) {
      const mainStore = useMainStore();
      const quoteId = mainStore.quote.id;
      const response = await axios
        .post(`/api/payment-intent/${quoteId}/authorise`, {
          intent: confirmedIntentId,
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            this.paymentError = error.response.data.error;
          } else {
            this.paymentError = 'Something went wrong, please try again.';
          }
          return false;
        });

      if (!response) {
        return false;
      }

      if (response.next_action?.type === 'use_stripe_sdk') {
        const { error, paymentIntent } = await mainStore.stripe.handleNextAction({
          clientSecret: response.data.client_secret,
        });
        if (error) {
          this.paymentError = error.message;
          return false;
        }
      }

      return true;
    },
    async createSetupIntent() {
      const mainStore = useMainStore();

      const { client_secret } = await axios.post(`/api/setup-intent`, {
        quote_id: mainStore.quote.id,
      });

      mainStore.quote.setup_intent_id = client_secret;
    },
    async processCostEstimatePayment() {
      const mainStore = useMainStore();

      this.checkingPayment = true;
      try {
        this.paymentError = '';
        const client_secret = await this.createCostEstimatePaymentIntent();
        console.log(client_secret);
        const { paymentIntent, error } = await mainStore.stripe.confirmCardPayment(client_secret, {
          payment_method: {
            card: this.stripeElement,
            billing_details: {
              name: this.paymentMethodOwner.name,
              email: this.paymentMethodOwner.email,
            },
          },
        });

        if (error) {
          throw Error(error.message);
        }
        mainStore.transactionComplete = true;
      } catch (error) {
        this.paymentError = 'Error processing payment, please try again';
      } finally {
        this.checkingPayment = false;
      }
    },
    async processPaymentAdvice() {
      this.paymentError = '';
      this.checkingPayment = true;
      const mainStore = useMainStore();

      try {
        const clientSecretId = await this.createPaymentIntent();

        if (!clientSecretId) {
          throw new Error('Failed to create payment intent');
        }

        const { paymentIntent, error } = await mainStore.stripe.confirmCardPayment(clientSecretId, {
          payment_method: {
            card: this.stripeElement,
            billing_details: {
              name: this.paymentMethodOwner.name,
              email: this.paymentMethodOwner.email,
            },
          },
        });
        if (error) {
          const allowedErrors = ['card_error', 'validation_error'];

          this.paymentError = allowedErrors.includes(error.type)
            ? error.message
            : 'Payment failed. Please try again or use a different payment method.';
        } else {
          if (mainStore.quote) {
            mainStore.quote.confirmed_intent = paymentIntent;
          } else {
            mainStore.quote = {
              confirmed_intent: paymentIntent,
            };
          }
          const quoteId = mainStore.quote.id;
          await axios.post(`/api/quote/${quoteId}/accept_terms`).catch(() => {});
          mainStore.transactionComplete = true;
        }
      } catch (error) {
        console.log(error);
        this.paymentError = 'Something went wrong. Please try again later.';
      } finally {
        this.checkingPayment = false;
      }
    },
    async processCardPayment() {
      this.paymentError = '';
      this.checkingPayment = true;
      const mainStore = useMainStore();

      const paymentIntent =
        this.outstandingInvoices.length > 0
          ? await this.createOverdueInvoicePaymentIntent()
          : await this.createPaymentIntent();

      if (!paymentIntent) {
        this.checkingPayment = false;
        return;
      }

      return mainStore.stripe
        .confirmCardPayment(paymentIntent, {
          payment_method: {
            card: this.stripeElement,
            billing_details: {
              name: this.paymentMethodOwner.name,
              email: this.paymentMethodOwner.email,
            },
          },
        })
        .then((response) => {
          if (response.paymentIntent?.status === 'succeeded') {
            if (mainStore.quote) {
              mainStore.quote.confirmed_intent = response.paymentIntent;
            } else {
              mainStore.quote = {
                confirmed_intent: response.paymentIntent,
              };
            }
            mainStore.transactionComplete = true;
          } else if (response.error?.code) {
            this.paymentError = response.error.message;
          } else {
            throw Error();
          }
        })
        .catch(() => {
          this.paymentError = 'Error processing payment, please try again';
        })
        .finally(() => {
          this.checkingPayment = false;
        });
    },
    createPaymentIntent() {
      const mainStore = useMainStore();

      const payload = {
        email: this.paymentMethodOwner.email,
        paymentTotal: this.paymentTotal,
      };

      if (this.isMultiPayment) {
        payload.subtotal = this.multiPaymentAmount;
      }

      if (mainStore.jurisdiction === 'uk') {
        payload.subtotal = this.paymentTotal;
      }

      return axios.post(`/api/payment-intent/${mainStore.quote.id}`, payload).catch((error) => {
        this.paymentError =
          error.response.data.error || error.response.data.paymentTotal?.[0] || error.response.data.subtotal?.[0];

        return false;
      });
    },
    acceptCostEstimate() {
      const mainStore = useMainStore();
      const costEstimateId = mainStore.costEstimate.id;
      this.checkingPayment = true;
      return axios
        .post(`/api/cost-estimate/${costEstimateId}/accept`, {
          stageIds: mainStore.unacceptedCostStages.map((stage) => stage.id),
          paymentAdviceIds: mainStore.unacceptedCostStagesWithPaymentAdvice.map(
            (stage) => stage.latestPaymentAdvice?.id
          ),
        })
        .then(() => {
          mainStore.transactionComplete = true;
        })
        .finally(() => {
          this.checkingPayment = false;
        });
    },
    acceptTerms() {
      const mainStore = useMainStore();
      const quoteId = mainStore.quote.id;
      this.checkingPayment = true;
      return axios
        .post(`/api/quote/${quoteId}/accept_terms`)
        .then(() => {
          mainStore.transactionComplete = true;
        })
        .finally(() => {
          this.checkingPayment = false;
        });
    },
    createCostEstimatePaymentIntent() {
      this.checkingPayment = true;
      const mainStore = useMainStore();

      const data = {
        email: this.paymentMethodOwner.email,
        paymentTotal: this.paymentTotal,
        paymentAdviceIds: mainStore.unacceptedCostStages
          .filter((stage) => !!stage.latestPaymentAdvice)
          .map((stage) => stage.latestPaymentAdvice?.id),
        stageIds: mainStore.unacceptedCostStages.map((stage) => stage.id),
        costEstimateId: mainStore.costEstimate.id,
      };

      return axios.post(`/api/payment-intent/costEstimate`, data).catch((error) => {
        if (error.response && error.response.status === 422) {
          this.paymentError =
            error.response.data.message ?? error.response.data.error ?? error.response.data.paymentTotal[0];
        } else if (error.response && error.response.status === 402) {
          this.paymentError = 'Your card was declined.';
        } else {
          this.paymentError = 'Error processing payment, please try again';
        }
        return false;
      });
    },
    createOverdueInvoicePaymentIntent() {
      this.checkingPayment = true;

      const data = {
        email: this.paymentMethodOwner.email,
        paymentTotal: this.paymentTotal,
        invoiceIds: this.outstandingInvoices.filter((invoice) => !!invoice.selected).map((invoice) => invoice.id),
      };

      return axios.post(`/api/overdue/payment-intent`, data).catch((error) => {
        if (error.response && error.response.status === 422) {
          this.paymentError =
            error.response.data.message ?? error.response.data.error ?? error.response.data.paymentTotal[0];
        } else if (error.response && error.response.status === 402) {
          this.paymentError = 'Your card was declined.';
        } else {
          this.paymentError = 'Error processing payment, please try again';
        }
        return false;
      });
    },
    async submitPaymentElement() {
      const mainStore = useMainStore();
      try {
        this.checkingPayment = true;

        const { setupIntent, error } = await mainStore.stripe.confirmSetup({
          elements: this.stripeElement,
          confirmParams: {
            return_url: `${window.location.origin}/payment/${mainStore.quote.id}`,
            payment_method_data: {
              billing_details: {
                address: {
                  line1: mainStore.businessInformation.updated.street,
                  line2: '',
                  city: mainStore.businessInformation.updated.city,
                  state: mainStore.businessInformation.updated.state,
                  postal_code: mainStore.businessInformation.updated.postcode,
                },
              },
            },
          },
          redirect: 'if_required',
        });

        if (error) {
          this.paymentError = error.message;
        } else {
          mainStore.quote.confirmed_intent = setupIntent;
          mainStore.$patch({
            currentStep: mainStore.currentStep + 1,
          });
        }
      } catch (error) {
        console.log(error);
        this.paymentError = 'Something went wrong, please try again or contact us.';
      } finally {
        this.checkingPayment = false;
      }
    },
    async updatePaymentMethod() {
      try {
        const mainStore = useMainStore();
        this.checkingPayment = true;
        this.paymentError = '';

        const { setupIntent, error } = await mainStore.stripe
          .confirmSetup({
            elements: this.stripeElement,
            confirmParams: {
              return_url: `${window.origin}/update/success`,
            },
            redirect: 'if_required',
          })
          .catch(() => {
            throw Error('Error updating payment method');
          });

        if (error) {
          throw Error(error.message);
        }

        axios.post(`/api/payment-method/${setupIntent.payment_method}`).catch(() => {});
        mainStore.transactionComplete = true;
      } catch (error) {
        this.paymentError = error.message;
      } finally {
        this.checkingPayment = false;
      }
    },
  },
});
