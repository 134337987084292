<template>
  <div class="mb-2">
    <slot name="label">
      <label
        v-if="type !== 'toggle' && type !== 'checkbox'"
        :for="label"
        class="block mb-2 font-semibold text-xs sm:text-sm"
        >{{ label }}</label
      >
    </slot>
    <template v-if="type === 'toggle'">
      <label class="relative inline-flex items-center cursor-pointer">
        <input
          type="checkbox"
          :checked="value"
          :name="name"
          :disabled="disabled"
          class="sr-only peer"
          @input.stop="$emit('update:modelValue', $event.target.checked)"
        />
        <div
          class="w-9 h-5 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-ming-600"
        ></div>
        <span class="ml-3 text-sm font-semibold">{{ label }}</span>
      </label>
    </template>
    <template v-else-if="type === 'checkbox'">
      <div
        class="flex items-center p-2"
        :class="{
          'border border-validation-red rounded-md': !!errorMessage && !meta.valid,
          'border-none': !errorMessage && meta.valid,
        }"
      >
        <input
          id="accept-checkbox"
          type="checkbox"
          :checked="value"
          :name="name"
          class="w-5 h-5 border border-lv-slate-200 rounded-md checked:accent-ming-600 hover:accent-ming-500"
          @input.stop="$emit('update:modelValue', $event.target.checked)"
        />
        <label for="accept-checkbox" class="ml-3 cursor-pointer text-sm font-normal"
          >I agree to LegalVision's
          <a :href="mainStore.costAgreementUrl" target="_blank" class="text-ming-600 hover:underline font-semibold"
            >Terms of Engagement
          </a>
        </label>
      </div>
    </template>
    <template v-else-if="type === 'select'">
      <select
        :id="label"
        :value="value"
        :name="name"
        class="h-10 sm:h-12 bg-lv-slate-20 border border-lv-slate-200 rounded-lg placeholder:text-lv-slate-600 block w-full p-2.5 focus:outline-none"
        required
        :disabled="disabled"
        @input="$emit('update:modelValue', $event.target.value)"
      >
        <option value="" disabled selected>Select an item</option>
        <option v-for="option in options" :key="option" :value="option">{{ option }}</option>
      </select>
    </template>
    <template v-else>
      <input
        :id="label"
        :type="type"
        :value="value"
        :name="name"
        :placeholder="placeholder"
        :disabled="disabled"
        :readonly="readonly"
        :autocomplete="autocomplete"
        class="h-10 sm:h-12 bg-lv-slate-20 border border-lv-slate-200 rounded-lg placeholder:text-lv-slate-600 placeholder:text-sm text-sm sm:text-base block w-full p-2.5 focus:outline-none"
        :class="{
          'border-validation-red': meta.touched && !meta.valid,
          '!border-green-400': meta.valid && required,
          '!border-lv-slate-100': disabled,
        }"
        @input="$emit('update:modelValue', $event.target.value)"
      />
    </template>
    <ErrorMessage v-slot="{ message }" :name="name">
      <span class="inline-flex text-sm text-red-700">{{ message }}</span>
    </ErrorMessage>
  </div>
</template>

<script setup>
import { toRef } from 'vue';
import { useField, ErrorMessage } from 'vee-validate';

const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  name: {
    type: String,
  },
  autocomplete: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: 'text',
  },
  required: {
    type: Boolean,
    default: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  modelValue: {},
  options: {
    type: Array,
    default: () => [],
  },
});

const name = toRef(props, 'name');
const valueRef = toRef(props, 'modelValue');

const {
  value: inputValue,
  errorMessage,
  value,
  handleBlur,
  handleChange,
  meta,
} = useField(name, undefined, {
  initialValue: props.modelValue,
});

defineEmits(['update:modelValue']);
</script>
