<template>
  <div class="container mx-auto justify-center flex flex-col mb-14 p-4 md:py-0">
    <template v-if="isLoaded">
      <h1 class="text-center text-2xl font-bold my-4">{{ mainStore.quote.client_name }}</h1>
      <template v-if="!mainStore.transactionComplete">
        <section-title>{{ addonData.label }}</section-title>
        <information-grid :data-array="promoPartOne" :start-open="isRelationshipManager"></information-grid>
        <information-grid :data-array="promoPartTwo"></information-grid>
        <section-title>Membership Terms</section-title>
        <div class="flex flex-col sm:flex-row mb-0 sm:mb-6">
          <div class="flex flex-col mb-6 sm:basis-1/2 sm:mb-0 pr-0 sm:pr-4 border-l-2 border-lv-slate-200 pl-3">
            <div class="font-bold">{{ mainStore.quote.client_name }}</div>
            <div class="text-sm mt-1">Client Name</div>
          </div>
          <div
            v-if="mainStore.businessInformation?.abn"
            class="flex flex-col mb-6 sm:basis-1/2 sm:mb-0 sm:pl-4 border-l-2 border-lv-slate-200 pl-3"
          >
            <div class="font-bold">
              {{ mainStore.businessInformation.abn }}
            </div>
            <div class="text-sm mt-1">{{ mainStore.companyNumber }}</div>
          </div>
        </div>
        <div class="flex flex-col sm:flex-row mb-0 sm:mb-8">
          <div class="flex flex-col mb-6 sm:basis-1/2 sm:mb-0 pr-0 sm:pr-4 border-l-2 border-lv-slate-200 pl-3">
            <div class="font-bold">{{ addonData.subscription_term }}</div>
            <div class="text-sm mt-1">Membership Term</div>
          </div>
          <div class="flex flex-col mb-6 sm:basis-1/2 sm:mb-0 sm:pl-4 border-l-2 border-lv-slate-200 pl-3">
            <div class="font-bold">
              {{ mainStore.numberToCurrency(addonData.weekly_cost) }} + {{ mainStore.taxType }}
            </div>
            <div class="text-sm mt-1">Weekly Cost</div>
          </div>
          <div class="flex flex-col mb-6 sm:basis-1/2 sm:mb-0 sm:pl-4 border-l-2 border-lv-slate-200 pl-3">
            <div class="font-bold">
              {{ mainStore.numberToCurrency(addonData.monthly_total_inc_gst) }} (inc. {{ mainStore.taxType }})
            </div>
            <div class="text-sm mt-1">Total Monthly Fee (incl. membership)</div>
          </div>
          <div class="flex flex-col mb-6 sm:basis-1/2 sm:mb-0 sm:pl-4 border-l-2 border-lv-slate-200 pl-3">
            <div class="font-bold">{{ contractEndDateString }}</div>
            <div class="text-sm mt-1">Membership Term End</div>
          </div>
        </div>
        <section-title>Acceptance of Updated Terms</section-title>
        <Form @submit="onSubmit" :validation-schema="schema">
          <form-section label="Information">
            <p-input
              v-model="recipient.name"
              label="Authorised by"
              name="name"
              required
              :disabled="contractStore.contractSigned"
              placeholder="Enter authorised contact..."
            />
            <div class="columns-1 md:columns-2 mb-0 md:mb-2">
              <p-input
                v-model="recipient.email"
                label="Email"
                type="email"
                name="email"
                required
                :disabled="contractStore.contractSigned"
                placeholder="Enter email..."
              />
              <p-input
                v-model="recipient.position"
                :options="contractStore.contractPositionOptions"
                label="Position"
                type="select"
                name="position"
                placeholder="Enter position..."
                required
                :disabled="contractStore.contractSigned"
              />
            </div>
            <template v-if="recipient.position === 'Other'">
              <p-input
                v-model="recipient.other"
                label="Enter Position"
                name="other"
                placeholder="Enter business name..."
              />
            </template>
            <template v-if="!isEnvelopeSignable || !contractStore.signUrl">
              <button
                type="submit"
                :disabled="contractStore.isLoadingContract"
                class="mx-auto bg-ming-600 text-white px-3 py-2 rounded-md flex items-center text-sm justify-center"
              >
                {{ contractStore.envelope ? 'Update contract' : 'Create contract' }}
              </button>
            </template>
            <template v-if="!contractStore.contractSigned && contractStore.signUrl && isEnvelopeSignable">
              <div class="flex justify-center items-center">
                <iframe
                  :src="contractStore.signUrl"
                  style="width: 100%; border-radius: 5px; border: none; overflow: hidden"
                  :style="contractHeight"
                />
              </div>
            </template>
          </form-section>
        </Form>
      </template>
      <template v-else>
        <transaction-success>
          <div class="flex-col justify-center">
            <template v-if="isWHS">
              <h1 class="text-center text-xl font-bold my-4">
                Thanks for adding the {{ mainStore.whsShort }} Advisory Service to your membership.
              </h1>
              <p class="my-4">
                You’re on your way to a safer workplace environment for your staff, customers and business.
              </p>
              <p class="my-4">
                You can now access the employment hotline, as well as exclusive WHS resources in your Prism account. You
                will shortly receive an email with further information. If you need any assistance in the meantime,
                please call
                <a class="text-decoration-none" :href="`tel:${mainStore.phoneNumber.split(' ').join('')}`">{{
                  mainStore.phoneNumber
                }}</a>
                or contact your client success manager.
              </p>
              <div class="text-center my-5 text-sm">
                A copy of your contract will be emailed to you or you can download a copy of your contract by clicking
                below.
              </div>
            </template>
            <template v-else-if="isRM">
              <h1 class="text-center text-xl font-bold my-4">
                Thanks for adding the Relationship Manager service to your membership.
              </h1>
              <p class="my-4">Your Relationship Manager will be in touch shortly to discuss next steps.</p>
              <p class="my-4">
                You can continue to access and book new requests for legal assistance via Prism. If you need any further
                information, please call
                <a class="text-decoration-none" :href="`tel:${mainStore.phoneNumber.split(' ').join('')}`">{{
                  mainStore.phoneNumber
                }}</a>
                or contact your client success manager.
              </p>
              <div class="text-center my-5 text-sm">
                A copy of your contract will be emailed to you or you can download a copy of your contract by clicking
                below.
              </div>
            </template>
            <template v-else-if="isPCS">
              <h1 class="text-center text-xl font-bold my-4">
                Thanks for adding the Privacy Compliance Service to your membership.
              </h1>
              <p class="my-4">We're looking forward to helping you with your privacy compliance.</p>
              <p class="my-4">
                You can now access the privacy hotline, and book in your privacy audit. You will shortly receive an
                email with further information. If you need any further information, please call
                <a class="text-decoration-none" :href="`tel:${mainStore.phoneNumber.split(' ').join('')}`">{{
                  mainStore.phoneNumber
                }}</a>
                or contact your client success manager.
              </p>
              <div class="text-center my-5 text-sm">
                A copy of your contract will be emailed to you or you can download a copy of your contract by clicking
                below.
              </div>
            </template>
            <div v-if="contractStore.envelope?.master" class="flex mb-6">
              <a
                class="mx-auto bg-ming-600 text-white py-3 px-4 rounded-full flex items-center text-sm justify-center font-semibold"
                download
                :href="contractStore.envelope.master"
                target="_blank"
                >Download Contract
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  class="w-5 h-5 ml-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                  />
                </svg>
              </a>
            </div>
          </div>
        </transaction-success>
      </template>
    </template>
    <loading-spinner v-else></loading-spinner>
    <loading-overlay
      :loading="contractStore.isLoadingContract"
      text="Preparing contract"
      subtext="This can take some time. Please do not refresh the page."
    ></loading-overlay>
    <loading-overlay
      :loading="contractStore.isCreatingSubscription"
      text="Setting up additional support"
    ></loading-overlay>
  </div>
</template>

<script setup>
import { computed, ref, watch, reactive, onMounted } from 'vue';
import { useMainStore } from '@/stores/mainStore';
import { useContractStore } from '@/stores/contractStore';
import { useRoute, useRouter } from 'vue-router';
import { object, string } from 'yup';
import { Form, useForm } from 'vee-validate';

import FormSection from '@/components/FormSection.vue';
import InformationGrid from '@/components/InformationGrid.vue';
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import PInput from '@/components/PInput.vue';
import StepProgress from '@/components/StepProgress.vue';
import SectionTitle from '@/components/SectionTitle.vue';
import TransactionSuccess from '@/components/TransactionSuccess.vue';

import AU_WHS from '@/json/whs/au_whs.json';
import UK_WHS from '@/json/whs/uk_whs.json';

import AU_PCS from '@/json/pcs/au_pcs.json';

const { handleSubmit } = useForm();
const mainStore = useMainStore();
const contractStore = useContractStore();
const route = useRoute();
const router = useRouter();

const isLoaded = ref(false);

const addonData = computed(() => {
  return mainStore.quote.addon_quote_data;
});

const isRelationshipManager = computed(() => {
  return mainStore.quote.addon_quote_data.label === 'Relationship Manager';
});

const schema = object({
  name: string().required().label('Authorised by'),
  email: string().email('Valid email required').required().label('Email'),
  position: string().required().label('Position'),
  other: string().when('position', ([position], schema) => {
    return position === 'Other' ? schema.required().label('Position') : schema.notRequired();
  }),
});

mainStore
  .getQuote(route.params.id)
  .then(() => {
    isLoaded.value = true;
    mainStore.title = addonData.value.label + ' Support';
    if (mainStore.quote.envelope_id) {
      contractStore.getEnvelope(mainStore.quote.envelope_id).then(() => {
        recipient.name = contractStore.envelope?.metadata?.authorised_by_name;
        recipient.email = contractStore.envelope?.metadata?.authorised_by_email;
        recipient.position =
          contractStore.contractPositionOptions.indexOf(contractStore.envelope?.metadata?.authorised_by_position) === -1
            ? 'Other'
            : contractStore.envelope?.metadata?.authorised_by_position;
        recipient.other =
          contractStore.contractPositionOptions.indexOf(contractStore.envelope?.metadata?.authorised_by_position) === -1
            ? contractStore.envelope?.metadata?.authorised_by_position
            : '';
      });
    }

    contractStore.contractData = {
      monthly_cost: addonData.value.monthly_total_inc_gst,
      contract_end_date: mainStore.quote.subscription.contract_end_date,
      existing_addons: addonData.value.existing_addons,
      addon: {
        id: addonData.value.id,
        label: addonData.value.label,
        short_label: addonData.value.short_label,
        subscription_term: addonData.value.subscription_term,
        subscription_term_value: addonData.value.subscription_term_value,
        weekly_cost: addonData.value.weekly_cost,
      },
      subscription_term: {
        value: addonData.value.subscription_term_value,
        label: addonData.value.subscription_term,
      },
    };
  })
  .catch((err) => {
    mainStore.errorMessage = 'Sorry we could not find your quote.';
    router.push({ name: 'NotFound' });
  });

const contractEndDateString = computed(() => {
  return mainStore.convertDateToString(contractStore.contractEndDate);
});

const contractHeight = computed(() => {
  return {
    height: window.screen.height < 1080 ? '90vh' : 'calc(100vh - 300px)',
  };
});

const isWHS = computed(() => {
  return addonData.value.short_label === 'WHS';
});

const isPCS = computed(() => {
  return addonData.value.short_label === 'PCS';
});

const isRM = computed(() => {
  return addonData.value.short_label === 'RM';
});

const promotionalItems = computed(() => {
  if (isWHS.value) {
    return mainStore.jurisdiction === 'au' ? AU_WHS : UK_WHS;
  }
  if (isPCS.value) {
    return AU_PCS;
  }
  if (isRM.value) {
    return [
      {
        label: 'Dedicated Relationship Manager',
        content:
          'You will be matched with a lawyer (your Relationship Manager) who is best placed to assist you with your legal needs. You will have direct access to, and support from, your Relationship Manager. You can book a call at a time that suits you best, directly with your Relationship Manager to discuss your legal request.',
      },
    ];
  }

  return [];
});

const promoPartOne = computed(() => {
  return promotionalItems.value.slice(0, 3).map((item) => {
    return {
      label: item.label,
      text: item.content,
    };
  });
});

const promoPartTwo = computed(() => {
  return promotionalItems.value.slice(3, 6).map((item) => {
    return {
      label: item.label,
      text: item.content,
    };
  });
});

const recipient = reactive({
  name: '',
  email: '',
  position: '',
  other: '',
});

const isEnvelopeSignable = computed(() => {
  const isRecipientSame = contractStore.envelope?.recipients[0].name.trim() === recipient?.name.trim();
  const isEnvelopeTermSame =
    contractStore.envelope?.metadata?.subscription_term === mainStore.quote?.addon_quote_data.subscription_term;
  let isPositionSame = false;
  if (recipient.position === 'Other') {
    isPositionSame = contractStore.envelope?.metadata?.authorised_by_position?.trim() === recipient?.other.trim();
  } else {
    isPositionSame = contractStore.envelope?.metadata?.authorised_by_position === recipient?.position;
  }

  return isRecipientSame && isEnvelopeTermSame && isPositionSame;
});

onMounted(() => {
  document.addEventListener('envelope-signed', handleSignedEvent, false);
  document.addEventListener('envelope-closed', handleClosedEvent, false);
});

const handleSignedEvent = (e) => {
  if (e.detail?.quoteId === mainStore.quote.id) {
    contractStore.contractSigned = true;
    contractStore.setupAddon();
  }
};

const handleClosedEvent = (e) => {
  console.log('envelope cancelled');
};

const onSubmit = handleSubmit(() => {
  contractStore.createEnvelope(recipient);
});

watch(
  recipient,
  (val) => {
    contractStore.contractData.name = val.name;
    contractStore.contractData.email = val.email;
  },
  { immediate: true }
);
</script>
