<template>
  <div>
    <template v-if="!mainStore.transactionComplete">
      <Form v-slot="{ errors }" :validation-schema="schema" @submit="onSubmit" @invalid-submit="onInvalidSubmit">
        <account-information-section>
          <template #error-list>
            <div
              v-if="errors && Object.keys(errors).length"
              class="flex p-4 mb-4 text-sm bg-warning rounded-md"
              id="error-alert"
              role="alert"
            >
              <svg
                aria-hidden="true"
                class="flex-shrink-0 inline w-5 h-5 mr-3"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="sr-only">Info</span>
              <div>
                <span class="font-medium">Please fix the following:</span>
                <ul class="mt-1.5 ml-4 list-disc list-inside">
                  <li v-for="error in Object.values(errors)" :key="error">{{ error }}</li>
                </ul>
              </div>
            </div>
          </template>
        </account-information-section>
        <template v-if="mainStore.upfrontCostEstimate">
          <section-title>Payment Details</section-title>
          <form-section>
            <template #label>
              <div class="sm:flex justify-between">
                <payment-type-select></payment-type-select>
                <template v-if="paymentStore.isCreditCard">
                  <div class="hidden sm:flex items-center">
                    <img src="/img/logo/stripe.svg" alt="" class="h-9" />
                  </div>
                  <div class="flex sm:hidden items-center">
                    <img src="/img/logo/stripe-small.svg" alt="" class="h-8" />
                  </div>
                </template>
              </div>
            </template>
            <template v-if="paymentStore.isCreditCard">
              <div class="columns-1 md:columns-2 mb-0 md:mb-2">
                <p-input
                  v-model="paymentStore.paymentMethodOwner.name"
                  label="Name"
                  name="name"
                  placeholder="Enter name on card..."
                />
                <p-input
                  v-model="paymentStore.paymentMethodOwner.email"
                  label="Email"
                  type="email"
                  name="email"
                  placeholder="example@company.com"
                />
              </div>
              <stripe-card-element />
            </template>
            <template v-else>
              <direct-deposit-payment></direct-deposit-payment>
              <div class="mt-4">
                <span class="font-italic text-xs sm:text-sm">
                  By making payment, you will be deemed to have accepted our
                  <a :href="mainStore.costAgreementUrl" target="_blank" class="text-teal">Terms of Engagement</a>. Once
                  you have made payment, please let your Legal Project Manager know via email with receipt attached.
                </span>
              </div>
            </template>
            <payment-error-message />
            <template v-if="mainStore.jurisdiction !== 'uk' && paymentStore.isCreditCard">
              <div class="text-center mt-4">
                <span class="font-italic text-xs sm:text-sm">
                  This transaction is subject to a surcharge of {{ paymentStore.cardFeePercent('card') }}% for Visa or
                  Mastercard, and {{ paymentStore.cardFeePercent('amex') }}% for American Express.
                </span>
              </div>
            </template>
          </form-section>
          <div class="flex justify-center space-x-8 my-8">
            <div class="flex-col text-left font-semibold text-sm">
              <div class="mb-2">Balance due</div>
              <div class="mb-2">Processing fee</div>
              <div class="mb-2">Total</div>
            </div>
            <div class="flex-col text-sm">
              <div class="mb-2">{{ mainStore.numberToCurrency(paymentStore.paymentAmount) }}</div>
              <div class="mb-2">{{ mainStore.numberToCurrency(paymentStore.processingFee) }}</div>
              <div class="mb-2">{{ mainStore.numberToCurrency(paymentStore.paymentTotal) }}</div>
            </div>
          </div>
        </template>
        <div class="flex mb-4 justify-center">
          <p-input v-model="acceptance" type="checkbox" name="acceptance"></p-input>
        </div>
        <footer-nav previous submit is-last-step :next="nextText" />
      </Form>
    </template>
    <template v-else>
      <transaction-success>Cost Estimate accepted</transaction-success>
    </template>
    <loading-overlay
      :loading="paymentStore.checkingPayment"
      :text="isPaymentRequired ? 'Processing payment' : 'Accepting terms'"
    ></loading-overlay>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';

import AccountInformationSection from '@/components/AccountInformationSection.vue';
import DirectDepositPayment from '@/components/DirectDepositPayment.vue';
import FooterNav from '@/components/FooterNav.vue';
import FormSection from '@/components/FormSection.vue';
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import PaymentErrorMessage from '@/components/PaymentErrorMessage.vue';
import PaymentTypeSelect from '@/components/PaymentTypeSelect.vue';
import PInput from '@/components/PInput.vue';
import StripeCardElement from '@/components/stripe/StripeCardElement.vue';
import SectionTitle from '@/components/SectionTitle.vue';
import TransactionSuccess from '@/components/TransactionSuccess.vue';

import { usePaymentStore } from '@/stores/paymentStore';
import { useMainStore } from '@/stores/mainStore';

import { Form, useForm } from 'vee-validate';
import { boolean, object, string } from 'yup';

const { handleSubmit } = useForm();
const paymentStore = usePaymentStore();
const mainStore = useMainStore();
const acceptance = ref(false);

const schema = computed(() => {
  return object({
    businessName: string().required().label('Business name'),
    streetAddress: string().required().label('Street address'),
    townCity: string().required().label(mainStore.area),
    state: string().required().label(mainStore.state),
    postCode: string().required().label('Post code'),
    country: string().required().label('Country'),
    acceptance: boolean().oneOf([true], `Acceptance of terms required`),
    email: isPaymentRequired.value
      ? string().email('Must be a valid email address').required().label('Email Address')
      : string().email(),
    name: isPaymentRequired.value ? string().required().label('Name on card') : string(),
  });
});

const isPaymentRequired = computed(() => {
  return mainStore.upfrontCostEstimate && paymentStore.isCreditCard;
});

function onInvalidSubmit({ values, errors, results }) {
  const el = document.querySelector(`#error-alert`);
  if (el) {
    el.scrollIntoView();
  }
}

const nextText = computed(() => {
  if (isPaymentRequired.value) {
    return 'Pay ' + mainStore.numberToCurrency(paymentStore.paymentTotal);
  } else {
    return 'Accept Estimate';
  }
});

const onSubmit = handleSubmit(async () => {
  mainStore.updateBusinessInformation();
  if (isPaymentRequired.value) {
    paymentStore.processCostEstimatePayment();
  } else {
    paymentStore.acceptCostEstimate();
  }
});
</script>
