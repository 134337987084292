<template>
  <div
    class="grid gap-3"
    :class="{
      'sm:grid-cols-2 lg:grid-cols-4':
        mainStore.quote.is_whs_sub_quote || !!mainStore.quote.subscription?.is_upgrade_with_active_whs,
      'grid-cols-1 md:grid-cols-2 lg:grid-cols-3':
        !mainStore.quote.is_whs_sub_quote && !mainStore.quote.subscription?.is_upgrade_with_active_whs,
    }"
  >
    <div
      v-for="(item, index) in promotionalItems"
      :key="item.label"
      @click="setModalData(item, index)"
      class="bg-white p-4 font-semibold cursor-pointer flex rounded-md shadow-md transition-transform hover:shadow-lg hover:-translate-y-1"
      :class="{ '!bg-gray-200': item.label === mainStore.whsLong && !contractStore.contractData.whs_addon_enabled }"
    >
      <div class="flex items-center basis-12 sm:basis-1/6 shrink-0">
        <img :src="'/img/icons/' + item.icon + '.svg'" alt="" />
      </div>
      <div
        class="flex items-center text-sm sm:basis-5/6"
        :class="{
          'line-through': item.label === mainStore.whsLong && !contractStore.contractData.whs_addon_enabled,
        }"
      >
        {{ item.label }}
      </div>
    </div>
  </div>
  <Modal
    v-model:active="modalState"
    :icon="modalData.icon"
    :label="modalData.label"
    :content="modalData.content"
    fixed-height="440"
  >
    <template #footer>
      <div class="h-[50px] w-full flex items-center justify-center p-4 border-t border-gray-200 rounded-b">
        <button @click="previousIndex" type="button" class="text-vivid-tangelo">
          <font-awesome-icon icon="fa-solid fa-chevron-left" class="mr-2" />
        </button>
        <div class="flex dots justify-center items-center">
          <template v-for="index in promotionalItems.length" :key="index">
            <li :class="{ active: modalIndex === index - 1 }">
              <a @click="changeModalData(index - 1)" />
            </li>
          </template>
        </div>
        <button @click="nextIndex" type="button" class="text-vivid-tangelo">
          <font-awesome-icon icon="fa-solid fa-chevron-right" class="ml-2" />
        </button>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useMainStore } from '@/stores/mainStore.js';
import { useContractStore } from '@/stores/contractStore.js';

import Modal from '../Modal.vue';
import AU_PROMO from '@/json/lvc-pro/au_promo.json';
import NZ_PROMO from '@/json/lvc-pro/nz_promo.json';
import UK_PROMO from '@/json/lvc-pro/uk_promo.json';
import AU_PROMO_EMPLOYMENT from '@/json/employment-hr/au_promo.json';

const mainStore = useMainStore();
const contractStore = useContractStore();

const modalState = ref(false);
const modalIndex = ref(0);
const modalData = ref({});

const promotionalItems = computed(() => {
  if (mainStore.jurisdiction === 'au' && mainStore.quote.is_employment_hr_quote) {
    return AU_PROMO_EMPLOYMENT;
  }
  const MAP_ITEMS = {
    au: AU_PROMO,
    nz: NZ_PROMO,
    uk: UK_PROMO,
  };

  const items = MAP_ITEMS[mainStore.jurisdiction] ?? AU_PROMO;

  if (mainStore.quote.is_whs_sub_quote || mainStore.quote.subscription?.is_upgrade_with_active_whs) {
    if (items.some((e) => e.label === mainStore.whsLong)) {
      return items;
    }
    items.push(
      {
        label: 'Legal health checks',
        content:
          '<p>Legals aren’t set and forget. Prudent legal and corporate governance requires a proactive approach to checking in on business operations, weak spots and growth pains to update legal documents, processes or policies.</p><p>Book in with the team for routine health checks at a frequency that works for you. Let us spot your blind spots and get ahead of preventable issues.</p>',
        icon: 'legal_health_check',
      },
      {
        label: mainStore.whsLong,
        content: `<p>LegalVision will support you with your WHS infrastructure and ongoing compliance steps.</p><p>We will:</p><ul><li>perform a ${mainStore.whsShort} Management Assessment;</li><li>develop a ${mainStore.whsShort}  Risk Management and Compliance framework;</li><li>draft customised ${mainStore.whsShort} documents and tools including internal policies, checklists and registers;</li><li>provide ongoing advice via our incident hotline and ${mainStore.whsShort} Advisory Service; and</li><li>provide ongoing training for both business owners/managers and teams.</li></ul>`,
        icon: 'whs',
      }
    );
  }
  return items;
});

function setModalData(item, index) {
  modalIndex.value = index;
  modalData.value = { ...item };
  modalState.value = true;
  document.body.classList.toggle('overflow-hidden');
  document.body.classList.toggle('bg-slate-100');
}

function changeModalData(index) {
  modalIndex.value = index;
  modalData.value = { ...promotionalItems.value[index] };
}

function test() {
  console.log('test');
}

function nextIndex() {
  const index = modalIndex.value + 1;
  if (index > promotionalItems.value.length - 1) {
    modalIndex.value = 0;
  } else {
    modalIndex.value = index;
  }
  modalData.value = { ...promotionalItems.value[modalIndex.value % promotionalItems.value.length] };
}

function previousIndex() {
  const index = modalIndex.value - 1;
  if (index < 0) {
    modalIndex.value = promotionalItems.value.length - 1;
  } else {
    modalIndex.value = index;
  }
  modalData.value = { ...promotionalItems.value[modalIndex.value % promotionalItems.value.length] };
}
</script>
<style scoped>
.dots {
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style: none;
  cursor: default;
}

.dots .active a {
  -webkit-transform: scale3d(1.4, 1.4, 1.4);
  transform: scale3d(1.4, 1.4, 1.4);
}

.dots .active a:after {
  height: 100%;
}

.dots li {
  position: relative;
  display: block;
  float: left;
  margin: 0 6px;
  width: 12px;
  height: 12px;
  cursor: pointer;
}

.dots li a {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  outline: none;
  border-radius: 50%;
  text-indent: -999em;
  cursor: pointer;
  position: absolute;
  overflow: hidden;
  background-color: transparent;
  box-shadow: inset 0 0 0 2px rgb(253, 107, 13);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

.dots li a:after {
  content: '';
  position: absolute;
  bottom: 0;
  height: 0;
  left: 0;
  width: 100%;
  background-color: rgb(253, 107, 13);
  box-shadow: 0 0 1px rgb(253, 107, 13);
  -webkit-transition: height 0.3s ease;
  transition: height 0.3s ease;
}

.membership-promo {
  transform: translateX(-50%);
}

.membership-promo :deep(p) {
  @apply my-2;
}

.membership-promo :deep(ul) {
  @apply ml-4 list-disc;
}
</style>
