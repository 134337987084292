import { defineStore } from 'pinia';
import { useMainStore } from './mainStore';
import { usePaymentStore } from './paymentStore';

export const useContractStore = defineStore('contract', {
  state: () => ({
    envelope: null,
    signUrl: '',
    contractSigned: false,
    contractData: {},
    isLoadingContract: false,
    isCreatingSubscription: false,
  }),
  getters: {
    contractPositionOptions() {
      return ['Director', 'CEO', 'CFO', 'CTO', 'General Manager', 'Office Manager', 'Secretary', 'Other'];
    },
    newContractEndDate() {
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth();
      const day = date.getDate();
      const additionalYear = Number(this.contractData?.subscription_term?.value.split('_')[0] || 1);
      return new Date(year + additionalYear, month, day);
    },
    upgradeContractEndDate() {
      const mainStore = useMainStore();

      if (!mainStore.quote?.subscription?.contract_end_date) {
        return this.newContractEndDate;
      }
      const date = new Date(mainStore.quote.subscription.contract_end_date);
      const year = date.getFullYear();
      const month = date.getMonth();
      const day = date.getDate();

      let diffInYears = 0;
      let revisedYears = year;

      const subscriptionTerm = mainStore.quote?.subscription?.subscription_term.split(' ')[0];
      const quoteTerm = this.contractData?.subscription_term?.label.split(' ')[0];

      if (mainStore.quote.subscription?.is_renewing_contract) {
        return new Date(year + Number(quoteTerm), month, day);
      }

      if (subscriptionTerm > quoteTerm) {
        diffInYears = subscriptionTerm - quoteTerm;
        revisedYears = year - Number(diffInYears);
      }

      if (subscriptionTerm < quoteTerm) {
        diffInYears = quoteTerm - subscriptionTerm;
        revisedYears = year + Number(diffInYears);
      }

      return new Date(revisedYears, month, day);
    },
    contractEndDate() {
      const mainStore = useMainStore();

      if (mainStore.quote?.id === 'd046872a-a79e-4f22-96f4-e39cfe765c54') {
        return new Date(2027, 4, 26);
      }

      if (mainStore.quote?.subscription && (mainStore.quote?.terms_accepted || mainStore.quote?.is_addon_only_quote)) {
        return mainStore.quote.subscription?.contract_end_date || this.newContractEndDate;
      }

      if (mainStore.quote.subscription?.is_renewing_contract) {
        return this.upgradeContractEndDate;
      }

      if (mainStore.quote?.subscription?.is_restarting_contract) {
        return this.newContractEndDate;
      }

      if (mainStore.quote?.subscription && !mainStore.quote?.terms_accepted) {
        const date = this.upgradeContractEndDate;
        this.contractData.contract_end_date = date;
        return date;
      }

      return this.newContractEndDate;
    },
  },
  actions: {
    async createEnvelope(recipient) {
      const mainStore = useMainStore();
      this.isLoadingContract = true;
      this.signUrl = '';
      const quote = mainStore.quote;
      const envelope = await axios.post(`/api/documents/envelopes/${quote.id}`, {
        recipient,
        client_name: mainStore.getBusinessInformation?.updated?.business_name || mainStore.quote.client_name,
        contractData: this.contractData,
        quote_type: quote.type,
      });

      this.getEnvelope(envelope.id);
    },
    async getEnvelope(envelopeId) {
      const envelope = await axios.get(`/api/documents/envelopes/${envelopeId}`).catch(() => {});
      const mainStore = useMainStore();
      this.envelope = envelope;

      if (this.envelope.status === 'completed' && mainStore.quote.terms_accepted) {
        this.contractSigned = true;
        mainStore.isBusinessInformationUpdated = true;
        mainStore.transactionComplete = true;
        return;
      }
      if (this.envelope.status === 'completed' && !mainStore.quote.terms_accepted && !this.contractSigned) {
        this.contractSigned = true;
        mainStore.isBusinessInformationUpdated = true;
        mainStore.transactionComplete = true;

        if (mainStore.quote.is_reactivate) {
          this.reactivateSubscription();
          return;
        }
        this.createSubscription();
        return;
      }

      if (this.envelope.recipients[0].status === 'declined') {
        this.envelope = null;
        return;
      }

      if (['sent', 'created'].indexOf(this.envelope.recipients[0].status) > -1) {
        this.getSigningToken();
      }
    },
    async getSigningToken() {
      this.isLoadingContract = true;
      const recipientId = this.envelope.recipients[0].id;

      const refreshId = setInterval(async () => {
        const signUrl = await axios
          .get(`/api/documents/receipients/${recipientId}/token`)
          .catch((err) => console.log(err));

        if (signUrl) {
          this.signUrl = signUrl;
          this.isLoadingContract = false;
          clearInterval(refreshId);
        }
      }, 3000);
    },
    createSubscription() {
      this.isCreatingSubscription = true;
      const mainStore = useMainStore();
      const paymentStore = usePaymentStore();

      return axios
        .post(`/api/subscription/${mainStore.quote.id}`, {
          setupIntentId: mainStore.quote.confirmed_intent.id,
          email: paymentStore.paymentMethodOwner.email,
          contractData: this.contractData,
        })
        .then(() => {
          mainStore.transactionComplete = true;
          mainStore.quote.subscription.contract_end_date = this.contractEndDate;
          this.getEnvelope(this.envelope.id);
        })
        .catch(() => {})
        .finally(() => {
          this.isCreatingSubscription = false;
        });
    },
    setupAddon() {
      this.isCreatingSubscription = true;
      const mainStore = useMainStore();
      const contractStore = useContractStore();
      const paymentStore = usePaymentStore();
      return axios
        .post(`/api/addon/${mainStore.quote.id}`, {
          monthly_cost: mainStore.quote.addon_quote_data.monthly_cost,
        })
        .then(() => {
          mainStore.transactionComplete = true;
          mainStore.quote.terms_accepted = 1;
          this.getEnvelope(this.envelope.id);
        })
        .catch(() => {
          paymentStore.paymentError = 'Something went wrong, please try again or contact us if the problem persists.';
        })
        .finally(() => {
          this.isCreatingSubscription = false;
        });
    },
    upgradeSubscription() {
      this.isCreatingSubscription = true;
      const mainStore = useMainStore();

      return axios
        .post(`/api/quote/upgrade/${mainStore.quote.id}`, {
          contractData: this.contractData,
        })
        .then(() => {
          mainStore.transactionComplete = true;
          mainStore.quote.terms_accepted = 1;
          mainStore.quote.subscription.contract_end_date = this.contractEndDate;
          this.getEnvelope(this.envelope.id);
        })
        .catch(() => {})
        .finally(() => {
          this.isCreatingSubscription = false;
        });
    },
    reactivateSubscription() {
      this.isCreatingSubscription = true;
      const mainStore = useMainStore();
      return axios
        .post(`/api/quote/reactivate/${mainStore.quote.id}`, {
          contractData: this.contractData,
        })
        .then(() => {
          mainStore.transactionComplete = true;
          mainStore.quote.terms_accepted = 1;
          mainStore.quote.subscription.contract_end_date = this.contractEndDate;
          this.getEnvelope(this.envelope.id);
        })
        .catch(() => {})
        .finally(() => {
          this.isCreatingSubscription = false;
        });
    },
  },
});
