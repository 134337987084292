<template>
  <div class="bg-white border border-lv-slate-200 rounded-md -mx-5 sm:mx-0">
    <div class="border-b border-lv-slate-200 p-4 sm:p-5">
      <span class="font-semibold">Details</span>
    </div>
    <div v-if="mainStore.acceptedCostStages?.length" class="p-4 sm:p-5">
      <div v-for="stage in mainStore.acceptedCostStages" :key="stage.name" class="text-sm mb-8">
        <div class="flex flex-col sm:flex-row sm:items-center justify-between mb-4">
          <div class="flex gap-4">
            <div class="flex border border-lv-slate-200 rounded-md font-semibold p-2">{{ stage.name }}</div>
            <div class="flex border border-validation-green rounded-md p-2 bg-validation-green text-white">Accepted</div>
          </div>
          <div class="flex flex-col sm:flex-row sm:space-x-4 justify-between">
            <div class="flex flex-row justify-between sm:flex-col text-right mt-3 sm:mt-0">
              <div class="font-semibold">{{ stage.fee_type?.value === 'fixed' ? 'Price' : 'Estimated Cost'}}</div>
              <div>{{ mainStore.numberToCurrency(getFeeEstimateWithBundledActivities(stage)) }}</div>
            </div>
          </div>
        </div>
        <div class="mb-4" v-if="stage.description">
          <div class="font-semibold pb-2">Scope of Work</div>
          <p class="mb-4" v-html="replaceNewlines(withoutPrefix(stage.description, 'Scope of Work:'))"></p>
        </div>
        <div class="mb-4" v-if="stage.complimentary_work">
          <div class="font-semibold pb-2">Complimentary Work</div>
          <p class="mb-4" v-html="replaceNewlines(withoutPrefix(stage.complimentary_work, 'Complimentary work:'))"></p>
        </div>
        <template v-if="stage.activity_items?.length">
          <div v-for="item in stage.activity_items" :key="item.name">
            <div v-if="item.invoice_visibility?.value === 'item'" class="flex flex-col sm:flex-row sm:items-center justify-between mb-4">
              <div class="flex flex-col">
                <div class="flex border border-lv-slate-200 bg-light-slate-grey rounded-md font-semibold p-2">
                  {{ item.name }}
                </div>
                <div class="mt-4" v-if="item.invoice_description">
                  <p v-html="replaceNewlines(item.invoice_description)"></p>
                </div>
              </div>
              <div class="flex flex-row justify-between sm:flex-col text-right mt-3 sm:mt-0">
                <div class="font-semibold">Price</div>
                <div>{{ mainStore.numberToCurrency(item.amount) }}</div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div v-if="mainStore.unacceptedCostStages?.length" class="p-4 sm:p-5">
      <div v-for="stage in mainStore.unacceptedCostStages" :key="stage.name" class="text-sm mb-8">
        <div class="flex flex-col sm:flex-row sm:items-center justify-between mb-4">
          <div class="flex border border-lv-slate-200 rounded-md font-semibold p-2">{{ stage.name }}</div>
          <div class="flex flex-col sm:flex-row sm:space-x-4 justify-between">
            <div class="flex flex-row justify-between sm:flex-col text-right mt-3 sm:mt-0">
              <div class="font-semibold">{{ stage.fee_type?.value === 'fixed' ? 'Price' : 'Estimated Cost'}}</div>
              <div>{{ mainStore.numberToCurrency(getFeeEstimateWithBundledActivities(stage)) }}</div>
            </div>
          </div>
        </div>
        <div class="mb-4" v-if="stage.description">
          <div class="font-semibold pb-2">Scope of Work</div>
          <p class="mb-4" v-html="replaceNewlines(withoutPrefix(stage.description, 'Scope of Work:'))"></p>
        </div>
        <div class="mb-4" v-if="stage.complimentary_work">
          <div class="font-semibold pb-2">Complimentary Work</div>
          <p class="mb-4" v-html="replaceNewlines(withoutPrefix(stage.complimentary_work, 'Complimentary work:'))"></p>
        </div>
        <template v-if="stage.activity_items?.length">
          <div v-for="item in stage.activity_items" :key="item.name">
            <div v-if="item.invoice_visibility?.value === 'item'" class="flex flex-col sm:flex-row sm:items-center justify-between mb-4">
              <div class="flex flex-col">
                <div class="flex border border-lv-slate-200 bg-light-slate-grey rounded-md font-semibold p-2">
                  {{ item.name }}
                </div>
                <div class="mt-4" v-if="item.invoice_description">
                  <p v-html="replaceNewlines(item.invoice_description)"></p>
                </div>
              </div>
              <div class="flex flex-row justify-between sm:flex-col text-right mt-3 sm:mt-0">
                <div class="font-semibold">Price</div>
                <div>{{ mainStore.numberToCurrency(item.amount) }}</div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="border-t border-lv-slate-200 p-4 sm:p-5 flex items-end flex-col text-sm">
      <template v-if="total > 0">
        <div class="flex w-full mb-2 md:w-1/3 justify-end">
          <span class="font-semibold text-right">Amount payable to trust</span>
        </div>
        <div class="flex justify-between w-full mb-2 md:w-1/3">
          <span class="font-semibold"></span>
          <span class="">{{ mainStore.numberToCurrency(total) }}</span>
        </div>
      </template>
      <template v-if="amountPaidToDate > 0">
        <div class="flex w-full mb-2 md:w-1/3 justify-end">
          <span class="font-semibold text-right">Amount paid to date</span>
        </div>
        <div class="flex justify-between w-full mb-2 md:w-1/3">
          <span class="font-semibold"></span>
          <span class="">{{ mainStore.numberToCurrency(amountPaidToDate) }}</span>
        </div>
      </template>
      <template v-if="disbursementTaxes > 0">
        <div class="flex w-full mb-2 md:w-1/3 justify-end">
          <span class="font-semibold text-right">Estimated {{ taxType }} on disbursements</span>
        </div>
        <div class="flex justify-between w-full mb-2 md:w-1/3">
          <span class="font-semibold"></span>
          <span class="">{{ mainStore.numberToCurrency(disbursementTaxes) }}</span>
        </div>
      </template>
      <template v-if="totalEstimate > 0">
        <div class="flex w-full mb-2 md:w-1/3 justify-end">
          <span class="font-semibold text-right">Total {{ estimateOrQuote }}</span>
        </div>
        <div class="flex justify-between w-full mb-2 md:w-1/3">
          <span class="font-semibold"></span>
          <span class="">{{ mainStore.numberToCurrency(totalEstimate) }}</span>
        </div>
      </template>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, watch } from 'vue';
import { useMainStore } from '@/stores/mainStore.js';

const mainStore = useMainStore();

const localEstimate = mainStore.costEstimate;

const subtotal = computed(() => {
  return mainStore.unacceptedCostStages?.reduce((acc, stage) => {
    return acc + parseFloat(stage.latestPaymentAdvice?.taxable_subtotal || 0);
  }, 0);
});
const gst = computed(() => {
  return mainStore.unacceptedCostStages?.reduce((acc, stage) => {
    return acc + parseFloat(stage.latestPaymentAdvice?.total_gst || 0);
  }, 0);
});
const total = computed(() => {
  return mainStore.unacceptedCostStages?.reduce((acc, stage) => {
    return acc + parseFloat(stage.latestPaymentAdvice?.balance || 0);
  }, 0);
});

const totalNotTrust = computed(() => {
  const noTrust = mainstore.unacceptedCostStages?.filter((stage) => !stage.latestPaymentAdvice);
  return noTrust?.reduce((acc, stage) => {
    return acc + parseFloat(stage.total_estimate);
  }, 0);
});

const totalEstimate = computed(() => {
  return mainStore.costEstimate?.stages?.reduce((acc, stage) => {
    return acc + parseFloat(stage.total_estimate);
  }, 0);
});
const totalToAccept = computed(() => {
  return mainStore.unacceptedCostStages?.reduce((acc, stage) => {
    return acc + parseFloat(stage.latestPaymentAdvice?.total_inc_gst || stage.total_estimate);
  }, 0);
});

const amountPaidToDate = computed(() => mainStore.costEstimate?.amount_paid_to_date ?? 0);

const disbursementTaxes = computed(() => mainStore.costEstimate?.stages?.reduce((acc, stage) => {
  return acc + parseFloat(stage.activity_item_tax);
}, 0));

const { taxType } = mainStore;

const replaceNewlines = (text) => {
  return (text ?? '').replace(/\n/g, '<br />');
};

const withoutPrefix = (text, prefix) => {
  const replaceRegex = new RegExp(`${prefix}\\s*`, 'i');
  return text?.replace(replaceRegex, '');
}


const isTradeMarkApp = computed(() => mainStore.costEstimate?.matter_type?.value === 'TradeMarkApplication');
const estimateOrQuote = computed(() => isTradeMarkApp.value ? 'Quote' : 'Estimate');

const getFeeEstimateWithBundledActivities = (stage) => {
  const feeEstimate = parseFloat(stage.fee_estimate ?? 0)
  const bundledActivityTotal = (stage.activity_items ?? [])
    .filter((item) => item.invoice_visibility?.value === 'bundle')
    .reduce((acc, item) => acc + parseFloat(item.amount), 0)
  return feeEstimate + bundledActivityTotal;
}

watch(
  total,
  () => {
    mainStore.costEstimate.total = total.value;
  },
  { immediate: true }
);
</script>
