<template>
  <Form @submit="onSubmit" :validation-schema="schema" @invalid-submit="onInvalidSubmit">
    <account-information-section></account-information-section>
    <section-title>Payment Details</section-title>
    <template v-if="mainStore.quote.confirmed_intent">
      <form-section :show-label="false">
        <div>
          <font-awesome-icon icon="fa-solid fa-circle-check" class="mr-3 text-green-500" size="xl" />
          Payment details captured
        </div>
      </form-section>
    </template>
    <template v-else>
      <form-section :show-label="mainStore.jurisdiction === 'au'" id="payment-form">
        <template #label>
          <div class="sm:flex justify-between">
            <payment-type-select></payment-type-select>
            <div class="hidden sm:flex items-center">
              <img src="/img/logo/stripe.svg" alt="" class="h-9" />
            </div>
            <div class="flex sm:hidden items-center">
              <img src="/img/logo/stripe-small.svg" alt="" class="h-8" />
            </div>
          </div>
        </template>
        <template v-if="mainStore.quote.is_bacs_enabled">
          <stripe-payment-element :client-secret="mainStore.quote.setup_intent_id"></stripe-payment-element>
        </template>
        <template v-else>
          <div class="columns-1 md:columns-2 mb-0 md:mb-2">
            <p-input
              v-model="paymentStore.paymentMethodOwner.name"
              label="Name"
              name="name"
              :placeholder="paymentStore.isCreditCard ? 'Enter name on card...' : 'Enter name of account holder...'"
            />
            <p-input
              v-model="paymentStore.paymentMethodOwner.email"
              label="Email"
              type="email"
              name="email"
              placeholder="example@company.com"
            />
          </div>
          <stripe-card-element v-if="paymentStore.isCreditCard" />
          <stripe-becs-debit-element v-else />
        </template>
        <payment-error-message />
        <template v-if="paymentStore.isCreditCard && !mainStore.quote.is_bacs_enabled">
          <div class="text-center mt-4">
            <span class="font-italic text-sm">
              A hold of {{ mainStore.currencyType }}0.50 will be placed on your card for authorisation. This will be
              released after 7 days.
              <span class="font-semibold" v-if="mainStore.jurisdiction !== 'uk'"
                >There are no fees to pay your membership via credit card.
              </span>
            </span>
          </div>
        </template>
      </form-section>
    </template>
    <div class="flex flex-col sm:flex-row mb-0 sm:mb-6">
      <div class="flex flex-col mb-6 sm:basis-1/2 sm:mb-0 pr-0 sm:pr-4">
        <div class="font-bold">{{ firstDebitDateString }}</div>
        <div class="text-sm">{{ localQuote.subscription ? 'Next' : 'First' }} Debit Date</div>
      </div>
      <div class="flex flex-col mb-6 sm:basis-1/2 sm:mb-0 pl-0 sm:pl-4">
        <div class="font-bold">
          {{ mainStore.numberToCurrency(localQuote.total + contractStore.contractData.monthly_cost) }} (inc.
          {{ mainStore.taxType }})
        </div>
        <div class="text-sm">{{ localQuote.subscription ? 'Updated' : 'First' }} Debit Amount</div>
      </div>
    </div>
    <div class="flex flex-col sm:flex-row mb-8">
      <div class="flex flex-col mb-6 sm:basis-1/2 sm:mb-0 text-sm pr-0 sm:pr-4">
        <div class="font-bold">
          There are no fees to pay upfront, our team will get to work on supporting your business right away. Monthly
          invoices will be issued on the same date as your sign up today, with your first invoice being issued next
          month. Your first direct debit will the day after your invoice is issued, see ‘First Debit Date’ above.
        </div>
      </div>
      <div class="flex flex-col mb-6 sm:basis-1/2 sm:mb-0 text-sm pl-0 sm:pl-4">
        <div>
          Subsequent debit dates will occur monthly, after the First Debit Date. If you accept this quote, then, on each
          Debit Date, you will be automatically charged the total value of any fixed fee or hourly rate Legal Assistance
          that you asked us to perform and that we completed since the last Debit Date, including any disbursements,
          plus the Monthly Membership Price.
        </div>
      </div>
    </div>
    <legalvision-awards></legalvision-awards>
    <loading-overlay :loading="paymentStore.checkingPayment" text="Adding payment method"></loading-overlay>
    <footer-nav
      previous
      :submit="!mainStore.isPreview"
      next="Proceed to Sign Up"
      :disabled="paymentStore.checkingPayment"
    />
  </Form>
</template>

<script setup>
import { ref, reactive, watch, computed, onMounted } from 'vue';
import { usePaymentStore } from '@/stores/paymentStore.js';
import { useMainStore } from '@/stores/mainStore.js';
import { useContractStore } from '@/stores/contractStore.js';
import { Form, useForm } from 'vee-validate';
import { object, string } from 'yup';

import AccountInformationSection from '@/components/AccountInformationSection.vue';
import FormSection from '@/components/FormSection.vue';
import FooterNav from '@/components/FooterNav.vue';
import LegalvisionAwards from '@/components/LegalvisionAwards.vue';
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import PInput from '@/components/PInput.vue';
import PaymentTypeSelect from '@/components/PaymentTypeSelect.vue';
import PaymentErrorMessage from '@/components/PaymentErrorMessage.vue';
import StripeCardElement from '@/components/stripe/StripeCardElement.vue';
import StripePaymentElement from '@/components/stripe/StripePaymentElement.vue';
import StripeBecsDebitElement from '@/components/stripe/StripeBecsDebitElement.vue';
import SectionTitle from '@/components/SectionTitle.vue';

const { handleSubmit } = useForm();
const paymentStore = usePaymentStore();
const mainStore = useMainStore();
const contractStore = useContractStore();

const localQuote = mainStore.quote;

const schema = computed(() => {
  return object({
    businessName: string().required().label('Business name'),
    streetAddress: string().required().label('Street address'),
    townCity: string().required().label(mainStore.area),
    state: string().required().label(mainStore.state),
    postCode: string().required().label('Post code'),
    country: string().required().label('Country'),
    name:
      !mainStore.quote.confirmed_intent && !mainStore.quote.is_bacs_enabled
        ? string().required().label('Name')
        : string(),
    email:
      !mainStore.quote.confirmed_intent && !mainStore.quote.is_bacs_enabled
        ? string().email('Must be a valid email address').required().label('Email Address')
        : string().email(),
  });
});

const firstDebitDateString = computed(() => {
  return paymentStore.nextDebitDate.toLocaleDateString();
});

const onSubmit = handleSubmit(async () => {
  if (mainStore.quote.terms_accepted) {
    nextStep();
    return;
  }

  if (mainStore.quote.confirmed_intent && mainStore.isBusinessInformationUpdated) {
    nextStep();
    return;
  }

  if (mainStore.quote.confirmed_intent && !mainStore.isBusinessInformationUpdated) {
    await mainStore.updateBusinessInformation();
    nextStep();
    return;
  }

  mainStore.updateBusinessInformation();

  if (mainStore.quote.is_bacs_enabled) {
    // for stripe-payment-element component
    paymentStore.submitPaymentElement();
    return;
  }
  paymentStore.createPaymentMethod();
});

function nextStep() {
  mainStore.$patch({
    currentStep: mainStore.currentStep + 1,
  });
}

function onInvalidSubmit({ values, errors, results }) {
  const firstErrorFieldName = Object.keys(errors)[0];
  const el = document.querySelector(`[name="${firstErrorFieldName}"]`);
  if (el) {
    const yOffset = -200;
    const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: 'smooth' });
  }
}

const paymentErrror = computed(() => {
  return paymentStore.paymentError;
});

watch(paymentErrror, (error) => {
  if (!error) {
    return;
  }
  const el = document.querySelector('#payment-form');
  if (el) {
    el.scrollIntoView();
  }
});
</script>
