<template>
  <div v-if="paymentError" class="bg-red-100 p-4 rounded-r-lg shadow-md border-l-4 mt-4 border-red-600">
    <div class="flex items-center justify-between">
      <div class="flex ml-0 sm:ml-8">
        <div class="flex justify-center mr-2 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            class="w-6 h-6 mr-2 text-red-500"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
            />
          </svg>
        </div>
        <span class="font-semibold text-red-500 text-sm sm:text-base"> {{ paymentError }}</span>
      </div>
      <button class="text-red-500 hover:underline text-sm" @click="paymentStore.paymentError = ''">Dismiss</button>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { usePaymentStore } from '@/stores/paymentStore';

const paymentStore = usePaymentStore();

const paymentError = computed(() => paymentStore.paymentError);
</script>
