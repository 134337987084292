<template>
  <div>
    <template v-if="!mainStore.transactionComplete">
      <Form v-slot="{ errors }" :validation-schema="schema" @submit="onSubmit" @invalid-submit="onInvalidSubmit">
        <account-information-section>
          <template #error-list>
            <div
              v-if="errors && Object.keys(errors).length"
              id="error-alert"
              class="flex p-4 mb-4 text-sm bg-warning rounded-md"
              role="alert"
            >
              <svg
                aria-hidden="true"
                class="flex-shrink-0 inline w-5 h-5 mr-3"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="sr-only">Info</span>
              <div>
                <span class="font-medium">Please fix the following:</span>
                <ul class="mt-1.5 ml-4 list-disc list-inside">
                  <li v-for="error in Object.values(errors)" :key="error">{{ error }}</li>
                </ul>
              </div>
            </div>
          </template>
        </account-information-section>
        <template v-if="mainStore.quote.type === 'Payment_Advice'">
          <section-title>Payment Details</section-title>
          <form-section :show-label="mainStore.jurisdiction === 'au'">
            <template #label>
              <payment-type-select :bank="false" :direct-deposit="true"></payment-type-select>
            </template>
            <template v-if="paymentStore.isCreditCard">
              <template v-if="paymentStore.isMultiPayment">
                <div class="columns-2 flex">
                  <div class="mb-2">
                    <label for="numberthing" class="block mb-2 font-semibold text-xs sm:text-sm">Amount</label>
                    <input
                      v-model="paymentStore.multiPaymentAmount"
                      id="numberthing"
                      type="number"
                      step="0.01"
                      class="h-10 sm:h-12 bg-lv-slate-20 border border-lv-slate-200 rounded-lg placeholder:text-lv-slate-600 placeholder:text-sm text-sm sm:text-base block w-full p-2.5 focus:outline-none"
                    />
                  </div>
                </div>
              </template>
              <div class="columns-1 md:columns-2 mb-0 md:mb-2">
                <p-input
                  v-model="paymentStore.paymentMethodOwner.name"
                  label="Name"
                  name="name"
                  placeholder="Enter name on card..."
                />
                <p-input
                  v-model="paymentStore.paymentMethodOwner.email"
                  label="Email"
                  type="email"
                  name="email"
                  placeholder="example@company.com"
                />
              </div>
              <stripe-card-element />
            </template>
            <template v-else>
              <direct-deposit-payment></direct-deposit-payment>
              <div class="mt-4">
                <span class="font-italic text-xs sm:text-sm">
                  By making payment, you will be deemed to have accepted our
                  <a :href="mainStore.costAgreementUrl" target="_blank" class="text-teal">Terms of Engagement</a>. Once
                  you have made payment, please let your Legal Project Manager know via email with receipt attached.
                </span>
              </div>
            </template>
            <payment-error-message />
            <template v-if="mainStore.jurisdiction !== 'uk' && paymentStore.isCreditCard">
              <div class="text-center mt-4">
                <span class="font-italic text-xs sm:text-sm">
                  This transaction is subject to a surcharge of {{ paymentStore.cardFeePercent('card') }}% for Visa or
                  Mastercard, and {{ paymentStore.cardFeePercent('amex') }}% for American Express.
                </span>
              </div>
            </template>
          </form-section>
        </template>
        <information-grid
          v-if="(mainStore.quote?.payment_terms && mainStore.quote?.is_multiple_payments) || !isPaymentAdvice"
          :data-array="paymentTerms"
        ></information-grid>
        <template v-if="mainStore.quote.type === 'Payment_Advice'">
          <div class="flex justify-center space-x-8 my-8">
            <div class="flex-col text-left font-semibold text-sm">
              <div class="mb-2">Balance due</div>
              <div class="mb-2">Processing fee</div>
              <div class="mb-2">Total</div>
            </div>
            <div class="flex-col text-sm">
              <div class="mb-2">{{ mainStore.numberToCurrency(paymentStore.paymentAmount) }}</div>
              <div class="mb-2">{{ mainStore.numberToCurrency(paymentStore.processingFee) }}</div>
              <div class="mb-2">{{ mainStore.numberToCurrency(paymentStore.paymentTotal) }}</div>
            </div>
          </div>
        </template>
        <div v-if="(isPaymentAdvice && paymentStore.isCreditCard) || !isPaymentAdvice" class="flex mb-4 justify-center">
          <p-input v-model="acceptance" type="checkbox" name="acceptance"></p-input>
        </div>
        <footer-nav
          previous
          submit
          is-last-step
          :disabled="
            (isPaymentAdvice && paymentStore.paymentAmount <= 0) ||
            (isQuoteQuote && mainStore.quote.terms_accepted) ||
            (isQuoteQuote && !hasQLIs)
          "
          :next="nextText"
        />
      </Form>
    </template>
    <template v-else>
      <transaction-success>{{ isPaymentAdvice ? 'Payment Successful' : 'Terms Accepted' }}</transaction-success>
    </template>
    <loading-overlay
      :loading="paymentStore.checkingPayment"
      :text="isPaymentAdvice ? 'Processing payment' : 'Accepting terms'"
    ></loading-overlay>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';

import AccountInformationSection from '@/components/AccountInformationSection.vue';
import DirectDepositPayment from '@/components/DirectDepositPayment.vue';
import FooterNav from '@/components/FooterNav.vue';
import FormSection from '@/components/FormSection.vue';
import InformationGrid from '@/components/InformationGrid.vue';
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import PaymentTypeSelect from '@/components/PaymentTypeSelect.vue';
import PaymentErrorMessage from '@/components/PaymentErrorMessage.vue';
import PInput from '@/components/PInput.vue';
import StripeCardElement from '@/components/stripe/StripeCardElement.vue';
import SectionTitle from '@/components/SectionTitle.vue';
import TransactionSuccess from '@/components/TransactionSuccess.vue';

import { usePaymentStore } from '@/stores/paymentStore';
import { useMainStore } from '@/stores/mainStore';

import { Form, useForm } from 'vee-validate';
import { boolean, object, string } from 'yup';

const { handleSubmit } = useForm();
const paymentStore = usePaymentStore();
const mainStore = useMainStore();

const isPaymentAdvice = computed(() => mainStore.quote.type === 'Payment_Advice');
const isQuoteQuote = computed(() => mainStore.quote.type === 'Quote');
const hasQLIs = computed(() => mainStore.quote.quote_line_items?.length > 0);
const acceptance = ref(false);

const schema = object({
  businessName: string().required().label('Business name'),
  streetAddress: string().required().label('Street address'),
  townCity: string().required().label(mainStore.area),
  state: string().required().label(mainStore.state),
  postCode: string().required().label('Post code'),
  country: string().required().label('Country'),
  acceptance: boolean().oneOf(
    [true],
    `${isPaymentAdvice.value ? 'Payment requires acceptance of terms' : 'Acceptance of terms required'}`
  ),
  email: isPaymentAdvice.value
    ? string().email('Must be a valid email address').required().label('Email Address')
    : string().email(),
  name: isPaymentAdvice.value ? string().required().label('Name on card') : string(),
});

if (isPaymentAdvice.value) {
  paymentStore.$patch({
    multiPaymentAmount: mainStore.quote.balance,
  });
}

function onInvalidSubmit({ values, errors, results }) {
  const el = document.querySelector(`#error-alert`);
  if (el) {
    el.scrollIntoView();
  }
}

const nextText = computed(() => {
  if (isPaymentAdvice.value) {
    if (paymentStore.isCreditCard) {
      return 'Pay ' + mainStore.numberToCurrency(paymentStore.paymentTotal);
    }
    return '';
  }
  return 'Accept Terms';
});

const onSubmit = handleSubmit(async () => {
  mainStore.updateBusinessInformation();
  if (isPaymentAdvice.value) {
    paymentStore.processPaymentAdvice();
  } else {
    paymentStore.acceptTerms();
  }
});

const paymentTerms = ref([
  {
    label: 'Payment Terms',
    text: mainStore.quote?.payment_terms,
  },
]);
</script>
