<template>
  <div class="bg-light-slate-grey flex sticky top-0 z-40 -mx-5 sm:mx-0 px-2 sm:px-0">
    <div
      class="inline-flex my-4 overflow-auto snap-x md:mx-auto"
      :class="{
        'md:mx-auto': steps.length > 3,
      }"
    >
      <div class="min-h-min flex">
        <div
          v-for="(step, index) in steps"
          :key="index"
          @click="stageReached >= index ? (mainStore.currentStep = index) : false"
          :id="step.name.replace(/\s/g, '')"
          class="flex items-center snap-start flex-nowrap whitespace-nowrap scroll-mt-1.5"
          :class="{
            'text-lv-slate-400': mainStore.currentStep !== index,
            'cursor-pointer': stageReached >= index,
          }"
        >
          <div
            class="flex items-center p-2"
            :class="{ 'border-teal border-b-2 font-semibold': mainStore.currentStep === index }"
          >
            <div class="w-6 mr-2">
              <div
                class="rounded-full text-center text-white text-sm"
                :class="{
                  'bg-lv-slate-400': mainStore.currentStep !== index,
                  'bg-teal': mainStore.currentStep === index,
                }"
              >
                {{ index + 1 }}
              </div>
            </div>
            <span class="font-normal text-sm" :class="{ 'text-teal font-semibold': mainStore.currentStep === index }">{{
              step.name
            }}</span>
          </div>
          <div
            v-if="index !== steps.length - 1"
            class="mx-3"
            :class="{ 'border-b-2 border-light-slate-grey': mainStore.currentStep === index }"
          >
            <font-awesome-icon icon="fa-solid fa-chevron-right" size="xs" class="text-slate-500" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { watch, computed, ref } from 'vue';
import { useMainStore } from '@/stores/mainStore.js';

const props = defineProps({
  steps: {
    type: Array,
    default: () => [],
    required: true,
  },
});

const mainStore = useMainStore();

const currentStep = computed(() => {
  return mainStore.currentStep;
});

const stageReached = computed(() => {
  return mainStore.stageReached;
});

watch(currentStep, (val) => {
  let element = document.querySelector(`#${props.steps[val].name.replace(/\s/g, '')}`);
  element.scrollIntoView({ behavior: 'smooth' });
});
</script>
