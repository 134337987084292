<template>
  <div class="flex flex-col gap-6">
    <template v-if="costEstimateInformation?.length">
      <section-title class="mt-6 sm:mt-12">Summary</section-title>
      <information-grid
        v-if="costEstimateInformation?.length"
        :data-array="costEstimateInformation"
        class="!mb-0"
      ></information-grid>
    </template>
    <cost-estimate-stages></cost-estimate-stages>
    <!--    <div class='bg-white  rounded-md -mx-5 sm:mx-0 p-4'>-->
    <!--      LegalVision's hourly rates are set out in the <a href="/costs-agreement" target="_blank" class='text-ming-600'>Engagement Terms</a> together with the rights and obligations of both parties.-->
    <!--    </div>-->
    <cost-estimate-exclusions />
    <cost-estimate-disclaimer />
    <footer-nav
      :disabled="isAllAccepted"
      :is-last-step="isAllAccepted"
      :next="isAllAccepted ? 'Nothing to accept' : nextText"
    ></footer-nav>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useMainStore } from '@/stores/mainStore.js';
import InformationGrid from '@/components/InformationGrid.vue';
import CostEstimateStages from '@/components/CostEstimateStages.vue';
import SectionTitle from '@/components/SectionTitle.vue';
import FooterNav from '@/components/FooterNav.vue';
import CostEstimateDisclaimer from '@/components/cost-estimates/CostEstimateDisclaimer.vue';
import CostEstimateExclusions from './CostEstimateExclusions.vue';

const mainStore = useMainStore();
const costEstimateInformation = ref([]);

const costsAgreementUrl = [
  {
    current: 'https://pay.legalvision.com.au/costs-agreement',
    target: 'https://pay.legalvision.com.au/costs-agreement',
  },
  {
    current: 'https://pay.legalvision.co.nz/costs-agreement',
    target: 'https://pay.legalvision.co.nz/costs-agreement',
  },
  {
    current: 'https://pay.legalvision.co.uk/costs-agreement',
    target: 'https://pay.legalvision.co.uk/costs-agreement-ff-hr',
  },
];

if (mainStore.costEstimate?.description) {
  let description = mainStore.costEstimate.description;

  costsAgreementUrl.forEach((url) => {
    description = description.replace(url.current, `<a href="${url.target}" target="_blank">${url.target}</a>`);
  });

  costEstimateInformation.value.push({
    label: mainStore.costEstimate.name,
    text: description.replace(/\n/g, '<br>'),
    icon: 'immediate_needs',
  });
}

const isAllAccepted = computed(() => {
  const allStagesAccepted = !mainStore.unacceptedCostStages?.length;
  if (mainStore.costEstimate?.trade_mark_application) {
    return allStagesAccepted && mainStore.costEstimate.trade_mark_application.date_accepted;
  }
  return allStagesAccepted;
});

const nextText = mainStore.upfrontCostEstimate ? 'Payment' : 'Acceptance';
</script>
