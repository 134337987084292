import './bootstrap';
import { createApp } from 'vue';
import { datadogRum } from '@datadog/browser-rum';
import '../css/app.css';
import App from '@/App.vue';
import store from '@/stores/store.js';
import router from './router';
import * as Sentry from '@sentry/vue';
import VueGtag from 'vue-gtag';
import { useMainStore } from '@/stores/mainStore.js';
import { loadStripe } from '@stripe/stripe-js';
import VueSmoothScroll from 'vue3-smooth-scroll';
import axios from 'axios';

axios.interceptors.response.use((response) => response.data);

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fas);

const app = createApp(App).component('font-awesome-icon', FontAwesomeIcon);
app.use(store);
app.use(router);
app.use(VueSmoothScroll);
app.use(VueGtag, {
  config: { id: 'UA-36375133-12' },
});

const dsn = ['staging', 'production'].includes(import.meta.env.VITE_APP_ENV)
  ? import.meta.env.VITE_SENTRY_LARAVEL_DSN_FRONTEND
  : null;

Sentry.init({
  app,
  dsn: dsn,
  release: import.meta.env.VITE_SENTRY_RELEASE,
  environment: import.meta.env.VITE_APP_ENV,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
  ],
});

Sentry.configureScope(function (scope) {
  scope.setExtra('Environment', import.meta.env.VITE_APP_ENV);
  scope.setLevel('warning');
});

datadogRum.init({
  applicationId: 'cefed5a5-3ec6-4c39-a57e-106edf6e6af9',
  clientToken: 'pub92aa857da7b199bd56fba0543d10e4eb',
  site: 'datadoghq.com',
  service: 'payment-portal',
  env: import.meta.env.VITE_APP_ENV,
  version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
});

const mainStore = useMainStore();
app.config.globalProperties.mainStore = mainStore;

mainStore.jurisdiction = import.meta.env.VITE_APP_JURISDICTION;
mainStore.environment = import.meta.env.VITE_APP_ENV;

async function initializeStripe() {
  mainStore.stripe = await loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_API_KEY);
}

initializeStripe();

app.mount('#app');
