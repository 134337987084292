<template>
  <div class="container mx-auto justify-center flex flex-col mb-14 p-4 md:py-0">
    <template v-if="isLoaded">
      <client-name></client-name>
      <status-banner :status="status"></status-banner>
      <step-progress :steps="steps"></step-progress>
      <transition name="fade" appear>
        <keep-alive>
          <component :is="steps[mainStore.currentStep].component"></component>
        </keep-alive>
      </transition>
    </template>
    <loading-spinner v-else></loading-spinner>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useMainStore } from '@/stores/mainStore';
import { useRoute, useRouter } from 'vue-router';

import ClientName from '@/components/ClientName.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import StepProgress from '@/components/StepProgress.vue';
import StatusBanner from '@/components/StatusBanner.vue';

import PartOne from '@/components/payment-advice/PartOne.vue';
import PartTwo from '@/components/payment-advice/PartTwo.vue';

const mainStore = useMainStore();
const route = useRoute();
const router = useRouter();

const isLoaded = ref(false);
const isPaymentAdvice = computed(() => mainStore.quote?.type === 'Payment_Advice');

mainStore
  .getQuote(route.params.id)
  .then(() => {
    isLoaded.value = true;
    mainStore.title = 'Quote for Legal Assistance';
    mainStore.stageReached = 1;
    if (mainStore.quote.terms_accepted) {
      mainStore.isBusinessInformationUpdated = true;
    }
    mainStore.businessInformation.original = { ...mainStore.quote.business_information };
    mainStore.businessInformation.updated = { ...mainStore.quote.business_information };
  })
  .catch(() => {
    mainStore.errorMessage = 'Sorry we could not find your quote.';
    router.push({ name: 'NotFound' });
  });

const status = computed(() => {
  if (!isPaymentAdvice.value && mainStore.quote.terms_accepted) {
    return 'Quote terms have been accepted.';
  }

  if (isPaymentAdvice.value && mainStore.quote.status === 'Paid') {
    return 'Quote balance has been paid.';
  }

  return '';
});

const steps = computed(() => {
  return [
    { name: 'Summary', component: PartOne },
    { name: isPaymentAdvice.value ? 'Payment' : 'Terms', component: PartTwo },
  ];
});
</script>
