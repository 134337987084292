<template>
  <div class="mt-6 sm:mt-12">
    <Form @submit="onSubmit" :validation-schema="schema">
      <template
        v-if="!mainStore.transactionComplete || (mainStore.transactionComplete && mainStore.quote.subscription)"
      >
        <section-title>Membership Summary</section-title>
        <div class="flex flex-col md:flex-row mb-0 md:mb-6">
          <div class="flex flex-col mb-6 md:basis-1/3 md:mb-0 pr-0 md:pr-4 border-l-2 border-lv-slate-200 pl-3">
            <div class="font-bold">{{ mainStore.quote.client_name }}</div>
            <div class="text-sm">Client Name</div>
          </div>
          <div
            v-if="mainStore.businessInformation?.updated.abn"
            class="flex flex-col mb-6 md:basis-2/3 md:mb-0 md:pl-4 border-l-2 border-lv-slate-200 pl-3"
          >
            <div class="font-bold">
              {{ mainStore.businessInformation.updated.abn }}
            </div>
            <div class="text-sm">{{ mainStore.companyNumber }}</div>
          </div>
        </div>
        <div class="flex flex-col md:flex-row mb-0 md:mb-8">
          <div class="flex flex-col mb-6 md:basis-1/2 md:mb-0 pr-0 md:pr-4 border-l-2 border-lv-slate-200 pl-3">
            <div class="font-bold">{{ contractStore.contractData.subscription_term.label }}</div>
            <div class="text-sm">Membership Term</div>
          </div>
          <div class="flex flex-col mb-6 md:basis-1/2 md:mb-0 md:pl-4 border-l-2 border-lv-slate-200 pl-3">
            <div class="font-bold">
              {{ mainStore.numberToCurrency(mainStore.quote.total + contractStore.contractData.total_excl_gst) }} (excl.
              {{ mainStore.taxType }})
            </div>
            <div class="text-sm">Monthly Billing Fee</div>
          </div>
          <div class="flex flex-col mb-6 md:basis-1/2 md:mb-0 md:pl-4 border-l-2 border-lv-slate-200 pl-3">
            <div class="font-bold">
              {{ nextDebitDate }}
            </div>
            <div class="text-sm">{{ mainStore.quote.subscription ? 'Next' : 'First' }} Debit Date</div>
          </div>
          <div class="flex flex-col mb-6 md:basis-1/2 md:mb-0 md:pl-4 border-l-2 border-lv-slate-200 pl-3">
            <div class="font-bold">{{ contractEndDateString }}</div>
            <div class="text-sm">Membership Term End</div>
          </div>
          <div
            v-if="mainStore.quote.free_months > 0"
            class="flex flex-col mb-6 md:basis-1/2 md:mb-0 md:pl-4 border-l-2 border-lv-slate-200 pl-3"
          >
            <div class="font-bold">{{ mainStore.quote.free_months }}</div>
            <div class="text-sm">Free Months</div>
          </div>
        </div>
      </template>
      <template v-if="!contractStore.contractSigned">
        <section-title>Your Membership Contract</section-title>
        <form-section label="Information">
          <p-input
            v-model="recipient.name"
            label="Authorised by"
            name="name"
            required
            :disabled="contractStore.contractSigned"
            placeholder="Enter authorised contact..."
          />
          <div class="columns-1 md:columns-2 mb-2">
            <p-input
              v-model="recipient.email"
              label="Email"
              type="email"
              name="email"
              required
              :disabled="contractStore.contractSigned"
              placeholder="Enter email..."
            />
            <p-input
              v-model="recipient.position"
              :options="contractStore.contractPositionOptions"
              label="Position"
              type="select"
              name="position"
              placeholder="Enter position..."
              required
              :disabled="contractStore.contractSigned"
            />
          </div>
          <template v-if="recipient.position === 'Other'">
            <p-input
              v-model="recipient.other"
              label="Enter Position"
              name="other"
              placeholder="Enter business name..."
            />
          </template>
          <template v-if="!isEnvelopeSignable || !contractStore.signUrl">
            <button
              type="submit"
              :disabled="contractStore.isLoadingContract"
              class="mx-auto bg-ming-600 text-white px-3 py-2 rounded-md flex items-center text-sm justify-center"
            >
              {{ contractStore.envelope ? 'Update contract' : 'Create contract' }}
            </button>
            <div v-if="contractStore.envelope && !isEnvelopeSignable" class="flex my-4 justify-center rounded-md">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="w-6 h-6 mr-2 text-red-700"
              >
                <path
                  fill-rule="evenodd"
                  d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
                  clip-rule="evenodd"
                />
              </svg>
              <span class="text-sm"
                >Changes to the authorised contact or selected membership require the contract to be updated</span
              >
            </div>
          </template>
          <template v-if="mainStore.isPreview && contractError">
            <div class="bg-red-100 p-4 rounded-r-lg shadow-md border-l-4 mt-4 border-red-600">
              <div class="flex items-center justify-between">
                <div class="flex ml-0 sm:ml-8">
                  <div class="flex justify-center mr-2 items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      class="w-6 h-6 mr-2 text-red-500"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                      />
                    </svg>
                  </div>
                  <span class="font-semibold text-red-500 text-sm sm:text-base"> {{ contractError }}</span>
                </div>
                <button class="text-red-500 hover:underline text-sm" @click="contractError = ''">Dismiss</button>
              </div>
            </div>
          </template>
          <template v-if="!contractStore.contractSigned && contractStore.signUrl && isEnvelopeSignable">
            <div class="flex justify-center items-center">
              <iframe
                :src="contractStore.signUrl"
                style="width: 100%; border-radius: 5px; border: none; overflow: hidden"
                :style="contractHeight"
              />
            </div>
          </template>
        </form-section>
      </template>
      <template v-if="mainStore.transactionComplete && mainStore.quote.subscription">
        <form-section :show-label="false">
          <transaction-success>
            <div class="mb-8">Contract signed.</div>
            <div>
              <div class="flex mb-6" v-if="contractStore.envelope?.master">
                <a
                  class="mx-auto bg-ming-600 text-white p-3 rounded-md flex items-center text-sm justify-center font-semibold"
                  download
                  :href="contractStore.envelope.master"
                  target="_blank"
                  >Download contract
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    class="w-5 h-5 ml-2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </transaction-success>
        </form-section>
      </template>
      <template v-if="mainStore.transactionComplete && !mainStore.quote.subscription">
        <transaction-success>
          <div class="flex-col justify-center my-8 max-w-3xl">
            <h1 class="text-center text-xl font-bold my-4">Thanks for signing up to LegalVision's membership!</h1>
            <div class="text-center mb-4">
              We are setting up your access to Prism, LegalVision’s member portal. From Prism, you can book a legal
              planning session, request legal projects, browse legal resources and more.
            </div>
            <div class="text-center mb-4">You’ll receive an email shortly with instructions on how to login.</div>
            <div class="flex my-6" v-if="contractStore?.envelope?.master">
              <a
                class="mx-auto bg-ming-600 text-white p-3 rounded-md flex items-center text-sm justify-center font-semibold"
                download
                :href="contractStore.envelope.master"
                target="_blank"
                >Download contract
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  class="w-5 h-5 ml-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                  />
                </svg>
              </a>
            </div>
          </div>
        </transaction-success>
      </template>
    </Form>
    <footer-nav previous></footer-nav>
    <loading-overlay
      :loading="contractStore.isLoadingContract"
      text="Preparing contract"
      subtext="This can take some time. Please do not refresh the page."
    ></loading-overlay>
    <loading-overlay
      :loading="contractStore.isCreatingSubscription"
      :text="loadingLabel"
      :subtext="loadingText"
    ></loading-overlay>
  </div>
</template>

<script setup>
import { reactive, ref, computed, watch, onMounted } from 'vue';
import { Form, useForm } from 'vee-validate';
import { object, string } from 'yup';
import { useMainStore } from '@/stores/mainStore.js';
import { useContractStore } from '@/stores/contractStore.js';
import { usePaymentStore } from '@/stores/paymentStore.js';

import FormSection from '@/components/FormSection.vue';
import FooterNav from '@/components/FooterNav.vue';
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import PInput from '@/components/PInput.vue';
import SectionTitle from '@/components/SectionTitle.vue';
import TransactionSuccess from '@/components/TransactionSuccess.vue';

const mainStore = useMainStore();
const contractStore = useContractStore();
const paymentStore = usePaymentStore();
const { handleSubmit } = useForm();

const contractError = ref('');

const schema = object({
  name: string().required('This field is required'),
  email: string().email('Valid email required').required('This field is required'),
  position: string().required('This field is required'),
  other: string().when('position', ([position], schema) => {
    return position === 'Other' ? schema.required('This field is required') : schema.notRequired();
  }),
});

const recipient = reactive({
  name: '',
  email: '',
  position: '',
  other: '',
});

if (contractStore.envelope) {
  recipient.name = contractStore.envelope?.metadata?.authorised_by_name;
  recipient.email = contractStore.envelope?.metadata?.authorised_by_email;
  recipient.position =
    contractStore.contractPositionOptions.indexOf(contractStore.envelope?.metadata?.authorised_by_position) === -1
      ? 'Other'
      : contractStore.envelope?.metadata?.authorised_by_position;
  recipient.other =
    contractStore.contractPositionOptions.indexOf(contractStore.envelope?.metadata?.authorised_by_position) === -1
      ? contractStore.envelope?.metadata?.authorised_by_position
      : '';
}

onMounted(() => {
  document.addEventListener('envelope-signed', handleSignedEvent, false);
  document.addEventListener('envelope-closed', handleClosedEvent, false);
});

const handleSignedEvent = (e) => {
  const quoteId = e.detail?.quoteId;
  axios
    .post(`/api/quote/${quoteId}/contract/event`, {
      event: 'HANDLER_SUCCESS',
    })
    .catch((err) => {
      console.log(err);
    });

  if (quoteId === mainStore.quote.id) {
    contractStore.$patch({
      contractSigned: true,
    });

    if (mainStore.quote.is_reactivate) {
      contractStore.reactivateSubscription();
      return;
    }

    if (mainStore.quote.subscription?.is_upgrade) {
      contractStore.upgradeSubscription();
      return;
    }

    contractStore.createSubscription();
  }
};

const handleClosedEvent = () => {
  console.log('envelope cancelled');
};

const onSubmit = handleSubmit(() => {
  if (mainStore.isPreview) {
    contractError.value = 'Membership proposal is in preview mode. Cannot generate contract.';
    return false;
  }
  contractStore.createEnvelope(recipient);
});

const loadingLabel = computed(() => {
  if (mainStore.quote.subscription?.is_upgrade) {
    return 'Updating membership';
  }

  return 'Setting up membership';
});

const loadingText = computed(() => {
  return "This won't take long. Please don't refresh the page.";
});

const contractEndDateString = computed(() => {
  return mainStore.convertDateToString(contractStore.contractEndDate);
});

const nextDebitDate = computed(() => {
  return mainStore.convertDateToString(paymentStore.nextDebitDate);
});

const contractHeight = computed(() => {
  return {
    height: window.screen.height < 1080 ? '90vh' : 'calc(100vh - 300px)',
  };
});

const isEnvelopeSignable = computed(() => {
  const isRecipientSame = contractStore.envelope?.recipients[0].name.trim() === recipient.name.trim();
  const isEnvelopeTermSame =
    contractStore.envelope?.metadata?.subscription_term === contractStore.contractData?.subscription_term.label;
  let isPositionSame = false;
  if (recipient.position === 'Other') {
    isPositionSame = contractStore.envelope?.metadata?.authorised_by_position?.trim() === recipient.other.trim();
  } else {
    isPositionSame = contractStore.envelope?.metadata?.authorised_by_position === recipient.position;
  }
  const isAddonStatusSame =
    contractStore.envelope?.metadata?.whs_addon_enabled === contractStore.contractData?.whs_addon_enabled &&
    contractStore.envelope?.metadata?.pcs_addon_enabled === contractStore.contractData?.pcs_addon_enabled;

  return isRecipientSame && isEnvelopeTermSame && isPositionSame && isAddonStatusSame;
});

watch(
  recipient,
  (val) => {
    contractStore.contractData.name = val.name;
    contractStore.contractData.email = val.email;
  },
  { immediate: true }
);
</script>

<style scoped>
.wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}

.wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.max-height {
  max-height: 440px;
  max-width: 660px;
}
</style>
