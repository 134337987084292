<template>
  <div>Declined</div>
</template>

<script>
export default {
  created() {
    const event = new CustomEvent('envelope-closed');
    window.parent.document.dispatchEvent(event);
  },
};
</script>

