<template>
  <div class="flex text-sm">
    <div class="flex p-1 border border-lv-slate-200 bg-lv-slate-20 rounded-lg mx-auto sm:mx-0 w-full sm:w-auto">
      <button
        type="button"
        class="rounded-md px-3 py-2 basis-1/2 sm:basis-auto"
        @click="setCreditCard"
        :class="{
          'bg-lv-slate-600 text-white font-semibold': paymentStore.isCreditCard,
          'font-medium': !paymentStore.isCreditCard,
        }"
      >
        Credit Card
      </button>
      <button
        v-if="bank"
        type="button"
        class="rounded-md px-3 py-2 basis-1/2 sm:basis-auto"
        @click="setBankPayment"
        :class="{
          'bg-lv-slate-600 text-white font-semibold': paymentStore.isBankPayment,
          'font-medium': !paymentStore.isBankPayment,
        }"
      >
        Bank Account
      </button>
      <button
        v-if="directDeposit"
        type="button"
        class="rounded-md px-3 py-2 basis-1/2 sm:basis-auto"
        @click="setDirectDeposit"
        :class="{
          'bg-lv-slate-600 text-white font-semibold': paymentStore.isDirectDeposit,
          'font-medium': !paymentStore.isDirectDeposit,
        }"
      >
        Direct Deposit
      </button>
    </div>
  </div>
</template>

<script setup>
import { usePaymentStore } from '@/stores/paymentStore';

const setCreditCard = () => {
  paymentStore.isCreditCard = true;
  paymentStore.isDirectDeposit = false;
  paymentStore.isBankPayment = false;
};
const setDirectDeposit = () => {
  paymentStore.isCreditCard = false;
  paymentStore.isDirectDeposit = true;
  paymentStore.isBankPayment = false;
};
const setManualPayment = () => {
  paymentStore.isCreditCard = false;
  paymentStore.isDirectDeposit = false;
  paymentStore.isBankPayment = false;
};
const setBankPayment = () => {
  paymentStore.isCreditCard = false;
  paymentStore.isDirectDeposit = false;
  paymentStore.isBankPayment = true;
};

const paymentStore = usePaymentStore();
defineProps({
  bank: {
    type: Boolean,
    default: true,
  },
  directDeposit: {
    type: Boolean,
    default: false,
  },
});
</script>
